<template>
  <div class="fyi-photo">
	<label v-if="label !== '' ">{{label}}</label>
    <div class="photo">
      <div
        class="picture"
        :style="{ backgroundImage: 'url(\'' + image + '\')' }"
      >
        <input
          v-if="file"
          type="file"
          accept="image/*"
          v-on:change="handleChangeFile"
          ref="fileImage"
        />
        <video
          v-if="mobile"
          ref="video"
          width="640"
          height="480"
          autoplay
        ></video>
        <canvas v-if="mobile" ref="canvas"></canvas>
      </div>
      <fyi-button
        class=""
        className="shot-photo"
        label="Tirar nova fotografia"
        :onclick="takePhoto"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "fyi-photo",
      props: {
        label: {
            type: String,
            default: ""
		},
	  },
  data: function() {
	  
    return {
      mobile: false,
      file: false,
      image: ""
    };
  },
  async mounted() {
    await this.startCamera();
  },
  methods: {
    handleChangeFile(e) {
      let reader = new FileReader();
      const _this = this;
      reader.onload = function(e) {
        _this.image = e.target.result;
        _this.$emit("photo", _this.image);
      };
      reader.readAsDataURL(e.target.files[0]);
    },
    takePhoto() {
      if (this.file) {
        this.$refs.fileImage.click();
      } else {
        if (this.mobile) {
          const width = this.$refs.video.videoWidth;
          const height = this.$refs.video.videoHeight;
          const context = this.$refs.canvas.getContext("2d");

          this.$refs.canvas.width = width;
          this.$refs.canvas.height = height;

          context.drawImage(this.$refs.video, 0, 0, width, height);

          this.image = this.$refs.canvas.toDataURL("image/jpeg");
          this.$emit("photo", this.image);
          this.stopCamera();
        } else {
          this.startCamera();
        }
      }
    },
    async startCamera() {
      try {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          this.mobile = true;
          let constraints = {
            video: {
              facingMode: "user"
            }
          };

          const stream = await navigator.mediaDevices.getUserMedia(constraints);

          this.$refs.video.srcObject = stream;
          this.$refs.video.play();
        } else {
          this.file = true;
        }
      } catch (error) {
        this.file = true;
      }
    },
    async stopCamera() {
      const stream = this.$refs.video.srcObject;
      if (stream) {
        if (stream.getTracks().length > 0) {
          await stream.getTracks().forEach(track => track.stop());
        }
      }

      this.mobile = false;
    }
  }
};
</script>