<template>
    <label>{{label}}</label>
    <vue-tags-input
        ref="tags"
        v-model="tag"
        :tags="tags"
        :placeholder="''"
        :disabled="disabled"
        :autocompleteItems="autocompleteItems"
        :add-only-from-autocomplete="onlyAutocomplete"
        @tags-changed="$emit('input-tags', $event)"
    />
</template>
<script>
import VueTagsInput from '@sipec/vue3-tags-input';

export default {
    components: {
        VueTagsInput,
    },
    name:"InputTags",
      data() {
        return {
            tag: '',
        };
    },
    props:{
        tags: {
            type: Array,
            default: []
        },
        fullTags: {
            type: Array,
            default: []
        },
        label: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        autocompleteItems: {
            type: Array,
            default: []
        },
        onlyAutocomplete: {
            type: Boolean,
            default: false
        },
        noUrlRedirect: {
            type: Boolean,
            default: false
        }
    },
    mounted(){
        if(!this.noUrlRedirect)
            this.$refs.tags.$el.addEventListener('click', (event) => {
                let target = event.target;
                let url = null
                while(target.parentElement != null && url == null){
                    if(target.classList.contains("ti-tag")){
                        this.fullTags.forEach(el => {
                            if(el.name == target.querySelector("span").innerHTML){
                                url = this.$router.resolve({ name: "pages_cms_edit", params: { id: this.$route.params.id, content_id: el.id } })
                            }
                        });
                    }else{
                        target = target.parentElement
                    }
                }

                if(url)
                    window.open(url.href, '_blank');
            })
    }
}
</script>

<style lang="scss">
    @import "../../../../styles/components/form/tags.scss";   
</style>