<template>
  <div class="addon charts open">
    <div class="tab">
      <span id="graph" @click="selectTab" :class="[{ active: tab == 'graph' }]"
        >Gráfico</span
      >
      <span
        id="values"
        @click="selectTab"
        :class="[{ active: tab == 'values' }]"
        >Valores</span
      >
      <span
        id="preview"
        @click="selectTab"
        :class="[{ active: tab == 'preview' }]"
        >Preview</span
      >
    </div>
    <div class="section graph" :class="[{ active: tab == 'graph' }]">
      <fyi-text
        v-bind:value="title[language_index]"
        v-on:input-text="title[language_index] = $event"
        :label="'Título do Gráfico'"
        :icon_box="false"
        :full="true"
      />
      <SimpleSelect
        :label="'Localização do título'"
        :className="'field-type'"
        :previewIcon="'i-order'"
        @select-data-value="title_position[language_index] = $event"
        :options="
          positions.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: title_position[language_index] == p.value,
            };
          })
        "
      />

      <SimpleSelect
        :label="'Tipo de gráfico'"
        :className="'field-type'"
        :previewIcon="'i-order'"
        @select-data-value="type[language_index] = $event"
        :options="
          graphs.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: type[language_index] == p.value,
            };
          })
        "
      />
      <SimpleSelect
        :label="'Esquema de cores'"
        :className="'field-type'"
        :previewIcon="'i-order'"
        @select-data-value="schema = $event"
        :options="
          color_scheme.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: schema == p.value,
            };
          })
        "
      />
      <SimpleSelect
        :label="'Posição dos Filtros'"
        :className="'field-type'"
        :previewIcon="'i-order'"
        @select-data-value="legend_position[language_index] = $event"
        :options="
          filter_position.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: legend_position[language_index] == p.value,
            };
          })
        "
      />

      <div>
        <label>Definições</label>
        <Switch
          :enabled="showScale[language_index]"
          :showLabel="true"
          :label="'Escala'"
          @input="showScale[language_index] = $event"
          :key="language_index"
        />
        <Switch
          :enabled="showSubtitle[language_index]"
          :showLabel="true"
          :label="'Legenda'"
          @input="showSubtitle[language_index] = $event"
          :key="language_index"
        />
        <Switch
          :enabled="showGrid[language_index]"
          :showLabel="true"
          :label="'Grelha e linha'"
          @input="showGrid[language_index] = $event"
          :key="language_index"
        />
        <Switch
          :enabled="showTooltip[language_index]"
          :showLabel="true"
          :label="'Tooltip'"
          @input="showTooltip[language_index] = $event"
          :key="language_index"
        />
      </div>

      <fyi-text
        v-show="showScale[language_index]"
        v-bind:value="scale_title[language_index]"
        v-on:input-text="scale_title[language_index] = $event"
        :label="'Título da escala'"
        :icon_box="false"
        :full="true"
      />
      <SimpleSelect
        v-show="showScale[language_index]"
        :label="'Tipo da escala'"
        :className="'field-type'"
        :previewIcon="'i-order'"
        @select-data-value="scale[language_index] = $event"
        :options="
          scales.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: scale[language_index] == p.value,
            };
          })
        "
      />

      <fyi-text
        v-show="showSubtitle[language_index]"
        v-bind:value="subtitle[language_index]"
        v-on:input-text="subtitle[language_index] = $event"
        :label="'Título da Legenda'"
        :icon_box="false"
        :full="true"
      />
      <SimpleSelect
        v-show="showSubtitle[language_index]"
        :label="'Localização da legenda'"
        :className="'field-type'"
        :previewIcon="'i-order'"
        @select-data-value="subtitle_position[language_index] = $event"
        :options="
          positions.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: subtitle_position[language_index] == p.value,
            };
          })
        "
      />
      <div style="clear: both" />
    </div>
    <div class="section values" :class="[{ active: tab == 'values' }]">
      <label>Valores do Gráfico</label>
      <ContextMenu
        ref="context_menu"
        :options="[
          { label: 'Adicionar linha', func: addLine },
          { label: 'Adicionar coluna', func: addColumn },
        ]"
      />
      <div class="table-cont">
        <table ref="table">
          <tr>
            <th></th>
            <th
              contenteditable
              @input="item.column = $event.target.textContent"
              v-for="(item, k) in data[language_index][0]"
              :key="k"
            >
              {{ item.column
              }}<i
                class="i-close"
                contenteditable="false"
                @click="removeColumn(k)"
              />
            </th>
          </tr>
          <tr v-for="(item, k) in data[language_index]" :key="k">
            <td
              @input="item[0].row = $event.target.textContent"
              contenteditable
            >
              {{ item[0].row
              }}<i
                contenteditable="false"
                class="i-close"
                @click="removeRow(k)"
              />
            </td>
            <td
              @keypress="
                isNaN(String.fromCharCode($event.which))
                  ? $event.preventDefault()
                  : ''
              "
              @input="row.value = $event.target.textContent"
              contenteditable
              v-for="(row, k2) in item"
              :key="k2"
            >
              {{ row.value }}
            </td>
          </tr>
        </table>
      </div>
      <label>Valores da escala</label>
      <div class="scale-cont">
        <fyi-number
          :label="'Min'"
          v-bind:value="minScale[language_index]"
          v-on:input-number="minScale[language_index] = $event"
          :alias="''"
          :classe="''"
          :show="true"
          :inputError="false"
        />
        <fyi-number
          :label="'Max'"
          v-bind:value="maxScale[language_index]"
          v-on:input-number="maxScale[language_index] = $event"
          :alias="''"
          :classe="''"
          :show="true"
          :inputError="false"
        />
        <fyi-number
          :label="'Intervalos'"
          v-bind:value="tickScale[language_index]"
          v-on:input-number="tickScale[language_index] = $event"
          :alias="''"
          :classe="''"
          :show="true"
          :inputError="false"
        />
      </div>
      <fyi-text
        style="clear: both"
        v-bind:value="footer[language_index]"
        v-on:input-text="footer[language_index] = $event"
        :label="'Nota de rodapé'"
        :icon_box="false"
        :full="true"
      />
      <SimpleSelect
        :label="'Localização do rodapé'"
        :className="''"
        :previewIcon="'i-order'"
        @select-data-value="footer_position[language_index] = $event"
        :options="
          alignment.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: footer_position[language_index] == p.value,
            };
          })
        "
      />
      <div style="clear: both" />
    </div>
    <div class="section preview" :class="[{ active: tab == 'preview' }]">
      <Bar
        v-if="type[language_index] == 'bars'"
        :options="chartOptions"
        :data="chartData"
      />
      <Pie
        v-if="type[language_index] == 'pie'"
        :options="chartOptions"
        :data="chartData"
      />
      <PolarArea
        v-if="type[language_index] == 'polarArea'"
        :options="chartOptions"
        :data="chartData"
      />
      <Radar
        v-if="type[language_index] == 'radar'"
        :options="chartOptions"
        :data="chartData"
      />
      <Line
        v-if="type[language_index] == 'line'"
        :options="chartOptions"
        :data="chartData"
      />
      <Doughnut
        v-if="type[language_index] == 'doughnut'"
        :options="chartOptions"
        :data="chartData"
      />
    </div>
  </div>
</template>

<script>
import "chartjs-adapter-moment";
import { Bar, Doughnut, PolarArea, Radar, Line, Pie } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
  ArcElement,
  SubTitle,
  PointElement,
  LineElement,
  RadialLinearScale,
  TimeScale,
  LogarithmicScale,
} from "chart.js";

ChartJS.register(
  Title,
  SubTitle,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  Colors,
  ArcElement,
  PointElement,
  LineElement,
  RadialLinearScale,
  TimeScale,
  LogarithmicScale
);

export default {
  name: "AddonCharts",
  components: { Bar, Doughnut, PolarArea, Radar, Line, Pie },
  props: {
    content: {
      type: Object,
      default: null,
    },
    language_index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      tab: "graph",
      type: [],
      title: [],
      scale_title: [],
      title_position: [],
      subtitle_position: [],
      subtitle: [],
      footer: [],
      footer_position: [],
      legend_position: [],
      minScale: [],
      maxScale: [],
      tickScale: [],
      scale: [],
      showScale: [],
      showSubtitle: [],
      showGrid: [],
      showTooltip: [],
      graphs: [
        {
          label: "Barras",
          value: "bars",
          checked: false,
        },
        {
          label: "Circular",
          value: "pie",
          checked: false,
        },
        {
          label: "Doughnut",
          value: "doughnut",
          checked: false,
        },
        {
          label: "Linha",
          value: "line",
          checked: false,
        },
        {
          label: "Radar",
          value: "radar",
          checked: false,
        },
        {
          label: "Polar area",
          value: "polarArea",
          checked: false,
        },
      ],
      filter_position: [
        {
          label: "Topo, Esquerda",
          value: "top_start",
          checked: false,
        },
        {
          label: "Topo, Centro",
          value: "top_center",
          checked: false,
        },
        {
          label: "Topo, Direita",
          value: "top_end",
          checked: false,
        },
        {
          label: "Baixo, Esquerda",
          value: "bottom_start",
          checked: false,
        },
        {
          label: "Baixo, Centro",
          value: "bottom_center",
          checked: false,
        },
        {
          label: "Baixo, Direita",
          value: "bottom_end",
          checked: false,
        },
        {
          label: "Esquerda, Topo",
          value: "left_start",
          checked: false,
        },
        {
          label: "Esquerda, Centro",
          value: "left_center",
          checked: false,
        },
        {
          label: "Esquerda, Baixo",
          value: "left_end",
          checked: false,
        },
        {
          label: "Direita, Topo",
          value: "right_start",
          checked: false,
        },
        {
          label: "Direita, Centro",
          value: "right_center",
          checked: false,
        },
        {
          label: "Direita, Baixo",
          value: "right_end",
          checked: false,
        },
      ],
      alignment: [
        {
          label: "Esquerda",
          value: "start",
          checked: false,
        },
        {
          label: "Centro",
          value: "center",
          checked: false,
        },
        {
          label: "Direita",
          value: "end",
          checked: false,
        },
      ],
      positions: [
        {
          label: "Topo, Esquerda",
          value: "top_start",
          checked: false,
        },
        {
          label: "Topo, Centro",
          value: "top_center",
          checked: false,
        },
        {
          label: "Topo, Direita",
          value: "top_end",
          checked: false,
        },
        {
          label: "Baixo, Esquerda",
          value: "bottom_start",
          checked: false,
        },
        {
          label: "Baixo, Centro",
          value: "bottom_center",
          checked: false,
        },
        {
          label: "Baixo, Direita",
          value: "bottom_end",
          checked: false,
        },
      ],
      color_scheme: [
        {
          label: "Aleatório",
          value: "random",
          checked: false,
        },
        {
          label: "Azuis",
          value: "blues",
          checked: false,
        },
      ],
      scales: [
        {
          label: "Nenhum",
          value: "",
          checked: false,
        },
        {
          label: "Categoria",
          value: "category",
          checked: false,
        },
        {
          label: "Linear",
          value: "linear",
          checked: false,
        },
        {
          label: "Linear Radial",
          value: "radialLinear",
          checked: false,
        },
        {
          label: "Logarítmico",
          value: "logarithmic",
          checked: false,
        },
        {
          label: "Tempo",
          value: "time",
          checked: false,
        },
      ],
      schema: "random",
      default_blues: ["#0f2f7f", "#dbf2fd", "#006fbb", "#dfdfdf", "#0091ff"],
      data: [],
    };
  },
  beforeMount() {
    if (this.content != null) {
      this.$store.getters.languages.forEach((l) => {
        let flag = false;
        this.content.charts.forEach((c) => {
          if (c.language_id == l.id) {
            flag = true;
            this.schema = c.data.schema;
            this.type.push(c.data.type);
            this.title.push(c.data.options.plugins.title.text);
            this.scale_title.push(c.data.options.scales.y.title.text);
            this.title_position.push(
              c.data.options.plugins.title.position +
                "_" +
                c.data.options.plugins.title.align
            );
            this.subtitle_position.push(
              c.data.options.plugins.subtitle.position +
                "_" +
                c.data.options.plugins.subtitle.align
            );
            this.subtitle.push(c.data.options.plugins.subtitle.text);
            this.footer.push(c.data.options.scales.x.title.text);
            this.footer_position.push(c.data.options.scales.x.title.align);
            this.legend_position.push(
              c.data.options.plugins.legend
                ? c.data.options.plugins.legend.position +
                    "_" +
                    c.data.options.plugins.legend.align
                : "top_center"
            );

            if (c.data.options.scales.y.min != undefined)
              this.minScale.push(c.data.options.scales.y.min);

            if (c.data.options.scales.y.max)
              this.maxScale.push(c.data.options.scales.y.max);

            if (c.data.options.scales.y.ticks)
              this.tickScale.push(c.data.options.scales.y.ticks.stepSize);

            this.scale.push(c.data.options.scales.y.type);
            this.showScale.push(c.data.options.scales.x.display);
            this.showSubtitle.push(c.data.options.plugins.subtitle.display);
            this.showGrid.push(c.data.options.scales.x.grid.display);
            this.showTooltip.push(c.data.options.plugins.tooltip.enabled);

            this.data.push([]);
            c.data.data.datasets.forEach((el) => {
              this.data[this.data.length - 1].push([]);
              el.data.forEach((e, k) => {
                this.data[this.data.length - 1][
                  this.data[this.data.length - 1].length - 1
                ].push({
                  row: el.label,
                  column: c.data.data.labels[k],
                  value: e,
                });
              });
            });
          }
        });

        if (!flag) {
          this.type.push("");
          this.title.push("");
          this.scale_title.push("");
          this.title_position.push("top_center");
          this.subtitle_position.push("top_center");
          this.subtitle.push("");
          this.footer.push("");
          this.footer_position.push("center");
          this.legend_position.push("top_center");

          this.minScale.push("");
          this.maxScale.push("");
          this.tickScale.push("");

          this.scale.push("");
          this.showScale.push(false);
          this.showSubtitle.push(false);
          this.showGrid.push(false);
          this.showTooltip.push(false);

          this.data.push([]);
          this.data[this.data.length - 1].push([
            {
              column: "Label 1",
              row: "Categoria 1",
              value: "",
            },
          ]);
        }
      });
    }
  },
  computed: {
    chartData() {
      let dt = { labels: [], datasets: [] },
        colors = null;
      this.data[this.language_index][0].forEach((element) => {
        dt.labels.push(element.column);
      });

      this.resetBlues();

      this.data[this.language_index].forEach((element) => {
        let set = [],
          label = "";
        element.forEach((c) => {
          set.push(c.value);
          label = c.row;
        });

        if (
          (this.type == "pie" ||
            this.type == "doughnut" ||
            this.type == "polarArea") &&
          colors == null
        ) {
          colors = set.map((s) => {
            return this.getColor();
          });
          this.resetBlues();
        }


        if (
          this.type == "pie" ||
          this.type == "doughnut" ||
          this.type == "polarArea"
        )
          dt.datasets.push({
            data: set,
            label: label,
            backgroundColor: colors,
          });
        else
          dt.datasets.push({
            data: set,
            label: label,
            backgroundColor: this.getColor(),
          });
      });

      return dt;
    },
    chartOptions() {
      let opt = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          colors: {
            enabled: true,
          },
          tooltip: {
            enabled: this.showTooltip[this.language_index],
          },
          title: {
            display:
              this.title[this.language_index] !== null,
            text: this.title[this.language_index],
            position: this.title_position[this.language_index].split("_")[0],
            align: this.title_position[this.language_index].split("_")[1],
          },
          subtitle: {
            display: this.showSubtitle[this.language_index],
            text: this.subtitle[this.language_index],
            position: this.subtitle_position[this.language_index].split("_")[0],
            align: this.subtitle_position[this.language_index].split("_")[1],
            padding: {
              bottom: 10,
            },
          },
          legend: {
            position: this.legend_position[this.language_index].split("_")[0],
            align: this.legend_position[this.language_index].split("_")[1],
          },
        },
        scales: {
          x: {
            display: this.showScale[this.language_index],
            grid: {
              display: this.showGrid[this.language_index],
            },
            title: {
              align: this.footer_position[this.language_index],
              display: this.footer[this.language_index] != "",
              text: this.footer[this.language_index],
            },
          },
          y: {
            display: this.showScale[this.language_index],
            type:
              this.scale[this.language_index] != ""
                ? this.scale[this.language_index]
                : "linear",
            title: {
              display: this.scale_title[this.language_index] != "",
              text: this.scale_title[this.language_index],
            },
            grid: {
              display: this.showGrid[this.language_index],
            },
          },
        },
      };

      if (this.minScale[this.language_index])
        opt.scales.y.min = parseInt(this.minScale[this.language_index]);

      if (this.maxScale[this.language_index])
        opt.scales.y.max = parseInt(this.maxScale[this.language_index]);

      if (this.tickScale[this.language_index])
        opt.scales.y.ticks = {
          stepSize: parseInt(this.tickScale[this.language_index]),
        };

      return opt;
    },
  },
  methods: {
    resetBlues() {
      this.default_blues = [
        "#0f2f7f",
        "#dbf2fd",
        "#006fbb",
        "#dfdfdf",
        "#0091ff",
      ];
    },
    selectTab(ev) {
      this.tab = ev.target.id;
    },
    addColumn() {
      this.data[this.language_index].forEach((row) => {
        row.push({
          column: "Label " + (this.data[this.language_index][0].length + 1),
          row: row[0].row,
          value: "",
        });
      });
    },
    addLine() {
      let row = [];
      this.data[this.language_index][0].forEach((c) => {
        row.push({
          column: c.column,
          row: "Categoria " + (this.data[this.language_index].length + 1),
          value: "",
        });
      });

      this.data[this.language_index].push(row);
    },
    removeRow(k) {
      this.data[this.language_index].splice(k, 1);
    },
    removeColumn(k) {
      this.data[this.language_index].forEach((item) => {
        item.splice(k, 1);
      });
    },
    chartDataForIndex(index) {
      let dt = { labels: [], datasets: [] },
        colors = null;
      this.data[index][0].forEach((element) => {
        dt.labels.push(element.column);
      });

      this.resetBlues();

      this.data[index].forEach((element) => {
        let set = [],
          label = "";
        element.forEach((c) => {
          set.push(c.value);
          label = c.row;
        });

        if (
          (this.type == "pie" ||
            this.type == "doughnut" ||
            this.type == "polarArea") &&
          colors == null
        ) {
          colors = set.map((s) => {
            return this.getColor();
          });
          this.resetBlues();
        }


        if (
          this.type == "pie" ||
          this.type == "doughnut" ||
          this.type == "polarArea"
        )
          dt.datasets.push({
            data: set,
            label: label,
            backgroundColor: colors,
          });
        else
          dt.datasets.push({
            data: set,
            label: label,
            backgroundColor: this.getColor(),
          });
      });

      return dt;
    },
    chartOptionsForIndex(index) {
      let opt = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          colors: {
            enabled: true,
          },
          tooltip: {
            enabled: this.showTooltip[index],
          },
          title: {
            display: this.title[index] !== null,
            text: this.title[index],
            position: this.title_position[index].split("_")[0],
            align: this.title_position[index].split("_")[1],
          },
          subtitle: {
            display: this.showSubtitle[index],
            text: this.subtitle[index],
            position: this.subtitle_position[index].split("_")[0],
            align: this.subtitle_position[index].split("_")[1],
            padding: {
              bottom: 10,
            },
          },
          legend: {
            position: this.legend_position[this.language_index].split("_")[0],
            align: this.legend_position[this.language_index].split("_")[1],
          },
        },
        scales: {
          x: {
            display: this.showScale[index],
            grid: {
              display: this.showGrid[index],
            },
            title: {
              align: this.footer_position[index],
              display: this.footer[index] != "",
              text: this.footer[index],
            },
          },
          y: {
            display: this.showScale[index],
            type: this.scale[index] != "" ? this.scale[index] : "linear",
            title: {
              display: this.scale_title[index] != "",
              text: this.scale_title[index],
            },
            grid: {
              display: this.showGrid[index],
            },
          },
        },
      };

      if (this.minScale[index])
        opt.scales.y.min = parseInt(this.minScale[index]);

      if (this.maxScale[index])
        opt.scales.y.max = parseInt(this.maxScale[index]);

      if (this.tickScale[index])
        opt.scales.y.ticks = { stepSize: parseInt(this.tickScale[index]) };

      return opt;
    },
    output() {
      let final = [];
      this.$store.getters.languages.forEach((l, k) => {
        final.push({
          language_id: l.id,
          data: {
            type: this.type[k],
            data: this.chartDataForIndex(k),
            options: this.chartOptionsForIndex(k),
            schema: this.schema,
          },
        });
      });
      return final;
    },
    getColor() {
      if (this.schema == "blues") {
        if (this.default_blues.length > 0) return this.default_blues.shift();
        else
          return (
            "hsl(223, " +
            (Math.floor(Math.random() * (100 - 30 + 1)) + 30) +
            "%, " +
            (Math.floor(Math.random() * (90 - 20 + 1)) + 20) +
            "%)"
          );
      } else {
        return "#" + ((Math.random() * 0xffffff) << 0).toString(16);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/layout/addons.scss";
</style>
