<template>
  <div class="modal-bg">
    <div class="modal add-block">
      <i @click="close" class="i-close" />
      <label class="title">Elementos Extra</label>
      <div v-if="extraBlocks.length > 0" class="extra">
        <div v-for="(item, k) in extraBlocks" class="extra-blocks" :key="k">
          <SimpleSelect
            :className="tableOrder"
            @select="
              resortExtra({ newOrder: $event, item: item, oldOrder: k + 1 })
            "
            :previewIcon="'i-order'"
            :options="extraOrder(item, $parent.content.extra_blocks.length, k)"
          />
          <SimpleSelect
            :className="'order'"
            :allowSearch="true"
            :previewIcon="'i-order'"
            @select-data-value="setExtra($event, item)"
            :options="
              blocks.map((t) => {
                return {
                  label: t.name,
                  value: t.id,
                  checked: t.id == item.block_id,
                };
              })
            "
          />
          <i class="i-remove" @click="removeExtra(item)" />
        </div>
      </div>
      <div v-else>Sem blocos associados</div>
      <fyi-button
        :label="'Adicionar elemento'"
        :icon="'i-plus'"
        :className="'default new-extra'"
        :onclick="() => addExtra()"
      />
    </div>
  </div>
  <teleport to="body">
    <Toast v-if="toast_show" :type="toast_type" :message="toast_message" />
  </teleport>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddBlock",
  data: () => ({
    creating: false,
    value: null,
    toast_type: "",
    toast_message: "",
    toast_show: false,
  }),
  props: {
    blocks: {
      type: Array,
      default: null,
    }
  },
  computed: {
    extraBlocks() {
      return this.$parent.content.extra_blocks.filter(
        (e) => e.language_id == this.$parent.current_language.id
      );
    },
  },
  methods: {
    ...mapActions(["createType", "createBlock", "getTypes"]),
    close() {
      this.$parent.addBlock = false;
    },
    extraOrder(item, total, index = null) {
      let list = [];
      //set the select dropdowns with all the possible positions for each item, and the current one
      for (let i = 0; i < total; i++) {
        list.push({
          label: i + 1,
          value: i + 1,
          checked: i == index,
        });
      }
      return list;
    },
    resortExtra(info) {
      //create clone of extra to make only 1 update to the UI, instead of 1 for each item changed
      let extra = [];

      this.$parent.content.extra_blocks.forEach((r) => {
        extra.push(null);
      });

      let oldOrder = info.oldOrder;

      this.$parent.content.extra_blocks.forEach((item, i) => {
        //give new order to updated item
        if (item.block_id == info.item.block_id) {
          extra[info.newOrder - 1] = info.item;
          //all items before the updated item decrease by 1
        } else if (oldOrder < info.newOrder && i + 1 <= info.newOrder) {
          extra[i - 1] = item;
          //all items after the updated item increase by 1
        } else if (
          oldOrder > info.newOrder &&
          i + 1 >= info.newOrder &&
          i + 1 < oldOrder
        ) {
          extra[i + 1] = item;
        } else {
          extra[i] = item;
        }
      });

      this.$parent.content.extra_blocks = extra;
    },
    removeExtra(item) {
      let toRemove = [];
      this.$parent.content.extra_blocks.forEach((i) => {
        if (i.block_id == item.block_id) {
          toRemove.push(i);
        }
      });
      toRemove.forEach((tr) => {
        this.$parent.content.extra_blocks.splice(tr, 1);
      });
    },
    addExtra() {
      this.$parent.content.extra_blocks.push({
        block_id: "",
        name: "",
        language_id: this.$parent.current_language.id
      });
    },
    setExtra(id, item) {
      this.$parent.content.extra_blocks.forEach((i) => {
        if (i == item) {
          this.blocks.forEach((b) => {
            if (b.id == id) {
              i.block_id = b.id;
              i.name = b.name;
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/components/layout/modals";
</style>
