import Fields from "../../../libs/api/cms/fields";

export default {
    state: {
        fields: []
    },

    getters: {
        fields: state => state.fields,
    },

    mutations: {
        setFields: (state, fields) => {
            state.fields = fields;
        }
    },

    actions: {
        getFields: async ({ commit }, params) => {
            const response = await Fields.getFields(params);
            commit("setFields", response);
			return response;
        },
        getField: async ({ commit }, { params, id }) => {
            const response = await Fields.getField(params, id);
            return response
        },
        updateField: async ({ commit }, { params, data, id }) => {
            const response = await Fields.updateField(params, data, id);
            return response
        },

        createField: async ({ commit }, { params, data }) => {
            const response = await Fields.createField(params, data);
            return response
        },
        setFields: ({ commit }, fields) => {
            commit("setFields", fields);
        },
        deleteField: async ({ commit }, { params, id }) => {
            const response = await Fields.deleteField(params, id);
            return response
        },
    }
};
