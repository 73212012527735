<template>
	<div class="fyi-input phone">
		<label v-if="label">{{label}}</label>
        <vue-tel-input :value="value" mode="international" :preferredCountries="['PT']"
        :inputOptions="inputOptions"  @input="onInput" autocomplete="off"
        ></vue-tel-input>
	</div>
</template>

<script>
import { VueTelInput } from 'vue3-tel-input'
export default {
    name: "fyi-phone",
    components:{
        VueTelInput
    },
    props: {
        value: [String, Number],
        alias: String,
        label: {
            type: String,
            default: ""
        }
	},
	data() {
        return {
            inputOptions: {
                placeholder: "Insira o número de telemóvel",
                required: this.required,
                readonly: this.disabled
            }
        }
	},
    methods: {
        onInput(phone, phoneObject, input) {
            if (phoneObject?.number) {
                this.$emit('input-phone', phoneObject.number);
            }
        },
    }
};
</script>

<style lang="scss">
    @import url(https://unpkg.com/vue3-tel-input/dist/vue3-tel-input.css);

    .vue-tel-input{
        height: 46px;
        border-color: $light-grey;
        border-radius: $border-radius;
        input{
            border: unset!important;
            border-left: solid 1px $light-grey!important;
            @extend %p;
            color: $grey;
        }
        ul{
            li{
                @extend %p;
            }
        }
    }
</style>