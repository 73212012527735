import _ from "lodash";
import Api from "../api";

class Search extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/search";
        this.requiresAuth = true;
    }

    search(text, params) {
        return this.get(this.uri +"?text="+text+"&"+params, this.requiresAuth);
    }
}

export default new Search();
