<template>
	<div class="fyi-user-photo">
		<label v-if="label">{{ label }}</label>
		<fyi-button
			:className="'blank outline'"
			:label="'Escolher Foto'"
			:onclick="handleUpload"
		>
			<fyi-icon icon="upload-cloud" slot="icon" />
		</fyi-button>

		<fyi-button
			v-if="!toggleCamera"
			:label="'Usar Camara'"
			class="orange"
			className="orange"
			:onclick="
				() => {
					this.chooseCamera();
				}
			"
		></fyi-button>

		<img v-if="photo64" :src="photo64" class="photo-preview" />
		<fyi-photo v-if="toggleCamera" @photo="handleCamera" />

		<input
			type="file"
			style="display: none"
			ref="userfoto"
			@change="handleFile"
			:accept="'.png,.jpg,.jpeg'"
		/>

		
		<fyi-button
			v-if="photo64 || showRemove"
			:label="'Remover Foto'"
			class="orange"
			className="orange"
			:onclick="
				() => {
					this.removerFoto();
				}
			"
		></fyi-button>
	</div>
</template>

<script>
export default {
	name: "fyi-user-photo",
	props: {
		label: {
			type: String,
			default: "",
		},
		photo: String
	},
	data: function () {
		return {
			mobile: false,
			showRemove: false,
			toggleCamera: false,
			image: "",
			file: null,
			photo64: null
		};
	},
	async mounted() {
		if(this.photo) this.photo64 = this.photo;
	},
	methods: {
		chooseCamera() {
			this.photo64 = null;
			this.file= null; 
			this.$refs.userfoto.value = null;
			this.$emit("input", null);
			this.toggleCamera = true;
		},
		removerFoto() {
			this.photo64 = null;
			this.file= null; 
			this.$refs.userfoto.value = null;
			this.showRemove = false;
			this.toggleCamera = false;
			this.$emit("input", null);
		},
		handleCamera(photo64) {
			this.showRemove = true;
			this.$emit("input", photo64);
		},

		handleFile(a) {
      		this.file = this.$refs.userfoto.files[0];
			if (this.file) {
				var FR = new FileReader();
				let _this = this;
				FR.addEventListener("load", function (e) {
					_this.photo64 = e.target.result;
					_this.$emit("input", e.target.result);
				});

				FR.readAsDataURL(this.file);
			}
		},

		handleUpload() {
			this.photo64 = null;
			this.file= null; 
			this.$refs.userfoto.value = null;
			this.$emit("input", null);
			this.$refs.userfoto.click();
			this.toggleCamera = false;
		},
	},
};
</script>


<style lang="scss">
.fyi-user-photo {
	.fyi-button {
		// margin-bottom: 2rem;
	}

	.photo-preview {
		width: 262px;
		height: 212px;
		background-color: rgba(0, 0, 0, 0.3);
		position: relative;
		display: table;
		margin: 0 auto 20px;
		object-fit: contain;
	}
}
</style>