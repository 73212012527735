<template>
    <div :class="['complex-select', className, {active: opened }]" ref="el">
        <div :class="['preview', {'filtered': selected.length > 0}]" @click="open()">
            <label>{{previewLabel}}</label>
            <i :class="previewIcon"></i>
        </div>
        <div ref="select" :class="['select', {active: opened }]">
            <template v-if="options.length > 0">
                <div class="filter-section options">
                    <span>{{optionsLabel}}</span>
                    <i class="i-search"></i>
                    <input :placeholder="'Pesquisar '+optionsLabel" class="select-search" @keyup="filterOptions($event.target.value)">
                    <ul class="select-options">
                        <li v-for="(opt, k) in options" :class="opt.class + (opt.checked ? ' active' : '')" :value="opt.value" :key="opt.value" @click="select($event.target, opt.value)">
                            {{opt.label}}
                        </li>
                    </ul>
                </div>
            </template>
            <template v-if="fields.length > 0">
                <div class="filter-section fields">                    
                    <span>{{fieldsLabel}}</span>
                    <ul class="select-options">
                        <li v-for="(opt, k) in fields" :class="'field-opt' + (opt.checked ? ' active' : '')" :value="opt.value" :key="opt.value" @click="selectField($event.target, opt.value)">
                            <span @click="selectField($event.target.parentElement, opt.value)">{{opt.label}}</span>
                            <ul class="field-options">
                                <li v-for="(v, k) in opt.values" :class="''" :data-value="v.value" :key="v.value" @click="selectFieldValue($event.target, v.value)">
                                    {{v.label ? v.label : v.name}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-for="(checkbox, id) in checkboxes" :key="id">
                <div class="filter-section">
                    <span>{{checkboxLabels[id]}}</span>
                    <div ref="checkboxes" class="state-filter">
                        <fyi-checkbox v-for="(st, k) in checkbox" :key="st.alias" :alias="st.alias" :label="st.label"    
                            :value="state[id][st.alias]"  
                            :isChecked="state[id][st.alias]"
                            v-on:input="allowMulti(id, st.alias, k)"/>
                    </div>
                </div>
            </template>
            <div v-if="!noButtons" class="buttons">
                <fyi-button
                    :label="buttonsLabels[0]"
                    :className="'default restore'"
                    :onclick="() => restore()"
                />
                <fyi-button
                    :label="buttonsLabels[1]"
                    :className="'default apply'"
                    :onclick="() => apply()"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"ComplexSelect",
    props: {
        className: {
            type: String,
            default: ""
        },
        previewLabel: {
            type: String,
            default: "Filter by"
        },
        previewIcon: {
            type: String,
            default: "i-filter"
        },
        fields: {
            type: Array,
            default: []
        },
        fieldsLabel: {
            type: String,
            default: "Fields"
        },
        options: {
            type: Array,
            default: []
        },
        optionsLabel: {
            type: String,
            default: "Options"
        },
        checkboxes: {
            type: Array,
            default: []
        },
        checkboxLabels: {
            type: Array,
            default: []
        },
        checkboxMulti: {
            type: Array,
            default: []
        },
        buttonsLabels: {
            type: Array,
            default: ['Reset', 'Filter by']
        },
        noButtons: {
            type: Boolean,
            default: false
        },
        singleSelection: {
            type: Boolean,
            default: false
        }
    },
    watch:{
        options(){
            this.opened = false
            this.restore(false);
        }
    },
    data() {
        return {
            opened: false,
            state: [],
            selected: [],
            selectedField: []
        }
    },
    created() {
        //add functionality to close dropdown when clicked outside of it
        window.addEventListener("click", this.closeOnBlur)
    },
    destroyed() {
        //remove functionality when destroying component
        window.removeEventListener("click", this.closeOnBlur);
    },
    beforeMount(){
        //set status of all available checkboxes if provided
        this.checkboxes.forEach( (ch, id ) => {
            this.state[id] = [];
            ch.forEach( st => {
                this.state[id][st.alias] = st.checked
            })
        })

        this.options.forEach( (o, k) => {
            if(o.checked)
                this.selected.push(k)
        })
    },
    methods: {
        open(){
            this.opened = !this.opened;
        },
        closeOnBlur(event){
            if(this.opened){
                if(this.$refs.el != null && !this.$refs.el.contains(event.target)){
                    this.opened = false;
                }
            }
        },
        select(el, id){
            if(this.singleSelection){
                this.$refs.select.querySelectorAll('.options li').forEach( li => li.classList.remove("active"))
                this.selected = [id]
                el.classList.add("active")
            }else{
                if(el.classList.contains("active")){
                    this.selected.splice(this.selected.indexOf(id), 1)
                    el.classList.remove("active")
                }else{
                    this.selected.push(id)
                    el.classList.add("active")
                }
            }
            this.$emit('clickLine', id);
        },
        selectField(el, id){
            if(!el.classList.contains('field-opt'))
                return

            if(el.classList.contains('active')){
                this.$refs.select.querySelectorAll('.fields > ul > li').forEach( li => {li.classList.remove("active");li.classList.remove('hidden')})
            }else{   
                this.$refs.select.querySelectorAll('.fields > ul > li').forEach( li => {li.classList.remove("active");li.classList.remove('hidden')})             
                this.$refs.select.querySelectorAll('.fields > ul > li').forEach( li => li != el ? li.classList.add("hidden") : '')
                el.classList.add("active")
            }
        },
        selectFieldValue(el, id){
            if(el.classList.contains("active")){
                this.selectedField.forEach( (f,k) => {
                    if(f.field == el.parentElement.parentElement.value && f.value == el.dataset.value)
                        this.selectedField.splice(k, 1)
                })
                el.classList.remove("active")
            }else{
                el.parentElement.querySelectorAll('li').forEach( li => li.classList.remove("active"))
                this.selectedField.forEach( (f,k) => {
                    if(f.field == el.parentElement.parentElement.value)
                        this.selectedField.splice(k, 1)
                })
                this.selectedField.push({field: el.parentElement.parentElement.value, value: el.dataset.value})
                el.classList.add("active")
            }
        },
        //filter options on search input by name
        filterOptions(value){
            this.$refs.select.querySelectorAll(".options li").forEach( el => el.classList.remove("hidden"))
            if(value.trim() != "")
                this.$refs.select.querySelectorAll(".options li").forEach( el => !el.innerHTML.toLowerCase().includes(value.toLowerCase()) ? el.classList.add("hidden") : el.classList.remove("hidden"))
        },
        //handler that provides functionality for multi or single selection
        allowMulti(id, alias, elID){
            //if multi selection not allowed set every option to false
            if(!this.checkboxMulti[id]){
                this.checkboxes.forEach( (ch, idd ) => {
                    if(id == idd){
                        ch.forEach( st => {
                            if(alias != st.alias){
                                this.state[id][st.alias] = false
                            }
                        })
                    }
                })
            }
            //besides the one clicked which we will toggle to the opposite
            this.state[id][alias] = !this.state[id][alias];
        },
        //send all info about selected fields to parent component to handle the request
        apply(){
            this.$emit('apply', {ids: this.selected, states: this.state, fields: this.selectedField});
            this.opened = false
        },
        //set default values
        restore(applyFlag = true){
            this.selected = [];
            this.selectedField = [];
            this.$refs.select.querySelectorAll("li").forEach( el => {el.classList.remove("active");el.classList.remove('hidden')})
            this.checkboxes.forEach( (ch, id ) => {
                ch.forEach( st => {
                    this.state[id][st.alias] = st.checked
                })
            })

            if(applyFlag)
                this.apply()
        }
    }
}
</script>

<style lang="scss">
  @import "@/styles/components/form/complex_select";
</style>