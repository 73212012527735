import AppAuth from "./../../AppAuth";
import Login from "./../../pages/auth/Login";
import RecoverPassword from "./../../pages/auth/RecoverPassword";
import RecoverPasswordEmail from "./../../pages/auth/RecoverPasswordEmail";
import Register from "./../../pages/auth/Register";

// middleware
import init from "./../middleware/init";
import store from "../../store";
import resetAuth from "./../middleware/resetAuth";

export default [{
    path: "",
    component: AppAuth,
    name: "auth",
    beforeEnter: (to, from, next) => {
        //redirect to dashboard home if user already logged
        if(to.name === "login" && store.getters.auth.isAuthenticated)
            next({
                name: "dashboard"
            })

        if (to.name === "auth") next({
            name: "login"
        });

        next();
    },
    children: [{
            path: "login",
            name: "login",
            component: Login,
            meta: {
                middleware: [init],
                title: 'Login'
            }
        },
        {
            path: "register",
            name: "register",
            component: Register,
            meta: {
                middleware: [init, resetAuth],
                title: 'Registo'
            }
        },
        {
            path: "recover-password",
            name: "recover-password",
            component: RecoverPassword,
            meta: {
                middleware: [init, resetAuth],
                title: 'Recuperar password'
            }
        },
        {
            path: "recover-email",
            name: "recover-email",
            component: RecoverPasswordEmail,
            meta: {
                middleware: [init, resetAuth],
                title: 'Recuperar password'
            }
        },
        {
            path: "password/:token",
            name: "password",
            component: RecoverPassword,
            meta: {
                middleware: [init, resetAuth],
                title: 'Recuperar password'
            }
        },
        {
            path: "*",
            beforeEnter: (to, from, next) => {
                next({
                    name: "login"
                });
            }
        }
    ]
}];