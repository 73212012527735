<template>
    <div class="bg">
        <div class="container">
            <i class='i-close' :onclick="() => { close() }" />
            <div class="body">
                <label class="title" >Definições</label>
                <SimpleSelect 
                    v-if="!addingRole"
                    :label="'Role'"
                    :className="''"
                    :allowSearch="true"
                    :previewIcon="'i-order'"
                    @select-data-value="setRole($event)" :options="roles.map( (t,k) => { return { label: t.name, value: t.id, checked: role?.id == t.id} })" :key="role?.id"/>

                    <fyi-text
                        v-else
                        v-bind:value="new_role"
                        v-on:input-text="new_role = $event"
                        :label="''"
                        :placeholder="'Novo Role'"
                        :icon_box="false" :full="true"
                        @enter-text="newRole()"
                    />
                    <div v-if="addingRole" class="input-bt">
                        <fyi-button
                            :icon="'i-check'"
                            :className="'default upload-bt'"
                            :onclick="() => newRole()"
                        />
                        <fyi-button
                            :icon="'i-remove file'"
                            :className="'default remove-bt'"
                            :onclick="() => addingRole = false"
                        />
                    </div>
                    <fyi-button
                        v-else
                        :label="'Novo Role'"
                        :className="'default external role-bt'"
                        :onclick="() => addingRole = true"
                    />
                <fyi-checkbox   
                    :label="'Tudo'"
                    :value="role?.permissions?.includes('all')"  
                    :checked="role?.permissions?.includes('all')"                      
                    v-on:input="handleRole('all')"
                    :key="role?.id"
                />
                <div class="categories-form">
                    <div class="permission-labels">
                        <label>Ler</label>
                        <label>Escrever</label>
                    </div>
                    <div class="permissions">
                        <div class="permission" v-for="(perm, k) in permissions" :key="role?.id">
                            <label>{{ perm.label }}</label>
                            <div class="checkboxes">
                                <fyi-checkbox   
                                    :value="role?.permissions?.includes(perm.perm+'.read')"  
                                    :checked="role?.permissions?.includes(perm.perm+'.read')"                      
                                    v-on:input="handleRole(perm.perm, 'read')"
                                    :key="role?.permissions"
                                />
                                <fyi-checkbox 
                                    :value="role?.permissions?.includes(perm.perm+'.write')"  
                                    :checked="role?.permissions?.includes(perm.perm+'.write')"                      
                                    v-on:input="handleRole(perm.perm, 'write')"
                                    :key="role?.permissions"
                                />
                            </div>
                        </div>
                        <div class="permission" v-for="(t, k) in types" :key="role?.id">
                            <label>{{ t.languages[0].name }}</label>
                            <div class="checkboxes">
                                <fyi-checkbox   
                                    :value="role?.permissions?.includes('contents.read.'+t.id)"  
                                    :checked="role?.permissions?.includes('contents.read.'+t.id)"                      
                                    v-on:input="handleRole('contents', 'read', t.id)"
                                    :key="role?.permissions"                                />
                                <fyi-checkbox 
                                    :value="role?.permissions?.includes('contents.write.'+t.id)"  
                                    :checked="role?.permissions?.includes('contents.write.'+t.id)"                      
                                    v-on:input="handleRole('contents', 'write', t.id)"
                                    :key="role?.permissions"                                />
                            </div>
                        </div>
                    </div>
                    <fyi-button
                        :label="'Guardar'"
                        :className="'default save'"
                        :loading="submitStatus=='PENDING'"
                        :onclick="() => save()"
                    />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { toHandlers } from "vue";
import { mapActions } from "vuex";

export default {
    name:"AppSettings",
    data(){
        return{
            role: null,
            roles: [],
            types: [],
            new_role: '',
            addingRole: false
        }
    },
    props: {
        app: {
            type: Number,
            default: 0
        }
    },
    async beforeMount(){
        this.roles = await this.getRoles();
        let headers = {}
        this.$store.getters.apps.forEach(a => {
            if( a.id == this.app ){
                headers.app = a.token
            }
        });
        if(this.app != 1)
            this.types = await this.getTypes({customRequest: headers});
    },
    computed: {
        permissions(){
            let perm = [{label: "Aplicações", perm: "apps"}, {label: "Addons", perm: "addons"}, {label: "Blocos", perm: "blocks"}, {label: "Categorias", perm: "categories"},
            {label: "Campos Extra", perm: "fields"} , {label:"Templates", perm: "templates"}, {label: "Idiomas", perm: "languages"}, {label: "Conteúdos", perm: "contents"}];

            return perm;
        }
    },
    methods:{
		...mapActions([
			"getRoles", "getTypes", "editRole", "createRole"
	  ]),
      setRole(r){
        let role, app;
        this.roles.forEach( rl => {
            if(rl.id == r){
                role = rl;
            }
        })

        role.apps.forEach( a => {
            if(a.id == this.app){
                role.permissions = a.pivot.permissions;
            }
        })

        if(!role.permissions)
            role.permissions = []

        this.role = role;

        this.$forceUpdate();
      },
      handleRole(permission, spec,  type = null){
        if(permission == "all" && !this.role?.permissions?.includes('all')){
            this.role.permissions = []
            this.role?.permissions?.push('all')
        }else if(permission == "all" && this.role?.permissions?.includes('all')){
            this.role.permissions = []
        }else if(this.role?.permissions?.includes(permission+'.'+spec + ( type ? ('.' + type) : ''))){
            this.role.permissions.splice( this.role.permissions.indexOf(permission+'.'+spec + ( type ? ('.' + type) : '')),1)
        }else{
            this.role?.permissions?.splice( this.role.permissions.indexOf('all'),1)
            this.role?.permissions?.push(permission+'.'+spec + ( type ? ('.' + type) : ''))
        }

        this.$forceUpdate();
      },
      async newRole(){
        await this.createRole({name: this.new_role, apps: [this.app, 1]})
        this.roles = await this.getRoles();
        this.addingRole = false;
      },
      async save(){
        this.role.apps = [this.app]

        await this.editRole({id:this.role.id, data: {name: this.role.name, apps: this.role.apps, perm: this.role.permissions}})
      },
      close(){
        this.$parent.showSettings = false;
      }
    },
    async mounted(){

    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/layout/settings";
</style>