import _ from "lodash";
import Api from "../api";

class Categories extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/categories";
        this.requiresAuth = true;
    }

    getCategory(id) {
        return this.get(this.uri + "/" + id, {}, this.requiresAuth);
    }
    
    getCategories(params) {
        return this.get(this.uri + "?" + params, {}, this.requiresAuth);
    }
    
    create(params) {
        return this.post(this.uri, params, this.requiresAuth);
    }
    
    deleteCategory(id) {
        return this.delete(this.uri + "/" + id, {}, this.requiresAuth);
    }
   
    edit(id, params) {
        return this.put(this.uri + "/" + id, params, this.requiresAuth);
    }
}

export default new Categories();
