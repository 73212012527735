<template>
    <div class="bg">
        <div class="container">
            <label>{{ title }}</label>
            <div class="drag-n-drop" v-bind="getRootProps()">
                <input v-bind="getInputProps()" />
                <i class='i-upload' />
                <label>Largue aqui os seus ficheiros ou <span>pesquise</span></label>
                <label>{{ description }}</label>
                <i class='i-close' :onclick="() => { closeUploadWindow() }" />
            </div>
            <div class="items">
                <template v-for="(p, k) in uploadProgress" :key="k">
                    <template v-if="p != undefined">
                        <div class="prog" v-for="(i, l) in p.files" :key="l">
                            <label>{{ i.name }}</label>
                            <label>{{ i.size }}</label>
                            <div class="ko-progress-circle" :data-progress="p.prog">
                                <div class="ko-circle">
                                    <div class="full ko-progress-circle__slice">
                                        <div class="ko-progress-circle__fill"></div>
                                    </div>
                                    <div class="ko-progress-circle__slice">
                                        <div class="ko-progress-circle__fill"></div>
                                        <div class="ko-progress-circle__fill ko-progress-circle__bar"></div>
                                    </div>
                                </div>
                                <div class="ko-progress-circle__overlay">
                                    <div id="tick-mark"></div>
                                </div>
                            </div>
                        </div>
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { useDropzone } from "vue3-dropzone";
import { useStore } from 'vuex'
import { ref } from 'vue';
import $bus from '../../../../plugins/event'

export default {
    name: "UploadBox",
    data() {
        return {
            files: [],
            uploadProgress: [],
            toast_type: '',
            toast_message: '',
            toast_show: false,
        }
    },
    props: {
        title: {
            type: String,
            default: "Upload de ficheiro"
        },
        description: {
            type: String,
            default: ''
        }
    },
    setup() {
        const store = useStore()
        var showModal = ref(0)
        var addFlag = ref(false)

        function onDrop(acceptFiles, rejectReasons) {
            saveFiles(acceptFiles); // saveFiles as callback
            addFlag = false
        }

        const { getRootProps, getInputProps, ...rest } = useDropzone({ maxFiles: 1, onDrop });

        const saveFiles = (files) => {
            let flagError = false, flag100 = false
            store.getters.auth.user.features?.forEach( f => {
                if(f.keyword == 'flag100'){
                    flag100 = f.flag == 1 ? true: false
                }
            })

            files.forEach(f => {
                //partly deprecated due to implementation of image compression server side (compression doesnt work php side for tiff so we enforce the size threshold)
                if (f.type.includes("image/tif")) {
                    // this.toast_type = "error";
                    // this.toast_message = "Ficheiro ultrapassa o limite máximo de 2MB.";
                    // this.toast_show = true;
                    showModal = 2
                    flagError = true
                }

                if (f.type.includes("video") && f.size > 8000000) {
                    // this.toast_type = "error";
                    // this.toast_message = "Ficheiro ultrapassa o limite máximo de 8MB.";
                    // this.toast_show = true;
                    showModal = 8
                    flagError = true
                }

                if (flag100 && f.size > 100000000 && !f.type.includes("image")) {
                    // this.toast_type = "error";
                    // this.toast_message = "Ficheiro ultrapassa o limite máximo de 25MB.";
                    // this.toast_show = true; 
                    flagError = true
                    showModal = 100
                } else if (!flag100 && f.size > 25000000 && !f.type.includes("image")) {
                    // this.toast_type = "error";
                    // this.toast_message = "Ficheiro ultrapassa o limite máximo de 25MB.";
                    // this.toast_show = true; 
                    flagError = true
                    showModal = 25
                }

            })
            if (flagError) {
                $bus.$trigger('modal', showModal)
                return
            }

            store.dispatch('importContents', { files: files });
        };
        return {
            getRootProps,
            getInputProps,
            addFlag,
            showModal,
            ...rest,
            showModal
        };
    },
    methods: {
        ...mapActions(["featureEnabledUser"]),
        closeUploadWindow() {
            event.preventDefault();
            event.stopPropagation();

            let allDone = true;

            this.uploadProgress.forEach(up => {
                if (up.prog != 'request-finished' && up.prog != 'request-error') {
                    allDone = false;
                }
            })

            //disable popup closing until upload is finished
            if (!allDone) {
                this.toast_type = "warning";
                this.toast_message = "Ficheiros em carregamento. Poderá fechar a janela mais tarde.";
                this.toast_show = true;
                return;
            }

            this.$parent.showImportWindow = false;
            this.uploadProgress = [];
        }
    },
    mounted() {
        $bus.$on('file-upload', (data) => {
            let prog = { prog: data.progress, files: [] }
            prog.files = data.info.map(i => { return { name: i.name, size: (i.size / (1024 ** 2)).toFixed(3) + 'MB' } })
            this.uploadProgress[0] = prog

            //if success show success toast
            if (prog.prog == 'request-finished') {
                this.$parent.toast_type = "success";
                this.$parent.toast_message = "Ficheiro importado com sucesso.";
                this.$parent.toast_show = true;
                this.uploadProgress = [];
                this.$parent.showImportWindow = false;
                this.$parent.reload();
            }

            //if error show error toast
            if (prog.prog == 'request-error') {
                this.toast_type = "error";
                this.toast_message = "Falha ao carregar ficheiro. Se o problema persistir contacte um administrador.";
                this.toast_show = true;
            }
        })

        $bus.$on('modal', (showModal) => {
            this.$parent.toast_type = "error";
            if (showModal == 'csv') {
                this.$parent.toast_message = "Ficheiro não suportado. Carregue um ficheiro .csv.";
            }else if (showModal == 'tiff') {
                this.$parent.toast_message = "Ficheiros com a extensão TIFF não permitidos.";
            } else if (showModal == 2) {
                this.$parent.toast_message = "Ficheiro ultrapassa o limite máximo de 2MB.";
            } else if (showModal == 8) {
                this.$parent.toast_message = "Ficheiro ultrapassa o limite máximo de 8MB.";
            } else if (showModal == 25) {
                this.$parent.toast_message = "Ficheiro ultrapassa o limite máximo de 25MB.";
            } else if (showModal == 100) {
                this.$parent.toast_message = "Ficheiro ultrapassa o limite máximo de 100MB.";
            } else {
                return
            }

            this.$parent.toast_show = true;
        })
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/form/upload_box.scss";
</style>