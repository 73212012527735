<template>
  <ul :class="'pagination ' + className">
    <li class="pagination-item prev" :class="[{'opacity-0': isInFirstPage}]">
      <button
        type="button"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
      >
        <i class="i-chevron"/>
      </button>
    </li>

    <!-- Visible Buttons Start -->

    <li v-for="(page, k) in pages" class="pagination-item" :key="page.name">
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
      >
        <span>{{ page.name }}</span>
      </button>
    </li>

    <!-- Visible Buttons End -->

    <li class="pagination-item next" :class="[{'opacity-0': isInLastPage}]">
      <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
        <i class="i-chevron"/>
      </button>
    </li>
    <div class="go-to-page">
      <label>{{ $t('go_to') }}</label>
      <input type="text" inputmode="numeric" @keyup.enter="goTo"/>
    </div>
  </ul>

</template>

<script>
export default {
  name:"Pagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 7,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    className: {
      type: String,
      default: ""
    }
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When total equals max
      if (this.maxVisibleButtons === this.totalPages) {
        return 1
      }

      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons == 0 ? 1 : this.totalPages - this.maxVisibleButtons + 1;
      }

      //when penultime page
      if (this.currentPage === this.totalPages - 1) {
        return this.currentPage - 5 > 0 ? this.currentPage - 5 : 1;
      }

      //when penultime page
      if (this.currentPage === this.totalPages - 2) {
        return this.currentPage - 4 > 0 ? this.currentPage - 4 : 1;
      }

      // When inbetween
      return this.currentPage - 3 > 0 ? this.currentPage - 3 : 1;
    },
    pages() {
      const range = [];
      let every_other = 1;

      for (
        let i = this.startPage;
        i <=
        Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        if(this.totalPages <= this.maxVisibleButtons){
          range.push({
            name: i,
            isDisabled: false
          });
        }else if(this.currentPage > 4 && this.currentPage < this.totalPages - 3){
          range.push({
            name: (every_other == 2 || every_other == 6) ? "..." : ((every_other == 1) ? 1 : ( (every_other == 7) ? this.totalPages : i)),
            isDisabled: (i === this.currentPage || every_other == 2 || every_other == 6) ? true : false,
          });
          every_other++;
        }else if(this.currentPage <= 4){
            range.push({
              name: (every_other == 7) ? this.totalPages : ((every_other == 6) ? "..." : i),
              isDisabled: (i === this.currentPage || every_other == 6 ) ? true : false,
            });
            every_other++;
        }else{
            range.push({
              name: (every_other == 1) ? 1 : ((every_other == 2) ? "..." : i),
              isDisabled: (i === this.currentPage || every_other == this.totalPages - 1 || every_other == 2) ? true : false,
            });
            every_other++;
        }
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
    goTo(event){
      let page = event.target.value;
      event.target.value = ""

      if(page > this.totalPages){
        page = this.totalPages
      }else if(page < 1){
        page = 1
      }else if(page == this.currentPage || isNaN(page)){
        return
      }

      this.$emit("pagechanged", parseInt(page));

    }
  }
};
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/pagination.scss";
</style>