<template>
    <div class="bg">
        <div class="container">
            <i class='i-close' :onclick="() => { close() }" />
            <div class="body">
                <label class="title" >Acesso Rápido</label>
                <Table v-if="features != null && types != null" ref="table" :items="{data: parsedFeatures, total: parsedFeatures.length}" :items2="availableFeatures" :items3="availableIcons" :loading="loadTable" :tableClass="'qa'"
                :pagination="false" @order-qa="resort"
                @remove="remove" @add="add" :showEntries="false" :showTotal="false" :showFilters="false"
                />
                <fyi-button
                        :label="'Guardar'"
                        :className="'default save'"
                        :loading="submitStatus=='PENDING'"
                        :onclick="() => save()"
                    />
            </div>
        </div>
    </div>
    <teleport to="body">
        <Toast v-if="toast_show" :type="toast_type" :message="toast_message" />
    </teleport>
</template>
<script>

import { mapActions } from "vuex";
export default {
    name:"QuickAccessPopup",
    data(){
        return{
            features: null,
            types: null,
            toast_type: '',
            toast_message: '',
            toast_show: false,
        }
    },
    props: {
        app: {
            type: Number,
            default: 0
        },
        options: {
            type: Array,
            default: []
        }
    },
    async beforeMount(){
        this.features = this.options
        this.types = await this.getTypes({});
    },
    computed: {
        parsedFeatures(){
            if(Object.keys(this.features).length === 0){
                if(this.features.json == undefined){
                    this.features.json = {};
                }
                this.features.json = [
                    {id: 1, label: 'Páginas', route: 'pages_cms', icon: "i-pages"},
                    {id: 2, label: 'Blocos', route: 'blocks_cms', icon: "i-blocks"},
                    // {id: 3, label: 'Sliders', route: 'none', icon: "i-sliders"},
                    {id: 4, label: 'Menus', route: 'menus_cms', icon: "i-menus"},
                    {id: 5, label: 'Media', route: 'media', icon: "i-media"},
                    {id: 6, label: 'Formulários', route: 'forms', icon: "i-forms"},
                    {id: 7, label: 'Campos Extra', route: 'fields', icon: "i-fields"},
                    {id: 8, label: 'Templates', route: 'templates', icon: "i-pages"},
                    {id: 9, label: 'Tipos', route: 'types', icon: "i-types"},
                    ]
            }
    
            return this.features.json;
            
        },
        availableFeatures(){
            let feat = [
                    {label: 'Páginas', value: 'pages_cms', checked: false},
                    {label: 'Blocos', value: 'blocks_cms', checked: false},
                    // {label: 'Sliders', value: 'none', checked: false},
                    {label: 'Menus', value: 'menus_cms', checked: false},
                    {label: 'Media', value: 'media', checked: false},
                    {label: 'Formulários', value: 'forms', checked: false},
                    {label: 'Campos Extra', value: 'fields', checked: false},
                    {label: 'Templates', value: 'templates', checked: false},
                    {label: 'Tipos', value: 'types', checked: false},
                ]

                let types = this.types.map( t =>  { return {
                    label: t.languages[0].name, value:t.languages[0].alias, checked:false
                }
            })

            return feat.concat(types)
        },

        availableIcons(){
            return [
                    {label: '', value: 'i-pages', img: 'i-pages' ,checked: false},
                    {label: '', value: 'i-blocks', img: 'i-blocks' ,checked: false},
                    {label: '', value: 'i-news', img: 'i-news' ,checked: false},
                    // {label: '', value: 'i-sliders', img: 'i-sliders' ,checked: false},
                    {label: '', value: 'i-menus', img: 'i-menus' ,checked: false},
                    {label: '', value: 'i-media', img: 'i-media' ,checked: false},
                    {label: '', value: 'i-forms', img: 'i-forms' ,checked: false},
                    {label: '', value: 'i-fields', img: 'i-fields' ,checked: false},
                    {label: '', value: 'i-types', img: 'i-types' ,checked: false},
                ]
        },
        typesAlias(){
            let final = [];
            this.types.forEach( t => {
                final[t.languages[0].alias] = t.languages[0].name
            })

            return final
        }
    },
    methods:{
		...mapActions([
			"saveCmsFeatureOrder", "deleteCmsFeatureOrder", "getTypes"
	  ]),
        add(c){
            console.log(this.typesAlias)
            this.features.json.push({id: this.features.json.length + 1, label: this.typesAlias[c.route], route: c.route, icon: c.icon})
        },
        remove(id){
            this.features.json.forEach( (c,k) => {
                if(c.id == id){
                    this.parsedFeatures.splice(k,1)
                }
            })
        },
        resort(info){
            //create clone of related to make only 1 update to the UI, instead of 1 for each item changed
            let associated = []

            this.parsedFeatures.forEach( r => {
                associated.push(null)
            });

            let oldOrder = info.oldOrder

            this.parsedFeatures.forEach( (rel,i) => {
                //give new order to updated item
                if(rel.id == info.item.id){
                    associated[info.newOrder-1] = info.item
                    //all items before the updated item decrease by 1
                }else if(oldOrder < info.newOrder && (i+1) <= info.newOrder && (i+1) >= oldOrder){
                    associated[i-1] = rel;
                    //all items after the updated item increase by 1
                }else if(oldOrder > info.newOrder && (i+1) >= info.newOrder && (i+1) < oldOrder){
                    associated[i+1] = rel;
                }else{
                    associated[i] = rel;
                }
            })

            this.features.json = associated
        },
      async save(){
        try{
            this.features.app_id = this.app;
            this.features.user_id = this.$store.getters.auth.user.id
            await this.saveCmsFeatureOrder({app_id: this.app_id, user_id: this.$store.getters.auth.user.id, data: this.features})
            this.toast_type = "success";
            this.toast_message = "Alterado com sucesso.";
            this.toast_show = true;
            this.close();
        }catch(exc){
            this.toast_type = "error";
            this.toast_message = "Falha ao atualizar. Tente novamente.";
            this.toast_show = true;
        }
      },
      close(){
        this.$parent.showQASettings = false;
      }
    },
    async mounted(){
    }
}
</script>
<style lang="scss" scoped>
@import "@/styles/components/layout/quick_access";
</style>