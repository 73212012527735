<template>
    <div class="cms">
        <Sidebar :menu="menu" :special="available_apps"/>
        <div class="main">
            <Header/>
                <div class="content">
                <label>{{view}}</label>
                <label class="content_name" v-if="$route.name == 'pages_cms_edit'">{{content_name}}</label>
                <label v-else>{{app.name}}</label>
                <router-view></router-view>
            </div>
        </div>
    </div>
    <teleport to="body">
        <transition name="fade">
            <LoadingPage v-if="transition" />
        </transition>
    </teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";


export default {
    name:"Cms",
    data() {
        return {
            menu: [
                {
                    label:this.$t('dashboard'),
                    icon:"i-dashboard",
                    route:"dashboard_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: []
                },
                {
                    label:this.$t('pages'),
                    icon:"i-pages",
                    route:"pages_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver páginas"
                },
                {
                    label:this.$t('blocks'),
                    icon:"i-blocks",
                    route:"blocks_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: ["blocks"],
                    buttonLabel: "Ver blocos"
                },
                // {
                //     label:this.$t('sliders'),
                //     icon:"i-sliders",
                //     route:"none",//"sliders",
                //     params: {},
                //     query: {},
                //     permissions: [],
                //     buttonLabel: "Ver sliders"
                // },
                {
                    label:this.$t('menus'),
                    icon:"i-menus",
                    route:"menus_cms",
                    params: {},
                    query: {},
                    permissions: ["menus"],
                    buttonLabel: "Ver menus"
                },
                {
                    label:this.$t('forms'),
                    icon:"i-forms",
                    route:"forms",
                    params: {},
                    query: {},
                    permissions: ["forms"],
                    buttonLabel: "Ver formulários"
                },
                {
                    label:this.$t('fields'),
                    icon:"i-fields",
                    route:"fields",
                    params: {},
                    query: {},
                    permissions: ["fields"],
                    buttonLabel: "Ver campos extra"
                },
                {
                    label:this.$t('media'),
                    icon:"i-media",
                    route:"media",
                    params: {},
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver media"
                },
                {
                    label:this.$t('templates'),
                    icon:"i-pages",
                    route:"templates",
                    params: {},
                    query: {},
                    permissions: ["templates"],
                    buttonLabel: "Ver templates"
                },
                {
                    label:this.$t('types'),
                    icon:"i-types",
                    route:"types",
                    params: {},
                    query: {},
                    permissions: ["types"],
                    buttonLabel: "Ver tipos"
                },
            ],
            loading: true,
            transition: true,
            app: '',
            content_name: '',
            view: this.$t('dashboard')
        }
    },
    computed: {
        ...mapGetters([
        "apps",
        "types",
        "cmsApp"
        ]),
        available_apps(){
            let available = [];
            if(this.$store.getters.apps != null)
                this.$store.getters.apps.forEach(app => {
                    if(app.token != 'backoffice' & app.token != this.app.token)
                        available.push(app)
                });

            return available;
        }
    },
    async beforeMount(){
        if(this.$store.getters.apps == null)
            await this.getApps();
            
        this.$store.getters.apps.forEach(async a => {
            if( a.id == this.$route.params.id ){
                this.setApp(a.token);
                this.setCmsApp(a.token);
                this.app = a;
                this.getLanguages();
            }
        });

        if(this.app == '' && this.$store.getters.app != 1)
            this.$store.getters.apps.forEach(a => {
                if(a.token == this.$store.getters.app){
                    this.app = a;
                }
            });

        // this.transition = false;
        this.loading = false;

    },
    methods: {
        ...mapActions(["getLanguages", "getApps", "setApp", "setCmsApp"]),
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/pages/dashboard.scss";   
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>