<template>
    <div class="addon attachments" :class="[{'open' : open}]">
        <div class="collapsible" @click="open = !open">
            <label>Anexos</label>
            <i class="i-collapse"/>
        </div>
        <div class="categories">
            <SimpleSelect 
                v-if="!addingCategory"
                :label="''"
                @select-value="filter = $event" :previewIcon="'i-order'" :options="createDropdownInfo(categories.filter( c => c.language_id == language), false, true)"/>
            <fyi-text
                v-else
                v-bind:value="newCat"
                v-on:input-text="newCat = $event"
                :label="''"
                :placeholder="'Nova Categoria'"
                :icon_box="false" :full="true"
                @enter-text="newCategory()"
            />
            <div v-if="addingCategory" class="input-bt">
                <fyi-button
                    :icon="'i-check'"
                    :className="'default upload-bt'"
                    :onclick="() => newCategory()"
                />
                <fyi-button
                    :icon="'i-remove file'"
                    :className="'default remove-bt'"
                    :onclick="() => addingCategory = false"
                />
            </div>
            <fyi-button
                v-else
                :label="'Nova Categoria'"
                :className="'default external'"
                :onclick="() => addCategory()"
            />
        </div>
        <Table ref="table" :items="{data: sort(content.attachments), total: content.attachments.length}"  :loading="loadTable" :tableClass="'attachments'"
            :pagination="false" @addon-order="resortAttachments"
            @edit="editFile" @remove="removeFile" @preview="previewFile" :showEntries="false" :showTotal="false" :showFilters="false" :language_index="language_index" :language="language"
        />
        <div class="button-bar">
            <fyi-button
                :label="'Adicionar ficheiro'"
                :className="'default add'"
                :onclick="() => addFile()"
            />
            <fyi-button
                :label="'Link externo'"
                :className="'default external'"
                :onclick="() => addExternalLink()"
            />
        </div>
        <teleport to="body">
            <div v-if="mediaOn" class="modal-media">
                <Media :modal="true" :mime="''" :multiSelect="true" @add-files="addFiles"/>
            </div>
        </teleport>
        <teleport to="body">
            <div v-if="editing!=null" class="bg">
                <div class="edit-file">
                    <label>Editar ficheiro</label>
                    <i class="i-close" @click="editing = null"/>
                    <div class="info" v-if="editing.mimetype">
                        <div class="preview">
                            <img v-if="editing.mimetype.includes('image')" :src="$store.getters.appConfig.url + editing.file">
                            <i v-else :class="setIconExtension(editing)" />
                        </div>
                        <div class="details">
                            <label><span>Criado:</span><span>{{            
                                new Date(editing.created_at).toLocaleDateString("pt-PT", {
                                format: "short",
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                })}}</span></label>
                            <label><span>Autor:</span><span>{{editing.fileInfo.user ? editing.fileInfo.user.name : '-'}}</span></label>
                            <label><span>Extensão:</span><span>{{!editing.file.includes('https') ? editing.file.split(".")[editing.file.split(".").length-1] : '-'}}</span></label>
                            <label><span>Tamanho:</span><span>{{(editing.fileInfo.size / (1024 ** 2)).toFixed(3) + 'MB'}}</span></label>
                            <label><span>Dimensões:</span><span>{{editing.fileInfo.dimensions ? editing.fileInfo.dimensions : "-"}}</span></label>
                            <div class="bt">
                                <fyi-button
                                    :label="'Preview'"
                                    :icon="'far fa-eye'"
                                    :className="'default preview'"
                                    :onclick="() => { previewFile(editing)}"
                                />
                                <fyi-button
                                    :label="'Download'"
                                    :className="'default download'"
                                    :icon="'i-upload file'"
                                    :onclick="() => { downloadEditFile() }"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="form">
                        <fyi-text
                            v-bind:value="editing.name"
                            v-on:input-text="editing.name = $event"
                            :label="'Nome'"
                            :placeholder="'Nome'"
                            :icon_box="false" :full="true"
                        />
                        <fyi-text
                            v-bind:value="editing.description"
                            v-on:input-text="editing.description = $event"
                            :label="'Texto Alternativo'"
                            :placeholder="'Texto Alternativo'"
                            :icon_box="false" :full="true"
                        />
                        <SimpleSelect 
                            :label="'Categorias'"
                            :className="'categories'"
                            @select-value="editing.category_id = $event" :previewIcon="'i-order'" :options="createDropdownInfo(categories.filter( c => c.language_id == language), editing.category_id)"/>
                        <fyi-text
                            v-bind:value="editing.file"
                            v-on:input-text="editing.file = $event"
                            :label="'Url'"
                            :disabled="!editing.file.includes('https')"
                            :icon_box="false" :full="true"
                        />
                    </div>
                    <div class="button-bar">
                        <fyi-button
                            :label="'Atualizar conteúdo'"
                            :className="'default add'"
                            :onclick="() => edit()"
                        />
                        <fyi-button
                            :label="'Eliminar'"
                            :className="'default external'"
                            :onclick="() => removeFile(editing.id)"
                        />
                    </div>
                </div>
            </div>
        </teleport>
        <teleport to="body">
            <div v-if="addExternal!=false" class="bg">
                <div class="external-file">
                    <label>Adicionar Link Externo</label>
                    <div class="form">
                        <fyi-text
                            v-bind:value="''"
                            v-on:input-text="external.name = $event"
                            :label="'Nome'"
                            :placeholder="'Nome'"
                            :icon_box="false" :full="true"
                        />
                        <fyi-text
                            v-bind:value="''"
                            v-on:input-text="external.description = $event"
                            :label="'Texto Alternativo'"
                            :placeholder="'Texto Alternativo'"
                            :icon_box="false" :full="true"
                        />
                        <SimpleSelect 
                            :label="'Categorias'"
                            :className="'categories'"
                            @select-value="external.category_id = $event" :previewIcon="'i-order'" :options="createDropdownInfo(categories.filter( c => c.language_id == language))"/>
                        <fyi-text
                            v-bind:value="''"
                            v-on:input-text="external.path = $event"
                            :label="'Url'"
                            :icon_box="false" :full="true"
                        />
                    </div>
                    <div class="button-bar">
                        <fyi-button
                            :label="'Atualizar conteúdo'"
                            :className="'default add'"
                            :disabled="external.name == '' || external.path == ''"
                            :onclick="() => addExternalAttach()"
                        />
                        <fyi-button
                            :label="'Cancelar'"
                            :className="'default external'"
                            :onclick="() => addExternal = false"
                        />
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name:"AddonAttachments",
    props: {
        content: {
            type: Object,
            default: null
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        }
    },
    data(){
        return{
            open: false,
            loadTable: false,
            mediaOn: false,
            files: [],
            categories: [],
            filter: '',
            addingCategory: false,
            newCat: '',
            editing: null,
            addExternal: false,
            external: {name:'',path:'',category_id:0}
        }
    },
    watch: {
        filter(_new){
            if(_new == -1){
                this.$refs.table.$el.querySelectorAll('.table-row').forEach( el => {
                    el.classList.remove("hidden")
                })
            }else{
                this.$refs.table.$el.querySelectorAll('.table-row').forEach( el => {
                    el.classList.add("hidden")
                })
                this.$refs.table.$el.querySelectorAll('.table-row[category-id="'+_new+'"]').forEach( el => {
                    el.classList.remove("hidden")
                })
            }
        }
    },
    async beforeMount(){
        this.categories = await this.getAddonsAttachmentsCategories()
    },
    methods: {
        ...mapActions(["getAddonsAttachmentsCategories", "createAttachmentCategory"]),
        sort(list){
            //sort list by order to be shown on table
            return list.sort((a, b) => (a.orderattachment >= b.orderattachment) ? 1 : -1)
        },
        resortAttachments(info){
            //create clone of attachments to make only 1 update to the UI, instead of 1 for each item changed
            let attachments = this.content.attachments;
            let oldOrder = info.item.orderattachment

            attachments.forEach( attach => {
                if(attach.language_id == this.language){
                    //give new order to updated item
                    if(attach.id == info.item.id){
                        attach.orderattachment = parseInt(info.newOrder)
                        //all items before the updated item decrease by 1
                    }else if(oldOrder < info.newOrder && attach.orderattachment <= info.newOrder && attach.orderattachment >= oldOrder){
                        attach.orderattachment -= 1;
                        //all items after the updated item increase by 1
                    }else if(oldOrder > info.newOrder && attach.orderattachment >= info.newOrder && attach.orderattachment < oldOrder){
                        attach.orderattachment +=1;
                    }else{
                        attach.orderattachment = attach.orderattachment
                    }
                }
            })

            this.content.attachments = attachments
        },
        createDropdownInfo(options, value, allOption = false){
            let list = [{
                    label: 'Todas as categorias',
                    value: -1,
                    checked: value ? false : true
                }, {
                    label: 'Sem categoria',
                    value: 0,
                    checked: false
                }];

            if(allOption == false){
                list = [{
                    label: 'Sem categoria',
                    value: 0,
                    checked: value ? false : true
                }];
            }

            for (let i = 0; i < options.length; i++) {
                if(options[i].value){
                    list.push(options[i])
                }else{
                    list.push(                
                    {
                        label: options[i].name,
                        value: options[i].id,
                        checked: options[i].id == value
                    })
                }
            }

            return list
        },
        previewFile(file){
            //TO DO - add plugin
            if(file.file.includes('http'))
                window.open(file.file, "_blank");
            else
                window.open(this.$store.getters.appConfig.url + file.file, "_blank");
        },
        addFiles(files){
            this.files.concat(files);
            this.$emit('add-files-attachments', {files: files, category: this.filter});
        },
        addFile(){
            this.mediaOn = true;
        },
        removeFile(id){
            this.$emit('remove-files-attachments', id);
        },
        addExternalLink(){
            this.addExternal = true
        },
        addExternalAttach(){
            if(!this.external.path.includes("http")){
                this.external.path = 'https://' + this.external.path;
            }
            this.$emit('add-files-attachments', {files: [this.external], category: this.external.category_id});
            this.external = {name:'',path:'',category_id:0}
            this.addExternal = false
        },
        editFile(file){
            this.editing = file
        },
        edit(){
            this.content.attachments.forEach( attach => {
                if(attach.id == this.editing.id){
                    attach = this.editing
                }
            })

            this.editing = null
            this.$parent.$parent.$refs.seo.analyze();
        },
        downloadEditFile(){
            //Create XMLHTTP Request.
            let req = new XMLHttpRequest();
            req.open("GET", this.$store.getters.appConfig.url + this.editing.path, true);
            req.responseType = "blob";
            req.onload = () => {
                //Convert the Byte Data to BLOB object.
                let blob = new Blob([req.response], { type: "application/octetstream" });
 
                //Check the Browser type and download the File.
                let isIE = false || !!document.documentMode;
                if (isIE) {
                    window.navigator.msSaveBlob(blob, this.editing.name);
                } else {
                    let url = window.URL || window.webkitURL;
                    let link = url.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.setAttribute("download", this.editing.name);
                    a.setAttribute("href", link);
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            };
            req.send();

        },
        addCategory(){
            this.addingCategory = true
        },
        async newCategory(){
            let c = await this.createAttachmentCategory({language_id: this.language, name:this.newCat})
            if(c != null){
                let category = {
                    name: this.newCat,
                    id: c,
                    language_id: this.language
                }
                this.categories.push(category)
            }
            this.filter = c
            this.addingCategory = false
        },
        setIconExtension(item){
            let extension = item.file.split(".")[item.file.split(".").length - 1];
            let available = ["svg", "pdf", "apk", "css", "doc", "iso", "js", "ppt", "php", "psd", "sql", "txt", "ttf", "xls", "zip"]
            let groups = []

            for (let i = 0; i < available.length; i++) {
                if(extension.toLowerCase().includes(available[i]) && extension.toLowerCase() != 'json')
                return 'i-'+available[i]
            }

            return 'i-other'
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>