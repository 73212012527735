<template>
	<div class="date">
		<label v-if="label">{{label}}</label>
        <Datepicker @update:modelValue="$emit('update:modelValue', $event)" :locale="'pt-PT'" cancelText="Cancelar" selectText="Selecionar" :format="format" :enableTimePicker="false" :minDate="minDate" :maxDate="maxDate" v-model="value" />
	</div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
    name: "Date",
    components: { Datepicker },
    props: {
        value: [String, Object, Date],
        alias: String,
        type: {
            type: String,
            default: "datetime"
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        range: {
            type: Boolean,
            default: false
        },
        hourFlag: {
            type: Boolean,
            default: true
        },
        format: {
            type: String,
            default: "dd/MM/yyyy"
        },
        minDate: {
            type: String,
            default: null
        },
        maxDate: {
            type: String,
            default: null
        }
    },
    data() {
        return {
        }
    },
    mounted(){
    }
    
};
</script>

<style lang="scss" scoped>
//@import "@/styles/components/form/datepicker";
</style>
