<template>
	<div :class="['fyi-input text ' + className, {'error': v$.value.$error }, {'disabled': disabled}, {'icon-box': icon_box }, {'full': full }]">
		<label v-if="label">{{label}}</label>
        <span v-if="charCount" class="counter">{{ (value != null ? value.length : 0) + " caracteres"}}</span>
		<input
			class="input-text"
			type="text"
			:placeholder="placeholder"
			:required="required"
			:disabled="disabled"
			v-on:blur="$emit('blur')"
            v-on:keydown.enter="handleEnter"
			v-bind:value="value"
			v-on:input="$emit('input-text', $event.target.value); $emit('text-valid', !(v$.value.$error))"
            @keyup.enter="$emit('enter-text', $event.target.value); $emit('text-valid', !(v$.value.$error))"
			v-on:change="$emit('change', $event.target.value)"
		/>
		<i v-if="inputError" class="error-icon fas fa-exclamation-circle"></i>
        <label v-if="inputError" class="error-label">{{ errorMessage }}</label>
	</div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
    name: "fyi-text",
    setup () { return { v$: useValidate() } },
	watch: {
		value(_new, _old){
            if(this.required)
			    this.v$.$validate();
		}
	},
    props: {
        value: [String, Number],
        alias: String,
        className: {
            type: String,
            default: ""
        },
        icon_box: {
            type: Boolean,
            default: true
        },
        errorMessage: {
			type: String,
			default: "Campo obrigatório"
		},
		inputError: {
			type: Boolean,
			default: false
		},
        full: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        onEnter: {
            type: Function
        },
        charCount: {
            type: Boolean,
            default: false
        }
    },
    validations () {
		return {
			value: {
				required
			}
		}
	},
    methods: {
        handleEnter(e) {
            e.preventDefault();
            e.stopPropagation();

            if(this.onEnter) {
                this.onEnter();
                this.$el.querySelector("input").blur();
            }
        }
    },
	mounted(){
		if(this.value !== "" && this.required){
			this.v$.$validate();
			this.$emit('text-valid', !(this.v$.value.$error))
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/form/input";
</style>