import _ from "lodash";
import Api from "./api";
import store from "../../store";

class Clients extends Api {
    constructor() {
        super();
        this.uri = "clients";
        this.requiresAuth = true;
    }
	
	isTrial() {
		let url = this.uri + "/" + window.location.hostname + "/trial";
        return this.get(url, this.requiresAuth);
	}

	deleteDomain() {
		let url = this.uri + "/" + window.location.hostname;
        return this.delete(url, this.requiresAuth);
	}

    clients() {
        let url = this.uri ;
        return this.get(url, this.requiresAuth);
    }
}

export default new Clients();
