import _ from "lodash";
import Api from "../api";
import store from "../../../store";

class Fields extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/fields";
        this.requiresAuth = true;
    }

    getFields(params) {
        let url = this.uri + (params ? "?" + params : "");
        return this.get(url, {}, this.requiresAuth);
    }

    getField(params, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.get(url, this.requiresAuth);
    }

    updateField(params, data, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.put(url, data, this.requiresAuth);
    }

    createField(params, data) {
        let url = this.uri + (params ? "?" + params : "");
        return this.post(url, data, this.requiresAuth);
    }

    deleteField(params, id) {
        let url = this.uri + "/" + id + (params ? "?" + params : "");
        return this.delete(url, this.requiresAuth);
    }
}

export default new Fields();
