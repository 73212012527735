<template>
	<div class="time">
		<label v-if="label">{{label}}</label>
        <Datepicker @update:modelValue="$emit('update:modelValue', $event)" :locale="'pt-PT'" cancelText="Cancelar" selectText="Selecionar" :format="format" :timePicker="true" :minTime="minTime" :maxTime="maxTime" v-model="time" />
    </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';

import '@vuepic/vue-datepicker/dist/main.css'
export default {
    components: { Datepicker },
    name: "Time",
    props: {
        value: [String, Object],
        alias: String,
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: "HH:mm"
        },
        minTime: {
            type: Object,
            default: null
        },
        maxTime: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            date: null,
            time: ""
        }
    },
    beforeMount() {
        this.loading = true;

        //on loading set time in format received by the plugin
        if(this.value != '' && this.value != undefined)
            this.time = {
                hours: parseInt(this.value.split(":")[0]),
                minutes: parseInt(this.value.split(":")[1]),
                seconds: 0
            };

        this.loading = false;
    }
};
</script>

<style lang="scss" scoped>
//@import "@/styles/components/form/datepicker";

</style>
