import _ from "lodash";
import Api from "./../api";

class Users extends Api {
    constructor() {
        super();
        this.uri = "users";
        this.requiresAuth = true;
    }

    all(params) {
        return this.get(this.uri + (params ? "?" + params : ""));
	}
	
	groups() {
        return this.get(this.uri + "/groups");
    }


    show(id) {
        return this.get(this.uri + "/" + id);
	}
	
    resendInvite(id) {
        return this.get(this.uri + "/" + id + "/resend-invite");
    }

    create(data) {
        return this.post(this.uri, data);
    }

    remove(id) {
        return this.delete(this.uri + "/" + id);
    }

    edit(id, data) {
        return this.put(this.uri + "/" + id, data);
    }

    editApps(id, data) {
        return this.patch(this.uri + "/" + id + "/apps", data);
    }

    import(data) {
        return this.post(this.uri + "/import", data);
    }
}

export default new Users();