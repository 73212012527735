import _ from "lodash";
import Api from "../api";
import store from "../../../store";

class Configs extends Api {
    constructor() {
        super();
        this.uri = "cms/v4/configs";
        this.requiresAuth = true;
    }

    getConfig(id) {
        let url = this.uri + "/" + id;
        return this.get(url, {}, this.requiresAuth);
    }
}

export default new Configs();
