import Users from "../../../libs/api/users/users";
import store from "../../../store"
export default {
    state: {
        users: [],
        usersPage: 0,
        usersCount: 0,
        currentUsersPageCount: 0,
    },

    getters: {
        users: state => state.users,
        groups: state => state.groups,
        usersCount: state => state.usersCount,
        currentUsersPageCount: state => state.currentUsersPageCount,
    },

    mutations: {
        setUsers: (state, users) => {
            state.users = users;
        },
        setUsersCount: (state, count) => {
            state.blocksCount = count;
        },
        setCurrentPageCount: (state, count) => {
            state.currentUsersPageCount = count;
        }
    },

    actions: {
        getUsers: async ({
            commit
        }, params) => {
            const response = await Users.all(params);
            commit("setUsers", response);
            return response
        },
        deleteUser: async ({}, id) => {
            await Users.remove(id);
        },
        createUser: async ({}, data) => {
            await Users.create(data);
        },
        getUser: async ({}, id) => {
            return await Users.show(id);
		},
		
        resendInvite: async ({}, id) => {
            return await Users.resendInvite(id);
		},
		
        getGroups: async ({}) => {
            return await Users.groups();
        },
        editUser: async ({}, {
            id,
            data
        }) => {
            return await Users.edit(id, data);
        },
        setBackofficeAccess: async ({}, {
			id,
			app_id,
            flag
        }) => {
            await Users.editApps(id, {
                "apps": [{
                    "app_id": app_id,
                    "action": (flag) ? "add" : "remove"
                }]
            })
        },
        importUsers: async ({}, data) => {
            const response = await Users.import(data);
            return response
        },
        updateLoggedUser: async ({
            commit, dispatch
        }) => {

			try {
                let data =  await Users.show(store.getters.auth.user.id);
                const {
                    name,
                    id,
                    phone,
                    avatar,
					roles,
					info,
                    apps
                } = data;
    
                commit("setUser", {
                    name,
                    id,
					phone,
					info,
                    avatar,
                    roles,
                    apps
                });

			} catch (error) {
				// dispatch("logout")
				return		
			}
        },
    }
};