<template>
  <div class="addon collections" :class="[{ open: open }]">
    <div class="collapsible" @click="open = !open">
      <label>Coleções</label>
      <i class="i-collapse" />
    </div>
    <div class="collections-form">
      <InputTags
        v-model="app"
        :tags="addon[language_index].categories.split(',')"
        :label="'Categorias'"
        :disabled="false"
        :icon_box="false"
        :full="true"
        :noUrlRedirect="true"
        @input-tags="
          addon[language_index].categories = $event;
          $emit('update-collection', addon);
        "
      />
      <SimpleSelect
        :label="'Disponibilidade de Conteúdo'"
        @select-data-value="
          addon[language_index].availability = $event;
          $emit('update-collection', addon);
        "
        :previewIcon="'i-order'"
        :options="
          availability.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: addon[language_index].availability == p.value,
            };
          })
        "
        :key="addon[language_index].availability"
      />
      <fyi-number
        :label="'Duração da disponibilidade (horas)'"
        v-bind:value="addon[language_index].availability_time"
        v-on:input-number="
          addon[language_index].availability_time = $event;
          $emit('update-collection', addon);
        "
        :alias="''"
        :classe="''"
        :show="true"
        :inputError="false"
      />
      <fyi-number
        :label="'Preço'"
        v-bind:value="addon[language_index].price"
        v-on:input-number="
          addon[language_index].price = $event;
          $emit('update-collection', addon);
        "
        :alias="''"
        :classe="''"
        :show="true"
        :inputError="false"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddonCollections",
  props: {
    content: {
      type: Object,
      default: null,
    },
    language_index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      open: false,
      availability: [
        {
          label: "Grátis",
          value: "gratis",
          checked: false,
        },
        {
          label: "Payperview",
          value: "payperview",
          checked: false,
        },
        {
          label: "Subscrição",
          value: "subscription",
          checked: false,
        },
      ],
    };
  },
  beforeMount() {
    this.addon = this.content.colecoes;

    this.$store.getters.languages.forEach((l) => {
      if (
        !this.addon
          .map((a) => {
            return a.language_id;
          })
          .includes(l.id)
      )
        this.addon.push({
          language_id: l.id,
          availability: "",
          price: "",
          categories: "",
          availability_time: ""
        });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/layout/addons.scss";
</style>
