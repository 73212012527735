<template>
    <label class="form-title">Logs</label>
    <div class="logs">
        <div class="page-content">
            <Table ref="table" :tableClass="'logs'" :items="logs" :paginationLoading="paginationLoading" :loading="loadTable" :limit="limit"
            @handlePagination="handlePagination" @apply-order="updateOrder" @apply="updateFilter($event)" @filter-type="updateFilter({value:$event})"
            @search="searchValue = $event" :availableOrders="availableOrders" :availableDirections="availableDirections"  :availableEntries="availableEntries" @entry-select="setLimit($event)" :optionButtons="optionButtons"
            @remove="removeItem" @toggleSwitch="toggleSwitch" @edit="editItem"/>
        </div>
        <teleport to="body">
            <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
        </teleport>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name:"Logs",    
    data() {
        return {
            loading: true,
            limit: 9,
            limitTimeout: null,
            limitOnResize: true,
            order: 'id_desc',
            content_state: 'both',
            logs: [],
            availableEntries:[
                {
                    label: "Default",
                    value: "default",
                    checked: true
                },
                {
                    label: "20",
                    value: "20",
                    checked: false
                },
                {
                    label: "50",
                    value: "50",
                    checked: false
                },
                {
                    label: "100",
                    value: "100",
                    checked: false
                },
            ],
            availableOrders: [
                {
                    alias:"timestamp",
                    label:this.$t('date_created'),
                    checked: false
                },
                {
                    alias:"id",
                    label: this.$t('ID'),
                    checked: true
                }
            ],
            availableDirections:[
                {
                    alias:"asc",
                    label:this.$t('asc'),
                    checked: false
                },
                {
                    alias:"desc",
                    label:this.$t('desc'),
                    checked: true
                },
            ],
            loadTable: false,
            paginationLoading: false,
            filterOn: false,
            searchValue: "",
            toast_type: '',
            toast_message: '',
            toast_show: false
        }
    },
    async beforeMount(){
        this.changeLimit();
        this.logs = await this.getLogs('paginate=true&limit='+this.limit);
        this.$parent.$parent.transition = false;
    },
    created() {
        window.addEventListener("resize", this.changeLimit);
    },
    destroyed() {
        window.removeEventListener("resize", this.changeLimit);
    },
    watch: {
        async searchValue(_new, _old){
            if(_new.trim() == "" && _old == '' )
                return

            await this.search()
        },
        async order(_new, _old){
            if(this.searchValue){
                this.search()
                return
            }

            this.$refs.table.resetPage(1)

            this.loadTable = true;
            this.paginationLoading = true;

            this.logs = await this.getLogs('paginate=true&limit='+this.limit+"&order="+_new);

            this.loadTable = false;
            this.paginationLoading = false;
        },
    },
    methods:{
        ...mapActions(["getLogs"]),
                //handles pagination requests
        async handlePagination(page){
            //set current table size to maintain gap for the pagination widget so it doesnt move around
            this.$refs.table.$el.querySelector(".pagination").style.top = this.$refs.table.$el.querySelector(".template-table").clientHeight + 47 + "px";

            //if theres a value in the search input perform search action otherwise continue
            if(this.searchValue){
                this.search(page)
                return
            }

            //show loading inside the table element
            this.loadTable = true;

            this.logs = await this.getLogs('paginate=true&limit='+this.limit+'&page='+page);

            //stop loading widget
            this.loadTable = false;
            this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        updateOrder(info){
            let order = ''
            for(let k in info["states"][0])
                if(info["states"][0][k]) order = k+"_"

            for(let k in info["states"][1])
                if(info["states"][1][k]) order += k
            
            this.order = order
        },
        async reload(){
            this.$refs.table.resetPage(1)
            if(this.searchValue){
                this.search()
                return
            }

            this.loadTable = true;
            this.paginationLoading = true;

            this.logs = await this.getLogs('paginate=true&limit='+this.limit);

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async search(page = false){
            this.loadTable = true;
            if(!page){
                this.paginationLoading = true;
                this.$refs.table.resetPage(1)
            }

            if(this.searchValue == '')
                this.logs = await this.getLogs('paginate=true&limit='+this.limit+"&order="+this.order);
            else if(page)
                 this.logs = await this.getLogs('paginate=true&limit='+this.limit+'&page='+page+"&order="+this.order);
            else
                this.logs = await this.getLogs('paginate=true&limit='+this.limit+'&searchQuery='+ this.searchValue+"&order="+this.order);

            this.loadTable = false;

            if(!page)
                this.paginationLoading = false;

            if(this.$refs.table.$el.querySelector(".pagination")) this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        setLimit(value){
            //handles content limit set on the UI
            if(value != 'Default'){
                this.limitOnResize = false;
                this.limit = parseInt(value);
                if(this.logs.data != undefined){
                    this.reload();
                }
            }else{
                this.limitOnResize = true;
                this.changeLimit();
            }
        },
        changeLimit(){
            if(!this.$parent.$parent.$parent.$parent.expired && this.limitOnResize){
                //throttling so it doesnt make the same request while still performing a resize
                clearTimeout(this.limitTimeout);
                this.limitTimeout = setTimeout(() => {
                    let availableHeight = window.innerHeight - 144 - 144 - 88 - 30; // 144 - page header; 144 - table filters; 30 - table header; 88 - pagination element
                    let rows = 0, rowHeight = 60;
                    //while theres still available space increase the number of rows
                    while((availableHeight - rowHeight) > rowHeight){
                        availableHeight -= rowHeight;
                        rowHeight = rowHeight == 60 ? 44 : 60; //row height changes every other row
                        rows++;
                    }
                    
                    this.limit = rows == 0 ? 9 : rows //9 is default number of rows
                    //if there is content available reload
                    if(this.logs.data != undefined){
                        this.reload();
                    }
                }, 500)
            }
        }
    },
    mounted(){
        this.$parent.$parent.view = this.$t('logs')
    }
}
</script>

<style lang="scss">
    @import "../../styles/pages/logs.scss";   
</style>