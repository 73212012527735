<template>
    <div class="addon vision" :class="[{'open' : open}]">
        <div class="collapsible" @click="open = !open">
            <label>Vision</label>
            <i class="i-collapse"/>
        </div>
        <Table ref="table" :items="{data: content.visions, total: content.visions.length}"  :loading="loadTable" :tableClass="'vision'"
            :pagination="false"
            @remove="removeFile" @preview="previewFile" :showEntries="false" :showTotal="false" :showFilters="false" :language_index="language_index" :language="language"
        />
        <div class="button-bar">
            <fyi-button
                :label="'Adicionar ficheiro'"
                :className="'default add'"
                :onclick="() => addFile()"
            />
            <fyi-button
                :label="'Link externo'"
                :className="'default external'"
                :onclick="() => addExternalLink()"
            />
        </div>
        <teleport to="body">
            <div v-if="mediaOn" class="modal-media">
                <Media :modal="true" :mime="''" :multiSelect="true" @add-files="addFiles"/>
            </div>
        </teleport>
        <teleport to="body">
            <div v-if="addExternal!=false" class="bg">
                <div class="external-file">
                    <label>Adicionar Link Externo</label>
                    <div class="form">
                        <fyi-text
                            v-bind:value="''"
                            v-on:input-text="external.path = $event"
                            :label="'Url'"
                            :icon_box="false" :full="true"
                        />
                    </div>
                    <div class="button-bar">
                        <fyi-button
                            :label="'Atualizar conteúdo'"
                            :className="'default add'"
                            :disabled="external.path == ''"
                            :onclick="() => addExternalAttach()"
                        />
                        <fyi-button
                            :label="'Cancelar'"
                            :className="'default external'"
                            :onclick="() => addExternal = false"
                        />
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name:"AddonVision",
    props: {
        content: {
            type: Object,
            default: null
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        }
    },
    data(){
        return{
            open: false,
            loadTable: false,
            mediaOn: false,
            files: [],
            filter: '',
            editing: null,
            addExternal: false,
            external: {name:'',path:''}
        }
    },
    methods: {
        previewFile(file){
            //TO DO - add plugin
            if(file.file.includes('http'))
                window.open(file.file, "_blank");
            else
                window.open(this.$store.getters.appConfig.url + file.file, "_blank");
        },
        addFiles(files){
            this.files.concat(files);
            this.$emit('add-files-vision', files);
        },
        addFile(){
            this.mediaOn = true;
        },
        removeFile(id){
            this.$emit('remove-files-vision', id);
        },
        addExternalLink(){
            this.addExternal = true
        },
        addExternalAttach(){
            if(!this.external.path.includes("http")){
                this.external.path = 'https://' + this.external.path;
            }
            this.$emit('add-files-vision', [this.external]);
            this.external = {name:'',path:''}
            this.addExternal = false
        },
        editFile(file){
            this.editing = file
        },
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>