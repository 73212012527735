import _ from "lodash";
import Api from "./../api";

class Misc extends Api {
    constructor() {
        super();
        this.uri = "";
        this.requiresAuth = true;
    }

    getPatchNotes() {
        return this.get('/patch/latest' , {}, this.requiresAuth);
    }

    getLogs(params) {
        return this.get('/logs' + (params ? "?" + params : "") , {}, this.requiresAuth);
    }

    getCmsFeatureOrder(user_id, app_id){
        return this.get('/app/'+app_id+'/features/'+user_id+'/order', this.requiresAuth);
    }

    saveCmsFeatureOrder(data, user_id, app_id){
        return this.post('/app/'+app_id+'/features/'+user_id+'/order', data,this.requiresAuth);
    }

    deleteCmsFeatureOrder(user_id, app_id){
        return this.delete('/app/'+app_id+'/features/'+user_id+'/order', this.requiresAuth);
    }
}

export default new Misc();
