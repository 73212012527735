<template>
  <li :class="['type', {'bottom-level': node.children.length == 0}, {'active': shown || itself}, {'selected': itself}]">
    <span v-if="node.languages.name != ''" class="label" ref="span" @click="toggleLevel($event)">{{ node.languages.name }}</span>
    <ul v-if="node.children.length > 0" :class="['level-'+level]">
      <TypesTree v-for="(child) in node.children" :node="child" :level="level+1" :shown="nextLevelVisible" :key="child.id" ref="child" @current_type="forwardUp"/>
    </ul>
  </li>
</template>

<script>
export default {
  name: "TypesTree",
  props: {
    node: Object,
    level: Number,
    shown: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return {
      nextLevelVisible: false,
      itself: false,
      curTypes: ''
    }
  },
  methods: {
    toggleLevel(event){
      //activate next level nodes
      this.nextLevelVisible = !this.nextLevelVisible;

      // if next level exists
      if(this.node.children.length > 0){

        //if not inside a level remove every item selected from parent
        if(!this.itself)
          this.$parent.$refs.child.forEach( c => c.itself = false)
        else{
          //if inside a level remove every item selected from it
          this.$refs.child.forEach( c => c.itself = false)
        }

        //disable from view all the items on same level as parent
        this.$parent.nextLevelVisible = !this.$parent.nextLevelVisible;

        //besides the parent itself
        if(this.$parent.itself !=undefined && this.$parent.$refs.span != undefined)
          this.$parent.$refs.span.classList.toggle("hidden")

        this.itself = !this.itself
      //if current selected item clicked, deactivate
      }else if(this.itself){
        this.itself = false;
      //only one type active at a time
      }else{
          this.$parent.$refs.child.forEach( c => c.itself = false)
          this.itself = true
      }

      this.curTypes = ''
      let current = this;
      while(current.itself != undefined){
        if(current.itself){
          this.curTypes = current.node.associated_types
          break;
        }else{
          current = current.$parent;
        }
      }

      //send info up the chain
      this.forwardUp(this.curTypes);

      event.stopPropagation();
    },
    //Pass the current selected type up the chain
    forwardUp(event){
      this.curTypes = event
      this.$emit('current_type', event)
    }
  }, 
  mounted(){
    //show all items of the first level on first render
    if(this.level == 1)
      this.nextLevelVisible = true

  }
}
</script>

<style>

</style>