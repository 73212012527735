import _ from "lodash";
import Api from "./api";
import store from "./../../store";

class Apps extends Api {
    constructor() {
        super();
        this.uri = "apps";
        this.requiresAuth = true;
    }

    getApps() {
        return this.get(this.uri, {}, this.requiresAuth);
    }

    updateAppConfig(id, params){
        return this.put(this.uri+"/"+id+"/config", params, this.requiresAuth)
    }
}

export default new Apps();
