<template>
  <div v-if="crumbs.length > 0" class="fyi-breadcrumb-label">
      <router-link v-for="(crumb, key) in crumbs" :key="key" :to="{path:crumb.route}">
        {{crumb.crumb}}
  </router-link>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: 'fyi-breadcrumb-label',
    props: {},
    data() {
        return {
            appActive: null,
            type: null,
            typeGroup: null,
            contentCrumb: null
        }
    },
    computed: {
        ...mapGetters(["apps", "app", "content", "types"]),
        crumbs() {
            let crumbs = [];
            let name = this.appActive;
            let paths = this.$route.path.split("/");
            let type = this.type;
            let typeGroup = this.typeGroup;
            let content = this.contentCrumb;
            
            if (paths.length >= 3) {
                if (name && paths.includes("cms")) {
                    //crumbs.push({crumb:name});
                    //if (paths[2]) crumbs.push({crumb: this.$t("general.breadcrumb." + paths[2]), route: paths[0] + "/" + paths[1] + "/" + paths[2]});
                    if (typeGroup) crumbs.push({crumb: typeGroup});
                    if (type) crumbs.push({crumb: type, route: paths[0] + "/" + paths[1] + "/" + paths[2] + "/" + paths[3] + "/" + paths[4] + "/" + paths[5]});
                    if (content) crumbs.push({crumb: content});

                    if (!typeGroup && !type && !content) {
                        if (paths[3]) crumbs.push({crumb:this.$t("general.breadcrumb." + paths[3]), route: paths[0] + "/" + paths[1] + "/" + paths[2] + "/" + paths[3]});
                    }
                } else {
                    let matched = this.$route.matched;
                    for (let i = 0; i < matched.length; i++) {
                        if (matched[i].name !== undefined) {
                           if(!crumbs.includes(this.$t("general.breadcrumb." + matched[i].name))){
                                crumbs.push(this.$t("general.breadcrumb." + matched[i].name));
                            }
                        } else {
                            let split = matched[i].path.split("/")
                            let splitName = split[split.length - 1];
                            if(!crumbs.includes(this.$t("general.breadcrumb." + splitName))){
                                crumbs.push(this.$t("general.breadcrumb." + splitName));
                            }
                        }
                    }
                }
			}
            return crumbs;
        }
    },
    methods: {
        ...mapActions(["getApps", "getContent"]),
        async parseRoutesCrumb(path) {
            if (path.name.includes("cms-content")) {
                let { typeId, id } = path.params
                let types = this.types;
                let type = this.types.find(t => t.id == typeId)

                if (type) {
                    this.type = type.languages[0].name;
    
                    if (type.languages[0].group_name)
                        this.typeGroup = type.languages[0].group_name;
                }
                
                if (!id) {
                    this.contentCrumb = null;
                } else {
                    let contents = (this.contents && this.contents.data) ? this.contents.data : [];
                    let content = contents.find(c => c.id == id);
                    
                    if (content)
                        this.contentCrumb = content.languages[0].name;
                }
            } else {
                this.type = null;
                this.typeGroup = null
                this.contentCrumb = null;
            }
        }
    },
    watch: {
        app(newValue, oldValue) {
            let app = this.apps.find(a => a.token === this.app)
            this.appActive = app.name;
        },
        apps(newValue, oldValue) {
            this.appActive =  false;
            if (newValue) {
                let app = newValue.find(a => a.token === this.app)
                if (app) this.appActive = app.name;
            }
        },
        $route(to, from) {
            this.typeGroup = null;
            this.parseRoutesCrumb(to);
        },
        types(newTypes, oldTypes) {
            let { typeId } = this.$route.params

            if (this.$route.name.includes("cms-content") && typeId) {
                let type = newTypes.find(t => t.id == typeId)
                if (type) {
                    this.type = type.languages[0].name;
    
                    if (type.languages[0].group_name)
                        this.typeGroup = type.languages[0].group_name;
                }
            } else {
                this.type = null;
                this.typeGroup = null;
            }
        },
        content(newContent, oldContent) {
            let { id } = this.$route.params

            if (this.$route.name.includes("cms-content") && newContent.id == id) {
                this.contentCrumb = newContent.languages[0].name;
            } else {
                this.contentCrumb = null;
            }
        }
    },
    async mounted() {
        
        if (this.apps !== null && this.apps.length > 0)
        {
            let app = this.apps.find(a => a.token === this.app)
            this.appActive = app.name;
        }

        await this.parseRoutesCrumb(this.$route);
    },
}
</script>

<style lang="scss">
//@import "@/styles/components/navigation/breadcrumb-label";
</style>
