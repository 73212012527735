import "es6-promise/auto"; // old browser's (e.g. IE) Promise support
import Vuex from "vuex";

import base from "./modules/base";
import clients from "./modules/clients";
import init from "./modules/init";
import auth from "./modules/auth";
import language from "./modules/language";
import apps from "./modules/apps";
import filemanager from "./modules/filemanager";
import users from "./modules/users/users";
import userFields from "./modules/users/fields";
import roles from "./modules/roles";
import backofficeConfigs from "./modules/configs";
import calendar from "./modules/calendar/calendar";
import apis from "./modules/apis";

// CMS Store
import blocks from "./modules/cms/blocks";
import fields from "./modules/cms/fields";
import languages from "./modules/cms/languages";
import templates from "./modules/cms/templates";
import categories from "./modules/cms/categories";
import metadatas from "./modules/cms/metadatas";
import types from "./modules/cms/types";
import contents from "./modules/cms/contents";
import configs from "./modules/cms/configs";
import addons from "./modules/cms/addons";
import search from "./modules/cms/search";
import task from "./modules/worklog/task";
import worklog from "./modules/worklog/worklog";
import entity from "./modules/entity";
import misc from "./modules/misc";

//NODE
import node from "./modules/node";
import notifications from "./modules/node/notifications";

//ZELO
import zelo from "./modules/zelo";

const store = new Vuex.Store({
    modules: {
        base,
        init,
        auth,
        language,
        filemanager,
        apps,
        entity,
		users,
		userFields,
		roles,
		backofficeConfigs,
		clients,
		apis,
        // cms store
        blocks,
        templates,
        contents,
        types,
        languages,
        configs,
        fields,
        categories,
        addons,
        metadatas,
        search,
        // worklog store
        task,
        worklog,
        // node
        node,
		notifications,
		//zelo/inbox
        zelo,
        // calendar
        calendar,
        misc
    },
});

export default store;
