import _ from "lodash";
import Api from "../api";

class Worklog extends Api {
    constructor() {
        super();
        this.uri = "users/worklog";
        this.requiresAuth = true;
    }

    getAverage(type, data) {
        return this.post(this.uri + '/average/' + type, data);
	}

    getWorklogLog(params) {
        return this.get(this.uri + "/paginate" + (params ? "?" + params : ""));
	}
	
    exportWorklog(params) {
        return this.get(this.uri + "/export" + (params ? "?" + params : ""));
    }
	
	indexTasks() {
        return this.get(this.uri + '/tasks');
    }	

}

export default new Worklog();