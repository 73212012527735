import Types from "../../../libs/api/cms/types";

export default {
    state: {
        types: [],
        type: {}
    },

    getters: {
        types: state => state.types,
        type: state => state.type,
    },

    mutations: {
        setTypes: (state, types) => {
            state.types = types;
        },
        
        setType: (state, type) => {
            state.type = type;
        }
    },

    actions: {
        resetTypes: async ({ commit }) => {
            commit("setTypes", []);
            commit("setType", {});
        },
        getTypes: async ({ commit }, {params = null, customRequest = null} = {}) => {
            const types = await Types.getTypes(params, customRequest);
            commit("setTypes", types);
            
            return types;
        },
        
        getType: async ({ commit }, id) => {
            const type = await Types.getType(id);
            commit("setType", type);
            return type;
        },

		updateType: async ({
            commit
        }, {
            params,
            id
        }) => {
            params = params || "";
            const response = await Types.updateType(params, id);
            return response
        },

        createType: async ({
            commit
        }, params) => {
            params = params || "";
            const response = await Types.createType(params);
            commit("setTypes", response);
            return response;
        },

        deleteType: async ({ commit }, id) => {
            await Types.deleteType(id);
        },

    }
};
