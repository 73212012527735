<template>
    <div class="modal-bg" v-if="open">
        <div class="modal" :class="type">
            <label>{{message}}</label>
            <div class="buttons">
                <fyi-button
                    :label="okButton"
                    :className="'default ok'"
                    :onclick="() => confirm()"
                />
                <fyi-button
                    :label="'Cancelar'"
                    :className="'default cancel'"
                    :onclick="() => cancel()"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Modal",
    data: () => ({
        // Parameters that change depending on the type of dialogue
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'Cancelar', // text for cancel button
        open: false,
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton
            }
            // Once we set our config, we tell the popup modal to open
            this.open = true
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        confirm() {
            this.open = false
            this.resolvePromise(true)
        },

        cancel() {
            this.open = false
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    },
}
</script>

<style scoped lang="scss">
@import "@/styles/components/layout/modals";
</style>