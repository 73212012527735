<template>
  <div class="modal-bg">
    <div class="modal add-item">
      <i @click="close" class="i-close" />
      <div class="title">Criar</div>
      <div class="options">
        <div
          class="opt"
          :class="{ selected: 'content' === selected }"
          @click="select('content')"
        >
          <i class="i-pages"></i><span>Conteúdo</span>
        </div>
        <div
          class="opt"
          :class="{ selected: 'list' === selected }"
          @click="select('list')"
        >
          <i class="i-menus"></i><span>Lista</span>
        </div>
        <div
          class="opt"
          :class="{ selected: 'faq' === selected }"
          @click="select('faq')"
        >
          <i class="i-fields"></i><span>FAQ</span>
        </div>
      </div>
      <div v-if="selected === 'content'">
        <SimpleSelect
          :label="'Tipo'"
          :allowSearch="true"
          @select-data-value="value = $event"
          :options="
            allTypes.map((t) => {
              return {
                label: t.languages.name ? t.languages.name : t.languages[0].name,
                value: t.id,
                checked: value == t.id,
              };
            })
          "
          :key="1"
        />
      </div>
      <div v-else-if="selected === 'faq' || selected === 'list'">
        <fyi-text
          :icon_box="false"
          :label="'Nome'"
          @input-text="value = $event"
        />
      </div>
      <fyi-button
        :label="'Criar'"
        :className="'default'"
        :disabled="value === null || value === ''"
        :loading="creating"
        :onclick="
          () => {
            create();
          }
        "
      />
    </div>
  </div>
  <teleport to="body">
    <Toast v-if="toast_show" :type="toast_type" :message="toast_message" />
  </teleport>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AddItemModal",
  data: () => ({
    creating: false,
    allTypes: null,
    selected: null,
    value: null,
    toast_type: "",
    toast_message: "",
    toast_show: false,
  }),
  props: {
    types: {
      type: Array,
      default: null,
    },
  },
  beforeMount() {
    this.allTypes = this.types;
  },
  methods: {
    ...mapActions(["createType", "createBlock", "getTypes"]),
    close() {
      this.$parent.addItemModal = false;
    },
    select(item) {
      this.value = null;
      this.selected = item;
    },
    async create() {
      this.creating = true;
      if (
        this.selected === "content" &&
        this.value !== null &&
        this.value !== ""
      ) {
        this.$router.push({
          name: "pages_cms_create",
          query: { id: this.value },
        });
      } else if (this.value !== null && this.value !== "") {
        if (this.selected === "faq") {
          await this.createFAQ();
        } else {
          await this.createList();
        }
        this.toast_type = "success";
        this.toast_message = "Criado com sucesso.";
        this.toast_show = true;

        this.allTypes = await this.getTypes({});

        this.selected = null;
        this.value = null;
        this.creating = false;
      }
    },
    async createFAQ() {
      // create type
      let type = {
        languages: [
          {
            name: this.value,
            language_id: 1,
          },
        ],
        addons: ["attachment"],
        page_template: 100,
      };
      const response = await this.createType(type);

      //create content block
      const data_2 = {
        name: this.value + " List",
        data: {
          contents_order: "date_desc",
          contents_limit: 0,
          types: [response.id],
          contents: [],
          url_dependence_limit: null,
          fields: [
            { field: "imageBg", options: "", orderfield: 1 },
            { field: "name", options: "", orderfield: 2 },
          ],
        },
        roles: [0],
        templates: [
          {
            template_id: 100,
            position: "content",
            orderposition: "2",
            plugin: "accordion",
          },
          {
            template_id: 200,
            position: "content",
            orderposition: "2",
            plugin: "accordion",
          },
        ],
        pages: [{ page: "type_" + response.id, language_id: 1, show: true }],
        type: "content",
        published: 1,
      };
      await this.createBlock({ data: data_2 });
    },
    async createList() {
      // create type
      let type = {
        languages: [
          {
            name: this.value,
            language_id: 1,
          },
        ],
        addons: ["attachment"],
        page_template: 100,
        contents_template: 200,
      };
      const response = await this.createType(type);

      //create text block
      const data_1 = {
        name: this.value + " List Title",
        data: [{ language_id: 1, text: this.value, type: "html" }],
        roles: [0],
        templates: [
          {
            template_id: 100,
            position: "content",
            orderposition: "1",
            plugin: "html",
          },
          {
            template_id: 200,
            position: "content",
            orderposition: "2",
            plugin: "html",
          },
        ],
        pages: [
          {
            page: "type_" + response.id,
            language_id: 1,
            show: true,
          },
        ],
        type: "text",
        published: 1,
      };

      //create content block
      await this.createBlock({ data: data_1 });
      const data_2 = {
        name: this.value + " List",
        data: {
          contents_order: "date_desc",
          contents_limit: 0,
          types: [response.id],
          contents: [],
          url_dependence_limit: null,
          fields: [
            { field: "imageBg", options: "", orderfield: 1 },
            { field: "name", options: "", orderfield: 2 },
          ],
        },
        roles: [0],
        templates: [
          {
            template_id: 100,
            position: "content",
            orderposition: "2",
            plugin: "grid",
          },
          {
            template_id: 200,
            position: "content",
            orderposition: "3",
            plugin: "grid",
          },
        ],
        pages: [{ page: "type_" + response.id, language_id: 1, show: true }],
        type: "content",
        published: 1,
        create_default_content: true
      };
      await this.createBlock({ data: data_2 });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/components/layout/modals";
</style>
