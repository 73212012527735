export default async function init ({ store }) {

    if (store.getters.init.clientToken !== null && store.getters.clientInfo.info !== null) {
        return true
    }else{
        try{

            await store.dispatch('init')
            return true

        }catch(err) {
            console.log(err)
            return {name:"erro"};
        }
    }
}