<template>
  <template v-for="(addon, k) in addons" :key="k">
    <AddonAttachments
      v-if="addon == 'attachment'"
      :content="content"
      @add-files-attachments="$emit('add-files-attachments', $event)"
      @remove-files-attachments="$emit('remove-files-attachments', $event)"
      :language_index="language_index"
      :language="language"
    />
    <AddonFields
      v-if="addon == 'field'"
      :content="content"
      :language_index="language_index"
      :language="language"
      :updatedValues="fields"
      @update-fields="updateFields"
    />
    <AddonEvents
      v-if="addon == 'event'"
      :content="content"
      @update-events="events = $event"
    />
    <AddonRelated
      v-if="addon == 'related'"
      :content="content"
      @update-related="events = $event"
    />
    <AddonCategories
      v-if="addon == 'categorie'"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-categories="categories = $event"
    />
    <AddonCharts
      v-if="addon == 'chart'"
      ref="chart"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-charts="charts = $event"
    />
    <AddonMaps
      v-if="addon == 'map'"
      ref="map"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-maps="maps = $event"
    />
    <AddonLivestream
      v-if="addon == 'livestream'"
      ref="livestream"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-livestream="livestream = $event"
    />
    <AddonPlays
      v-if="addon == 'play'"
      ref="plays"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-plays="plays = $event"
    />
    <AddonCatalogo
      v-if="addon == 'catalogo'"
      ref="catalogo"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-catalogos="catalogos = $event"
    />
    <AddonModules
      v-if="addon == 'modulo'"
      ref="modulos"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-module="modulos = $event"
    />
    <AddonCollections
      v-if="addon == 'colecoe'"
      ref="collection"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-collection="colecoes = $event"
    />
    <AddonNutrition
      v-if="addon == 'nutrition'"
      ref="nutrition"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-nutrition="nutrition = $event"
    />
    <AddonVision
      v-if="addon == 'vision'"
      ref="vision"
      :content="content"
      :language_index="language_index"
      :language="language"
      @add-files-vision="$emit('add-files-vision', $event)"
      @remove-files-vision="$emit('remove-files-vision', $event)"
    />
    <AddonPlays
      v-if="addon == 'play'"
      ref="plays"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-plays="plays = $event"
    />
    <AddonImageCompare
      v-if="addon === 'imagecompare'"
      ref="ic"
      :content="content"
      :language_index="language_index"
      :language="language"
      @update-ic="ic = $event"
    />
  </template>
</template>

<script>
export default {
  name: "ContentAddons",
  props: {
    addons: {
      type: Array,
      default: [],
    },
    content: {
      type: Object,
      default: null,
    },
    language_index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      fields: [],
      events: [],
      categories: [],
      maps: [],
      livestream: [],
      plays: [],
      modulos: [],
      colecoes: [],
      catalogos: [],
      nutrition: [],
      vision: [],
      ic: [],
    };
  },
  methods: {
    charts() {
      return this.$refs.chart[0].output();
    },
    imageCompare() {
      return this.$refs.ic ? this.$refs.ic[0].getInfo() : [];
    },
    updateFields(fields) {
      this.fields = fields;
    },
  },
};
</script>

<style></style>
