import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import VueGtag  from 'vue-gtag';
import Vue3TouchEvents from "vue3-touch-events";
import VueGoogleMaps from '@fawmi/vue-google-maps'

import axios from "axios";

window.axios = axios;

import { createApp, h } from 'vue'
import App from "./App.vue";

import router from "./router";
import store from "./store";
import design from "./design";
import FlagIcon from 'vue-flag-icon'

import i18n  from "./plugins/i18n.js";
import $bus  from "./plugins/event.js";

// mixin global
import global from "./mixins/global";

import './registerServiceWorker'

const app = createApp({
        render: () => h(App)
});
app.config.warnHandler = (msg, instance, trace) => {
  // `trace` is the component hierarchy trace
}

app.mixin(global)
app.use(design);
app.use(FlagIcon);
app.use(ElementPlus);
app.use(store)
app.use(i18n)
app.use(router)
app.use(Vue3TouchEvents)
app.use($bus)
app.use(VueGtag, {
  config: { 
    id: "G-LVNEJK32CS",
  },
}, router);

app.use(VueGoogleMaps, {
  load: {
      key: "AIzaSyDK0Df_rZ23WknAWG04rIc8sEsao4F4A0g",
      libraries: "places"
  },
})

app.mount("#app");
