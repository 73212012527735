<template>
  <div class="header" :class="[{ searching: search }]">
    <SuperSearch @searching="search = $event" />
    <keep-alive>
      <img
        v-if="$store.getters.auth.user.info.photo != null"
        @click="$router.push({ name: 'profile' })"
        class="avatar"
        :src="$store.getters.auth.user.info.photo"
      />
      <div
        @click="$router.push({ name: 'profile' })"
        v-else
        :style="'background: #' + color"
        class="avatar"
      >
        <span>{{ pseudo_name }}</span>
      </div>
    </keep-alive>
    <i class="i-notifications" @click="notifications">
      <div class="noti-square" v-if="$store.getters.unreadNotifications.length > 0">
        {{ $store.getters.unreadNotifications.length }}
      </div></i
    >
    <i class="i-settings" v-if="rolesFeature && hasPermission(['roles.write'])" @click="showSettings = true"></i>
    <i class="i-logout" @click="out"></i>
    <teleport to="body">
      <Modal ref="modal" />
    </teleport>
    <teleport to="body">
      <NotificationsModal :open="showNoti" />
    </teleport>
  </div>
  <teleport to="body">
        <AppSettings v-if="showSettings" :app="1"/>
    </teleport>
</template>

<script>
import { toHandlers } from "vue";
import { mapActions } from "vuex";

export default {
  name: "Header",
  data: function () {
    return {
      search: false,
      showNoti: false,
      showSettings: false,
      rolesFeature: true
    };
  },
  computed: {
    pseudo_name() {
      if (this.$store.getters.auth.user.name == undefined) return "";

      let splt = this.$store.getters.auth.user.name.split(" ");
      return (
        splt[0][0].toUpperCase() +
        (splt[splt.length-1] != undefined ? splt[splt.length-1][0].toUpperCase() : "")
      );
    },
    color() {
      if (
        window.sessionStorage.getItem("headerColor") == undefined ||
        window.sessionStorage.getItem("headerColor") == null
      )
        window.sessionStorage.setItem(
          "headerColor",
          ((Math.random() * 0xffffff) << 0).toString(16)
        );

      return window.sessionStorage.getItem("headerColor");
    },
  },
  async beforeMount(){
    if(!await this.featureEnabled('roles'))
      this.rolesFeature = false
  },
  methods: {
    ...mapActions(["logout","featureEnabled"]),
    async out() {
      const ok = await this.$refs.modal.show({
        message: "Deseja terminar a sessão?",
        okButton: "Terminar Sessão",
      });
      // If you throw an error, the method will terminate here unless you surround it wil try/catch
      if (ok) {
        this.logout().then(() => {
          this.$router.push({ name: "login" });
        });
      }
    },
    notifications() {
      this.showNoti = !this.showNoti;
    },
    hasPermission(menu_permission) {
        let permissions = this.$store.getters.auth.permissions;

        if(menu_permission == null || menu_permission == undefined){
            return true;
        }else if(permissions.includes("all")){
            return true
        }else{
            let flag = 0;
            permissions.forEach(p => {
                if(menu_permission.some(item => item == p.split(".")[0])){
                    flag++;
                }
            });

            return flag == menu_permission.length
        }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/navigation/header.scss";
</style>
