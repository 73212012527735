import Contents from "../../../libs/api/cms/contents";
import $bus from '../../../plugins/event'

export default {
    state: {
        contents: [],
        drafts: [],
        content: {}
    },

    getters: {
        content: state => state.content,
        contents: state => state.contents,
        drafts: state => state.drafts,
        contentsCount: state => state.contentsCount,
        currentContentsPageCount: state => state.currentContentsPageCount,
        contentsByType: state => (type, page, offset) => {
            return [];
        }
    },

    mutations: {
        setContents: (state, contents) => {
            state.contents = contents;
        },
        setContent: (state, content) => {
            state.content = content;
        },
        setDrafts: (state, drafts) => {
            state.drafts = drafts;
        },
        setContentsCount: (state, count) => {
            state.contentsCount = count;
        },
        setCurrentPageCount: (state, count) => {
            state.currentContentsPageCount = count;
        },
        setContentPage: (state, page) => {
            state.contentPage = page;
        }
    },

    actions: {
        resetContents: async ({
            commit
        }) => {
            commit("setContent", {});
            commit("setContents", []);
        },

        getContents: async ({
            commit
        }, params) => {
            const response = await Contents.getContents(params);
            commit("setContents", response);

            return response;
        },

        getRevisions: async ({
            commit
        }, {params, id}) => {
            const response = await Contents.getRevisions(params, id);

            return response;
        },


		getContentsStateless: async ({
            commit
        }, params) => {
            const response = await Contents.getContents(params);
            return response;
        },

        getContent: async ({
            commit
        }, {
            params,
            id
        }) => {
            const content = await Contents.getContent(params, id);
            commit("setContent", content);

            return content;
        },

        getDrafts: async ({
            commit
        }, {
            params,
            id
        }) => {
            params = params || "";

            const drafts = await Contents.getDrafts(params, id);
            commit("setDrafts", drafts);

            return drafts;
        },


        getContentsCount: async ({
            commit
        }, params) => {
            params = params || "";
            const response = await Contents.getContentsCount();
            commit("setContentsCount", response.count);
        },

        searchContents: async ({
            commit
        }, {
            params,
            data
        }) => {
            params = params || "";
            const response = await Contents.searchContents(params, data);
            commit("setContents", response);

            return response;
        },

        updateContent: async ({
            commit
        }, {
            params,
            id
        }) => {
            params = params || "";
            const response = await Contents.updateContent(params, id);
            return response
        },

        createContent: async ({
            commit
        }, params) => {
            params = params || "";
            const response = await Contents.createContent(params);
            commit("setContents", response);
            return response;
        },
        batchContents: async ({
            commit
        }, params) => {
            const response = await Contents.batchContents(params.data, params.action);
        },
        deleteBatchContents: async ({
            commit
        }, data) => {
            const response = await Contents.deleteBatch(data);
        },

        togglePublished: async ({
            commit
        }, {
            published,
            id
        }) => {
            const response = await Contents.togglePublished(published, id);
        },

        addonsAction: async ({
            commit
        }, {
            addon,
            action,
            content,
            value = null,
            field = null
        }) => {
            const response = await Contents.addonsAction(addon, action, content, value, field);
        },
        deleteDraft: async ({
            commit
        }, id) => {
            await Contents.deleteDraft(id);
        },

        verifyBreakLink: async ({
            commit
        }, id) => {
            let response = await Contents.verifyBreakLink(id);
            return response;
        },

        exportContents: async ({}, data) => {
            const response = await Contents.export(data);
            return response
        },

        importContents: async ({}, data) => {
            try{
                const response = await Contents.import(data);
                return response
            }catch(err){
                $bus.$trigger('modal', 'csv')
            }
        },

		cacheClear: async ({}, url) => {
            const response = await Contents.cacheClear(url);
            return response
        },
    }
};