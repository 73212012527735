<template>
    <label class="form-title">{{!$route.name.includes('profile') ? $t('users') : "Perfil"}}</label>
    <div id="content-form" class="content-form" v-if="user != null || $route.params.id == null">
        <div class="form">
            <!--Base content!-->
            <label>Dados Pessoais</label>
            <fyi-text
                v-bind:value="user ? user.name : ''"
                @input-text="user.name = $event"
                :label="'Nome'"
                ref="name"
                :required="true"
                :inputError="true"
                :icon_box="false" :full="true"/>
            <fyi-email
                v-bind:value="user ? user.email : ''"
                @input-email="user.email = $event"
                ref="email"
                :label="'Email'"
                :icon_box="false" :full="true"
                :inputError="true"/>
            <fyi-phone
                v-bind:value="user && user.phone ? user.phone : ''"
                :label="'Telemóvel'"
                @input-phone="user.phone = $event"
            />
            <fyi-password
                v-bind:value="new_password"
                @input-password="new_password = $event"
                :alias="'password'"
                :label="'Nova palavra-passe'"
                :show="true"
                :icon_box="false"
                :placeholder="'● ● ● ● ● ●'"
                :errorType="input_1_error_type"
                :disabled="submitStatus == 'TOKEN_NOT_FOUND'"      
                :required="true"
                :inputError="true"
            />
            <fyi-password
                v-bind:value="repeat_password"
                @input-password="repeat_password = $event"
                :alias="'password'"
                :label="'Repetir palavra-passe'"
                :show="true"
                :icon_box="false"
                :placeholder="'● ● ● ● ● ●'"
                :errorMessage="input_2_error_message"
                :errorType="input_2_error_type"
                :disabled="submitStatus == 'TOKEN_NOT_FOUND'"      
                :required="true"
                :inputError="true"
            />
            <label v-if="!$route.name.includes('profile')">Permissões e acessos</label>
            <Switch v-if="!$route.name.includes('profile')" :enabled="this.userApps.includes(1)" :showLabel="true" :label="'Acesso ao Backoffice'" @input="toggleBOAccess($event)"/>
            <ComplexSelect v-if="!$route.name.includes('profile')" ref="apps" :className="'apps'" :optionsLabel="''" :previewLabel="'Aplicações'" :options="filteredApps" 
                :noButtons="true" @clickLine="selectApp" :singleSelection="false"/>
            <ComplexSelect v-if="!$route.name.includes('profile')" ref="perms" :className="'perms'" :optionsLabel="''" :previewLabel="'Permissões'" :options="filteredRoles" 
                :noButtons="true" @clickLine="selectRole" :singleSelection="false"/>
            <ComplexSelect v-if="!$route.name.includes('profile') && groups != null" ref="groups" :className="'groups'" :optionsLabel="''" :previewLabel="'Grupos'" :options="filteredGroups" 
                :noButtons="true" @clickLine="selectGroup" :singleSelection="false"/>
        </div>
        <div class="options-sidebar">
            <label class="title">{{this.$route.params.id ? 'Editar' : 'Criar'}}</label>
            <div class="buttons">
                <span v-if="$route.name.includes('edit')" :onclick="removeUser">Eliminar</span>
                <fyi-button
                    :label="($route.name.includes('edit') ? 'Atualizar' : 'Finalizar')"
                    :className="'default ' + ($route.name.includes('edit') ? 'update' : 'create')"
                    :loading="submitStatus=='PENDING'"
                    :onclick="save"
                />
            </div>
            <div class="section access active" ref="access" >
                <div class="bar" @click="openBar('access')">
                    <i class="i-collapse"/>
                    <label>Fotografia de perfil</label>
                </div>
                <div class="options">
                    <div class="profile-avatar">
                        <img v-if="user && user.info.photo != null" ref="pic" :src="user.info.photo"/>
                        <div v-else :style="'background: #'+ color(user)" class="avatar">
                            <span>{{ pseudo_name(user ? user.name : '') }}</span>
                        </div>
                        <fyi-button
                            :label="'Adicionar foto'"
                            :className="'default change-avatar'"
                            :onclick="changeAvatar"
                        />
                        <input style="display:none" ref="profile_pic" type="file" accept="image/png, image/jpeg">
                    </div>
                </div>
            </div>
        </div>
        <div onclick="this.previousElementSibling.classList.toggle('open')" class="sidebar-mobile-tab">
            <i class="i-collapse"></i>
        </div>
        <teleport to="body">
            <div class="bg" v-show="cropping">
                <div class="popup popup-crop active">
                    <div class="popup-inner">
                        <label>Editar</label>
                        <i class="i-close" />
                        <div ref="crop_container" class="crop-container"></div>
                        <div class="button_bar">
                            <fyi-button
                                :label="'Guardar'"
                                :className="'default change-avatar'"
                                :onclick="setProfilePic"
                            />
                            <fyi-button
                                :label="'Cancelar'"
                                :className="'default cancel'"
                                :onclick="() => cropping = null"
                            />
                        </div>
                    </div>
                </div>
        </div>
        </teleport>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
        <teleport to="body">
            <transition name="fade">
                <LoadingPage v-if="transition" />
            </transition>
        </teleport>
    </div>
</template>


<script>
import { mapActions, mapMutations } from "vuex";
import Croppie from 'croppie'

export default {
    name:"Users",
    data: function(){
        return {
            c: null,
            cropping:false,
            user: null,
            roles:[],
            apps:[],
            userRoles: [],
            userApps: [],
            userGroups: [],
            loading: true,
            transition: true,
            new_password: "",
			repeat_password: "",
			input_1_error_type: "",
			input_2_error_type: "",
			input_2_error_message: "As passwords não coincidem",
            submitStatus: "READY",
            og_email:''
        }
    },
    watch: {
        user: {
            handler(val){
                if(this.$refs.email && !this.$refs.email.v$.value.$error){
                    this.getUsers('search='+val.email).then( r => {
                        if(r.total > 0 && !this.$route.name.includes('profile') && this.og_email != val.email)
                            this.$refs.email.showError = "Email já em uso";
                        else
                            this.$refs.email.showError = "";
                    })
                }else if(this.$refs.email)
                    this.$refs.email.showError = "";
            },
            deep: true
        },
        repeat_password(_new, _old){
			if(_new == this.new_password){
				this.input_2_error_type = 'valid'
				this.input_2_error_message = this.$t('error_4')
			}else{
				this.input_2_error_type = 'warning'
				this.input_2_error_message = this.$t('error_5')
			}
		},
		new_password(_new, _old){
			let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[\\\-_!@#\$%\^&\*\?])(?=.{8,})"); //add (?=.*[0-9]) if numeric char required
            if(strongRegex.test(_new)){
				this.input_1_error_type = 'valid'
			}else{
				this.input_1_error_type = 'warning'
			}

			if(_new == this.repeat_password){
				this.input_2_error_type = 'valid'
				this.input_2_error_message = this.$t('error_4')
			}else{
				this.input_2_error_type = 'warning'
				this.input_2_error_message = this.$t('error_5')
			}
		},
    },
    async beforeMount(){
        if(this.$route.name.includes('profile')){
            this.user = await this.getUser(this.$store.getters.auth.user.id)
            this.og_email = this.user.email
            this.userRoles = this.user.roles.map( r => {return r.id})
            this.userApps = this.user.apps.map( a => {return a.id})
            this.userGroups = this.user.groups.map( g => { return g.id})
        }else if(this.$route.params.id){
            this.user = await this.getUser(this.$route.params.id)
            this.og_email = this.user.email
            this.userRoles = this.user.roles.map( r => {return r.id})
            this.userApps = this.user.apps.map( a => {return a.id})
            this.userGroups = this.user.groups.map( g => { return g.id})
        }else
            this.user = {
                name: '',
                email: '',
                phone: '',
                apps: [],
                roles: [],
                fields: [],
                groups: [],
                info: {
                    photo: null
                },
                state: 2,
            }

        this.groups = await this.getGroups()
        this.roles = await this.getRoles()
        this.apps = await this.getApps()

        this.loading = false;
        this.transition = false;
    },
    computed: {
        filteredRoles() {
            let array = this.roles.map( r => {return {label: r.name, value:r.id, checked: this.userRoles.includes(r.id)}})

            
            return array;
        },
        filteredApps() {
            return this.apps.map( r => {return {label: r.name, value:r.id, checked: this.userApps.includes(r.id)}}).filter(i => {
                return i.value != 1
            });
        },
        filteredGroups() {
            return this.groups.map( g => {return {label: g.name, value:g.id, checked: this.userGroups.includes(g.id)}})
        }
    },
    methods: {
        ...mapActions(["getUsers","getUser", "getRoles", "getApps", "getGroups", "editUser", "createUser", "deleteUser"]),
        ...mapMutations(["changeUser"]),
        pseudo_name(name){
            if(name == undefined)
                return ''
                
            let splt = name.split(" ");
            if(name.length > 0)
                return splt[0][0].toUpperCase() + (splt[splt.length-1] != undefined && splt[splt.length-1] != '' ? splt[splt.length-1][0].toUpperCase() : '')

            return ''
        },
        color(item){
            if(item == null)
                return ''

            if(!item.color)
                item.color = (Math.random()*0xFFFFFF<<0).toString(16)
                
            return item.color
        },
        openBar(element){
            document.querySelectorAll(".section.active").forEach( el => {if(!el.classList.contains(element)) el.classList.remove("active")})
            this.$refs[element].classList.toggle('active')
        },
        save(){
            this.submitStatus = "PENDING"

            let user = {};
            user.roles = []

            this.userRoles.forEach( r => {
                user.roles.push(r)
            })

            user.apps = []
            this.userApps.forEach( a => {
                user.apps.push(a)
            })

            user.groups = []
            this.userGroups.forEach( g => {
                user.groups.push(g)
            })

            if(this.user.info.photo != null && this.user.info.photo.includes('base64'))
                user.photo64 = this.user.info.photo
            
            user.name = this.user.name
            user.email = this.user.email
            user.phone = this.user.phone 

            if(user.name.trim() == ''){
                this.submitStatus = "ERROR"
                this.$refs.name.$el.classList.add("error")
                return
            }

            if(user.email.trim() == '' || this.$refs.email.showError){
                this.submitStatus = "ERROR"
                this.$refs.email.$el.classList.add("error")
                return
            }

            if(this.new_password != '' && this.input_2_error_type == 'valid')
                user.password = this.new_password
            
            user.state = this.user.state

            if(this.$route.name.includes('profile')){
                this.editUser({id: parseInt(this.$store.getters.auth.user.id), data: user }).then((r) => {
                    const {
                        name,
                        phone,
                        info,
                        roles,
                        apps,
                        groups
                    } = r.user;

                    this.changeUser({
                        name,
                        phone,
                        info,
                        roles,
                        apps,
                        groups
                    });
                    this.$router.push({ name: "users" });
                })
            }else if(this.$route.params.id){
                this.editUser({id: parseInt(this.$route.params.id), data: user }).then(() => {
                    this.$router.push({ name: "users" });
                })
            }else{
                this.createUser(user).then(() => {
                    this.$router.push({ name: "users" });
                })
            }
        },
        async removeUser(){
            const ok = await this.$refs.modal.show({
                message: "Deseja eliminar o utilizador?",
                okButton: 'Eliminar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.deleteUser(this.$route.params.id).then(() => {
                    this.$router.push({ name: "users" });
                })
            }
        },
        changeAvatar(){
            if(this.$refs.crop_container && this.c == null){
                this.c = new Croppie(this.$refs.crop_container, {
                            viewport: {
                                width: 200,
                                height: 200,
                                type: 'square'
                            },
                            boundary: {
                                width:508,
                                height: 300
                            }});

                this.$refs.profile_pic.addEventListener("change", (event) => {
                let FR = new FileReader();
                FR.readAsDataURL( this.$refs.profile_pic.files[0] );
                FR.addEventListener("load", (e) =>  {
                    this.cropping = true
                    this.c.bind({
                        url:e.target.result,
                    } )
                    this.$refs.profile_pic.value = null
                }); 
            })
            }

            this.$refs.profile_pic.click()
        },
        closeCrop(){
            this.cropping = false
        },
        setProfilePic(){
            this.c.result('base64', 'viewport').then( b64 => {
                this.user.info.photo = b64;
                this.cropping = false;
            })
        },
        selectApp(id){
            if(this.userApps.includes(id)){
                this.userApps.splice(this.userApps.indexOf(id), 1)
            }else{
                this.userApps.push(id)
            }
        },
        selectRole(id){
            if(this.userRoles.includes(id)){
                this.userRoles.splice(this.userRoles.indexOf(id), 1)
            }else{
                this.userRoles.push(id)
            }
        },
        selectGroup(id){
            if(this.userGroups.includes(id)){
                this.userGroups.splice(this.userGroups.indexOf(id), 1)
            }else{
                this.userGroups.push(id)
            }
        },
        toggleBOAccess(ev){
            if(ev){
                this.userApps.push(1)
            }else{
                this.userApps.splice(this.userApps.indexOf(1), 1)
            }
        }
    },
    mounted(){
        setTimeout( () => {
            if(this.$refs.crop_container){
                this.c = new Croppie(this.$refs.crop_container, {
                            viewport: {
                                width: 200,
                                height: 200,
                                type: 'square'
                            },
                            boundary: {
                                width:508,
                                height: 300
                            }});

                this.$refs.profile_pic.addEventListener("change", (event) => {
                    let FR = new FileReader();
                    FR.readAsDataURL( this.$refs.profile_pic.files[0] );
                    FR.addEventListener("load", (e) =>  {
                        this.cropping = true
                        this.c.bind({
                            url:e.target.result,
                        } )
                        this.$refs.profile_pic.value = null
                    }); 
                })
            }
        }, 500)
    }
}
</script>


<style lang="scss" scoped>
    @import "../../styles/pages/users.scss";   
    @import "../../../node_modules/croppie/croppie.css"
</style>