<template>
    <div class="addon livestream" :class="[{ 'open': open }]">
        <div class="collapsible" @click="open = !open">
            <label>Livestream</label>
            <i class="i-collapse" />
        </div>
        <div class="livestream-form">
            <fyi-textarea
                v-bind:value="addon[language_index].embed_stream"
                v-on:input="addon[language_index].embed_stream = $event;$emit('update-livestream', addon)"
                :label="'Código Embed da Livestream'"
                :required="true" :toolbar="'none'" :key="language_index"/>
                <fyi-textarea
                v-bind:value="addon[language_index].embed_chat"
                v-on:input="addon[language_index].embed_chat = $event;$emit('update-livestream', addon)"
                :label="'Código Embed do Chat (Opcional)'"
                :required="true" :toolbar="'none'" :key="language_index"/>
            <SimpleSelect
                :label="'Disponibilidade de Conteúdo'"
                @select-data-value="addon[language_index].availability = $event;$emit('update-livestream', addon)" :previewIcon="'i-order'"
                :options="availability.map( p => { return {label: p.label, value: p.value, checked: addon[language_index].availability == p.value}})" :key="addon[language_index].availability"/>
                <fyi-number
                    :label="'Preço'"
                    v-bind:value="addon[language_index].price"
                    v-on:input-number="addon[language_index].price = $event;$emit('update-livestream', addon)"
                    :alias="''"
                    :classe="''"
                    :show="true"
                    :inputError="false"
                />
        </div>
    </div>
</template>

<script>
export default {
    name: "AddonLivestream",
    props: {
        content: {
            type: Object,
            default: null
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            open: false,
            availability:[
            {
                label: 'Grátis',
                value: 'gratis',
                checked: false
            },
            {
                label: 'Payperview',
                value: 'payperview',
                checked: false
            },
            {   
                label: 'Subscrição',
                value: 'subscription',
                checked: false
            },
            ]
        }
    },
    beforeMount(){
        this.addon = this.content.livestreams

            this.$store.getters.languages.forEach( l => {
                if(!this.addon.map( a => { return a.language_id}).includes(l.id))
                    this.addon.push({
                        language_id: l.id,
                        embed_chat: '',
                        embed_stream: '',
                        price: '',
                        availability: ''
                    })

            });
    }
}
</script>
    
<style lang="scss" scoped>@import "../../../../styles/components/layout/addons.scss";</style>