import _ from "lodash";
import Api from "./../api";

class Calendar extends Api {
    constructor() {
        super();
        this.uri = "my-calendar";
        this.requiresAuth = true;
    }

    getCalendars(params) {
        return this.get(this.uri + (params ? "?" + params : ""));
	}
}

export default new Calendar();