import Misc from "../../libs/api/misc/misc";

export default {
    state: {
    },

    getters: {
    },

    mutations: {
    },

    actions: {
        getPatchNotes: async ({
            commit
        }, id) => {
            const response = await Misc.getPatchNotes();
            return response
        },
        getLogs: async ({
            commit
        }, params) => {
            const response = await Misc.getLogs(params);
            return response
        },
        getUnits: async ({
            commit
        }) => {
            const response = await Misc.getUnits();
            return response
        },

        getCmsFeatureOrder: async ({
            commit
        }, {user_id = null, app_id = null}) => {
            const response = await Misc.getCmsFeatureOrder(user_id, app_id);
            return response
        },

        saveCmsFeatureOrder: async ({
            commit
        }, {user_id, app_id, data}) => {
            const response = await Misc.saveCmsFeatureOrder(data, user_id = null, app_id = null);
            return response
        },

        deleteCmsFeatureOrder: async ({
            commit
        }, {user_id, app_id}) => {
            const response = await Misc.deleteCmsFeatureOrder(user_id = null, app_id = null);
            return response
        }
    }
};