import Roles from "../../libs/api/roles";

export default {
    state: {
        roles: null,
    },

    getters: {
        roles: state => state.roles,
    },

    mutations: {
        setRoles: (state, roles) => {
            state.roles = roles;
        },
    },

    actions: {
        getRoles: async ({
            commit
        }, params) => {
            const response = await Roles.getRoles(params);
            commit("setRoles", response);
            return response
        },


        
        getPermissions: async ({}) => {
            const response = await Roles.getPermissions();
            return response
        },
        
        getRole: async ({}, id) => {
            const response = await Roles.getRole(id);
            return response
        },

        deleteRole: async ({}, id) => {
            await Roles.remove(id);
        },

        createRole: async ({}, data) => {
            await Roles.create(data);
        },

        editRole: async ({}, {
            id,
            data
        }) => {
            await Roles.edit(id, data);
        },
    }
};