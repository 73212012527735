import Metadatas from "../../../libs/api/cms/metadatas";

export default {
    state: {
        metadatas: [],
        metadata: {}
    },

    getters: {
        metadata: state => state.metadata,
        metadatas: state => state.metadatas,
    },
       
    mutations: {
        setMetadatas: (state, metadatas) => {
            state.metadatas = metadatas;
        },
        setMetadata: (state, metadata) => {
            state.metadata = metadata;
            state.metadatas.push(metadata);
        }
    },

    actions: {
        getMetadata: async ({ commit }, id) => {
            const metadata = await Metadatas.getMetadata(id);
            commit("setMetadata", metadata);
            return metadata;
        },
        getMetadatas: async ({ commit }, params) => {
            params = params || "";
            const { data, total, nextPage } = await Metadatas.getMetadatas(params);
            commit("setMetadatas", data);
            return { data, total, nextPage };
        },
        createMetadata: async ({ commit }, params) => {
            await Metadatas.create(params);
        },
        deleteMetadata: async ({ commit }, id) => {
            await Metadatas.deleteMetadata(id);
        },
        editMetadata: async (_, {id, params}) => {
            await Metadatas.edit(id, params);
        }
    }
}
