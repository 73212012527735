import Worklog from "../../../libs/api/worklog/worklog";

export default {
    state: {
        refreshWorklogDashboard: null
    },

    getters: {
        refreshWorklogDashboard: state => state.refreshWorklogDashboard,

    },

    mutations: {
        setRefreshWorklogDashboard: (state, date) => {
            state.refreshWorklogDashboard = date;
        },
    },

    actions: {
        getWorklogAverage: async (_, params) => {
            let data = {...params.data};
            const response = await Worklog.getAverage(params.type, data);
            
            return response
		},
		
        getWorklogLog: async ({
            commit
        }, params) => {
            const response = await Worklog.getWorklogLog(params);
            return response
		},
		
        exportWorklog: async ({
            commit
        }, params) => {
            const response = await Worklog.exportWorklog(params);
            return response
		},
		
		indexTasks: async (_) => {
			return  await Worklog.indexTasks();
		}
    }
};