<template>
<div class="fyi-input-url">
    <label v-if="label && showLabel">{{ label }}</label>
    <div class="url">
        <div class="link-icon"><i class="fas fa-link"></i></div>
        <input class="input-text" type="text" :placeholder="placeholder" :required="required" :disabled="disabled" v-on:blur="$emit('blur')" v-model="inputValue" 
            v-on:keydown="handleDown"
            v-on:input="$emit('input', $event.target.value)"
            v-on:keydown.enter="handleEnter"
            v-on:keyup="handleUp" />
        <div @click.stop="reset" class="reset-button"><i class="fas fa-times"></i></div>
    </div>
</div>
</template>

<script>
import urlMetadata from "url-metadata";

export default {
    name: "fyi-input-url-simple",
    props: {
        value: [String, Number, Object],
        alias: String,
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showLabel: {
            type: Boolean,
            default: false
        },
        showMetadatas: {
            type: Boolean,
            default: true
        },
        required: {
            type: Boolean,
            default: false
        },
        regex: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            inputValue: "",
            typingInterval: 1000,
            loading: false,
            loadingMetadatas: false,
            validMetadatas: false,
            validUrl: false,
            metadatas: {},

        };
    },
    methods: {
        reset() {
            this.validMetadatas = false;
            this.validUrl = false;
            this.loadingMetadatas = false;
            this.inputValue = "";
            this.$emit('input', this.inputValue);
        },

        handleUp(el) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(this.doneTyping, this.typingInterval);
        },
        handleDown(el) {
            clearTimeout(this.typingTimer);
        },
        doneTyping(el) {
            this.handleInput();
        },

        handleInput() {
            this.$emit('input', this.inputValue);
        },
        handleEnter(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        validate() {
            if(!this.regex) return true;
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            return this.inputValue.match(regex);
        },
          async fetchMetadatas () {
              let self = this;
                await urlMetadata("https://cors-anywhere.herokuapp.com/"+this.inputValue).then(
                function(metadata) {
                    if(metadata.title === "" || metadata.title === null) {
                        metadata.title = self.inputValue;
                    }
                    
                    if(metadata.description === "") {
                        metadata.description = self.$t("pages.cms.block_images_url_metadatas_no_description");
                    }
                    self.metadatas = metadata;
                    self.validMetadatas = true;
                },
                function(error) {
                    self.validMetadatas = false;
                }
            )
        }
    },
    async mounted() {

        if(typeof this.value === "object" && this.value.url) {
            this.inputValue = this.value.url;
        } else {
            this.inputValue = this.value;
        }
        if(this.inputValue !== "") {
            this.handleInput();
        }
   },
};
</script>

<style lang="scss" scoped>
//@import "@/styles/components/form/input";

.fyi-input-url {
    
    .url {
        display: block;
        position: relative;
        z-index: 1;

        > .link-icon {
            position: absolute;
            left: 14px;
            top: 4px;
            font-size: 24px;
            color: rgba(62, 76, 211, 1);
        }

        > .reset-button {
            position: absolute;
            right: 14px;
            top: 4px;
            font-size: 24px;
            color: rgba(145, 158, 171, 1);
            cursor: pointer;
        }
        > input {
            width: 100%;
            border-radius: 4px;
            padding: 12px 48px 12px 48px !important;
            font-size: 14px;
            line-height: 16px;
            border: 1px solid rgba(0, 0, 0, 0.25);
            -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
            color: rgba(62, 76, 211, 1);
        }
    }

    .metadatas-container {
        display: block;
        border: 1px solid rgba(211,211,211,1);
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 2px;
        border-bottom-left-radius: 2px;
        background-color: rgba(249,249,252,1);
        box-shadow: 0 2px 4px 0 rgba(211,211,211,0.5);
        z-index: -1;
        padding: 20px;

        .fyi-loading {
            margin: 0 auto;
        }

        .metadatas {
            text-align: left;
            .title {
                color: rgba(0,0,0,1);
                font-family: Roboto;
                font-size: 16px;
                letter-spacing: 0;
                line-height: 19px;
            }
            .text {
                margin-top: 8px;
                color: rgba(145,158,171,1);
                font-family: Roboto;
                font-size: 14px;
                font-style: italic;
                letter-spacing: 0;
                max-width: 600px;
                line-height: 20px;
            }
        }
    }
}
</style>
