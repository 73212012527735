<template>
    <div class="super search" :class="[{'open': submitStatus == 'LOADING' || submitStatus == 'DONE'}]">
        <i class="i-search"></i>
        <input
            v-on:keyup="handleUp"
            v-model="query"
            type="text"
            ref="input"
            :placeholder="placeholder"
            :readonly="disabled"
        >
        <i v-if="submitStatus == 'LOADING' || submitStatus == 'DONE'" @click="clearSearch" class="i-close">
        </i>
        <div v-if="submitStatus == 'LOADING' || submitStatus == 'DONE'" class="results-container">
            <div class="result-tags">
                <div v-if="tags.length > 0" class="tag all" :class="[{'active' : activeTag == 'all'}]" @click="selectTag('all')" :key="k">
                    <i class="i-all" />
                    <label>Tudo</label>
                </div>
                <div v-for="(tag, k) in tags" class="tag" :class="[tag.alias, {'active' : activeTag == tag.alias}]" @click="selectTag(tag.alias)" :key="k">
                    <i :class="'i-'+tag.alias" />
                    <label>{{tag.name}}</label>
                </div>
            </div>
            <div class="results" ref="results">
                <fyi-loading v-if="submitStatus == 'LOADING'" :size="'small'"/>
                <div v-for="(tag, k) in tags" class="result-section" :class="tag.alias" :key="k">
                    <span><i :class="'i-'+tag.alias" /> {{tag.name}}</span>
                    <div v-for="(item, k) in tag.results" @click="goTo(tag, item)" class="result" :key="k">
                        {{item.name}}
                    </div>
                </div>
            </div>
            <span v-if="total > 0">A mostrar {{total}} resultados</span>
            <span v-else-if="total == 0 && submitStatus == 'DONE'">Sem resultados</span>
        </div>
    </div>
    <teleport to="body">
        <div v-if="submitStatus == 'LOADING' || submitStatus == 'DONE'" class="bg" @click="clearSearch">
        </div>
    </teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "SuperSearch",
    props: {
        placeholder: {
            type: String,
            default: "Pesquisar..."
        },
        search: {
            type: Function
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            query: "",
            typingTimer: null,
            typingInterval: 1000,
            submitStatus: "READY",
            tags: [],
            total: 0,
            activeTag: 'all',
            enabled: false
        };
    },
    methods: {
        ...mapActions(["superSearch"]),
        handleUp(event) {
            clearTimeout(this.typingTimer);
            if(event.keyCode == 27){
                this.clearSearch()
                return
            }
            this.submitStatus = "LOADING"
            this.tags = []
            this.total = 0
            this.enabled = true
            this.$emit('searching', true);
            this.typingTimer = setTimeout(() => {this.doneTyping(event)}, 500);
        },
        clearSearch(){
            this.$refs.input.value = ""
            this.$refs.input.blur()
            this.activeTag = 'all'
            this.total = 0
            this.query = ""
            this.enabled = false
            this.tags = []
            this.submitStatus = "READY"
            this.$emit('searching', false);
        },
        async doneTyping(event) {
            let text = event.target.value;
            if(text.length < 3)
                return
                
            let total = 0;
            this.superSearch({text: text, params: "filters=destaque&blockFilter=menu,form"}).then( response => {
                if(this.enabled){
                    for (const key in response) {
                        let tmp = {name: response[key].name , alias: key.substring(0,3), results:[]}
                        if(response[key].data != undefined){
                            if(response[key].data.data != undefined)
                                response[key].data.data.forEach( r => {
                                    tmp.results.push( {
                                        name: r.name ? r.name : r.languages[0].name ,
                                        id: r.id
                                    })
                                });
                            else
                                response[key].data.forEach( r => {
                                    tmp.results.push( {
                                        name: r.name ? r.name : r.languages[0].name ,
                                        id: r.id
                                    })
                                });
                        }
                        if(tmp.results.length > 0){
                            total+=tmp.results.length
                            this.tags.push(tmp)
                        }
                    }
                    this.total =total;
                    this.submitStatus = "DONE"
                }
            })
            
        },
        selectTag(tag){
            this.activeTag = tag
            if(tag == "all"){
                this.$refs.results.querySelectorAll(".result-section").forEach( el => {
                    el.classList.remove("hidden")
                })
            }else{
                this.$refs.results.querySelectorAll(".result-section").forEach( el => {
                    if(!el.classList.contains(tag))
                        el.classList.add("hidden")
                    else
                        el.classList.remove("hidden")
                })
            }
        },
        goTo(tag, item){
            if(tag.alias == "con"){
                this.$router.push({ name: 'pages_cms_edit', params: { id: this.$route.params.id, content_id: item.id }})
            }else if(tag.alias == "blo"){
                this.$router.push({ name: 'blocks_cms_edit', params: { id: this.$route.params.id, block_id: item.id }})
            }else if(tag.alias == "use"){
                this.$router.push({ name: 'users_edit', params: { id: item.id}})
            }
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/form/search.scss";
</style>
