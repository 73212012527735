<template>
    <div class="type-content edit" v-if="type != null" :class="[{'open' : open}]">

        <fyi-text
            v-bind:value="type.languages[0].name"
            v-on:input-text="type.languages[0].name = $event"
            :label="'Nome'"
            :icon_box="false" :full="true"
        />

        <fyi-text
            class="alias"
            v-bind:value="type.languages[0].alias"
            v-on:input-text="type.languages[0].alias = $event"
            :label="'Alias'"
            :icon_box="false" :full="true"
        />

        <fyi-textarea
            v-bind:value="type.languages[0].description"
            @input="type.languages[0].description = $event"
            :label="'Descrição'"
            :toolbar="'mini'"
            :textAreaID="'description'"
        />

        <div class="type-edit">
            <div class="collapsible" @click="open($event)">
                <label>Addons</label>
                <i class="i-collapse"/>
            </div>
            <div v-if="addons.length > 0" class="addons-available">
                <fyi-checkbox v-for="(addon, k) in addons" :alias="addon.alias" :label="addon.name"    
                :value="type.addons.includes(addon.alias)"  
                :checked="type.addons.includes(addon.alias)"                      
                v-on:input="handleAddons( addon.alias, $event.checked)" :key="k"/> 
            </div>
        </div>

        <div class="type-edit">
            <div class="collapsible" @click="open($event)">
                <label>Categorias</label>
                <i class="i-collapse"/>
            </div>
            <div v-if="categories.data.length > 0" class="categories-available">
                <fyi-checkbox v-for="(category, k) in categories.data" :alias="category.languages[0].alias" :label="category.languages[0].name"    
                :value="type.categories.filter( c => { return c.category_id == category.id}).length > 0"  
                :checked="type.categories.filter( c => { return c.category_id == category.id}).length > 0"                      
                v-on:input="handleCategories( category.id, $event.checked)" :key="k"/> 
            </div>
        </div>

        <div class="type-edit">
            <div class="collapsible" @click="open($event)">
                <label>Campos Extra</label>
                <i class="i-collapse"/>
            </div>
            <div v-if="fields.length > 0" class="categories-available">
                <fyi-checkbox v-for="(field, k) in fields" :alias="field.languages[0].label" :label="field.languages[0].label"    
                :value="type.fields.filter( c => { return c.field_id == field.id}).length > 0"  
                :checked="type.fields.filter( c => { return c.field_id == field.id}).length > 0"                      
                v-on:input="handleFields( field.id, $event.checked)" :key="k"/> 
            </div>
        </div>

        <div class="type-edit">
            <div class="collapsible" @click="open($event)">
                <label>Relacionados</label>
                <i class="i-collapse"/>
            </div>
            <div class="related">
            <div class="templates-available">
                <Table ref="table" :items="{data: type.relateds, total: type.relateds.length}" :items2="types" :items3="required" :tableClass="'type-related'"
                    :pagination="false" @addon-order-related="resortRelated"
                    @remove="removeType" @add="addType" :showEntries="false" :showTotal="false" :showFilters="false"
                />
            </div>
            </div>
        </div>

        <fyi-button
            :label="'Guardar'"
            :className="'default save'"
            :loading="submitStatus=='PENDING'"
            :onclick="() => save()"
        />
    </div>
    <teleport to="body">
        <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
    </teleport>

</template>

<script>
import { mapActions } from "vuex";

export default {
    name:"TemplateEdit",
    data(){
        return{
            required:[{
                label: 'Sim',
                value: 1,
            }, 
            {
                label: 'Não',
                value: 0,
            }],
            types: [],
            categories: [],
            fields: [],
            addons: [],
            submitStatus:"READY",
            typesModel:[],
            type: null,
            contents: [],
            pagesParsed: [],
            toast_type: '',
            toast_message: '',
            toast_show: false,
            addPage: false,
            pageTemp: {},
            addPageContent: [],
            addPageContentKey: 1,
            loadTable: false,
            newPosition: ''
        }
    },
    async beforeMount(){
        this.categories = await this.getCategories();
        this.fields = (await this.getFields()).filter( f => { return f.languages.length > 0 });
        this.addons = await this.getAddons();
        this.types = await this.getTypes({});

        if(this.$route.name.includes("edit")){
            this.type = await this.getType(this.$route.params.type_id)
        }else{
            this.type = {
                languages:[{
                    name:'',
                    alias:'',
                    language_id: 1
                }],
                addons:[],
                fields:[],
                categories:[],
                relateds:[]
            }
        }

        this.$parent.$parent.loading = false;
        this.$parent.$parent.transition = false;
    },
    methods:{
        ...mapActions(["getType", "getTypes", "updateType", "createType", "getCategories", "getFields", "getAddons"]),
        hasPermission(prmsion) {
            let permissions = this.$store.getters.auth.permissions
            if(permissions.includes("all")){
                return true
            }else{
                let flag = 0;
                permissions.forEach(p => {
                    if(prmsion.some(item => item == p.split(".")[0])){
                        flag++;
                    }
                });

                return flag == prmsion.length
            }
        },
        open(ev){
            ev.target.parentElement.classList.toggle('open')
        },
        addType(c){
            c.id = Math.floor(Math.random() * 100000);
            this.type.relateds.push(c)
        },
        removeType(id){
            this.type.relateds.forEach( (c,k) => {
                if(c.id == id){
                    this.type.relateds.splice(k,1)
                }
            })
        },
        handleAddons(addon, checked){
            if(checked){
                this.type.addons.push(addon)
            }else{
                this.type.addons.splice(this.type.addons.indexOf(addon), 1)
            }
        },
        handleFields(field, checked){
            if(checked){
                this.type.fields.push({
                    id: Math.floor(Math.random() * 100000),
                    field_id: field,
                    type_id: this.type.id
                })
            }else{
                this.type.fields.forEach( (f, k) => {
                    if(f.id == field)
                        this.type.fields.splice(k, 1)
                });
            }
        },
        handleCategories(category, checked){
            if(checked){
                this.type.categories.push({
                    id: Math.floor(Math.random() * 100000),
                    category_id: category,
                    type_id: this.type.id
                })
            }else{
                this.type.categories.forEach( (c, k) => {
                    if(c.id == category)
                        this.type.categories.splice(k, 1)
                });
            }
        },
        async save(){
            try{
                this.submitStatus = "PENDING"
                let type = this.type;

                if(this.$route.name.includes("edit"))
                    await this.updateType({params: type, id: this.type.id})
                else{
                    await this.createType(type)
                    this.$router.push({ name: 'types', params: { id: this.$route.params.id }})
                }

                this.submitStatus = "READY"
                this.toast_type = "success";
                this.toast_message = "Alterado com sucesso";
                this.toast_show = true;
            }catch(error){
                console.log(error)
                this.submitStatus = "ERROR"
                this.toast_type = "error";
                let message = "Aconteceu um erro. Tente novamente.";
                for (const k in error.error) {
                    if(k == "name"){
                        message+="\n\nNecessita associar um nome."
                        this.$refs.name.$el.classList.add("error")
                    }
                    if(k == "positions"){
                        message+="\n\nNecessita associar uma posição."
                        this.$refs.positions.classList.add("error")
                    }
                }
                this.toast_message = message;
                this.toast_show = true;
            }

        },
    },
    mounted(){
        if(this.$route.name.includes("edit"))
            this.$parent.$parent.view = this.$t('edit')
        else
            this.$parent.$parent.view = this.$t('Criar')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/types.scss";   
</style>