<template>
  <div class="error-notfound">
    <p>Not found</p>
  </div>
</template>

<script>

export default {
  name: 'error-notfound'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
