import { accessSync } from "fs";

export default {
    email: "E-mail",
    email_placeholder: "Insira o e-mail registado",
    email_placeholder_2: "Introduza o e-mail registado",
    password: "Password",
    remember_login_access: "Recordar acessos de entrada",
    login: "Entrar",
    enter: "Entrar",
    password_update_title: "Actualização de password",
    password_update_description: "Por razões de segurança, sugerimos que altere a sua palavra passe atual.",
    password_update_bt: "Alterar palavra-passe",
    login_success: "Autenticação validada",
    forgot_password: "Esqueci-me da password",
    error_1: "Dados incorretos. Por favor tente novamente.",
    error_2: "Código inválido. Por favor tente novamente.",
    error_3: "Email inváildo. Insira um email válido",
    error_4: "As passwords coincidem",
    error_5: "As passwords não coincidem",
    error_6: 'Dados incorretos. Por favor tente novamente.',
    error_7: 'Código inválido. Por favor tente novamente.',
    error_8: 'O token atual não foi encontrado',
    error_9: 'A palavra passe já foi usada. Escolha uma nova palavra passe.',
    error_10: "É necessária uma password para prosseguir.",
    auth_code_title: "Código de Autenticação",
    auth_code_description: "Para continuar precisamos que verifique o seu email e insira o seu código.",
    timer_label: "O seu código",
    validate_code: "Validar código",
    resend_code: "Reenviar código",
    cancel: "Cancelar",
    expires_in: 'expira em ',
    expired: 'expirou',
    go_back: 'Retroceder',
    recover_password: 'Recuperar password',
    recover_password_description: 'Vamos enviar para o seu email as instruções para que possa recuperar a sua password',
    email_sent: "Email enviado",
    email_sent_description: "Verifique o seu email com as informações necessárias para alterar a sua password.",
    dashboard: "Dashboard",
    apps: "Aplicações",
    notifications: "Notificações",
    notifications_empty: "Sem novas notificações",
    remove_all: "Remover todas",
    users: "Utilizadores",
    users_empty: "Sem utilizadores",
    view_all: "Ver tudo",
    devices: "Dispositivos",
    devices_empty: "Neste momento não tem dispositivos associados à sua conta",
    no_app: "Sem aplicação",
    stats: "Estatísticas",
    environments: "Ambientes",
    quick_access: "Acesso Rápido",
    pages: "Páginas",
    blocks: "Blocos",
    news: "Notícias",
    sliders: "Sliders",
    menus: "Menus",
    media: "Media",
    forms: "Formulários",
    change_env: "Trocar de ambiente",
    stats_empty: "Sem estatísticas a reportar",
    environment_empty: "Sem ambientes adicionais",
    name: "Nome",
    location: "Localização",
    date_created: "Data de Criação",
    date_updated: "Data de Atualização",
    asc: "Ascendente",
    desc: "Descendente",
    published: "Publicado",
    unpublished: "Despublicado",
    draft: "Rascunho",
    add_el: "Adicionar elemento",
    export_table: "Exportar tabela",
    import_file: "Importar ficheiro",
    clear_cache: "Limpar cache",
    disable_el: "Desabilitar elemento",
    delete_el: "Eliminar elemento",
    filter_by: "Filtrar por",
    state: "Estado",
    restore: "Repor",
    apply_filters: "Aplicar filtros",
    order_by: "Ordenar por",
    order_type: "Tipo de ordenação",
    order: "Ordenar",
    fast_search: "Pesquisa rápida",
    entries: "Entradas",
    results: "resultados",
    update: "Atualização",
    creation: "Criação",
    edit: "Editar",
    remove: "Remover",
    add: "Adicionar",
    edited: "Editou",
    created: "Criou",
    deleted: "Apagou",
    options: "Opções",
    filter: "Filtrar",
    version: "Versão",
    go_to: "Ir para",
    Ordenar: "Ordenar",
    Localização: "Localização",
    Elemento: "Elemento",
    Ordenar: "Ordenar",
    Ordem: "Ordem",
    Adicionar: "Adicionar",
    Esconder: "Esconder",
    Template: "Template",
    Posição: "Posição",
    Plugin: "Plugin",
    up_level: "Subir de nível",
    down_level: "Descer de nível",
    templates: "Templates",
    types: "Tipos",
    type: "Tipo",
    fields: "Campos Extra",
    item: "Item",
    icon: "Icon"
};
