<template>
  <div class="addon image-compare" :class="[{ open: open }]">
    <div class="collapsible" @click="open = !open">
      <label>Imagem Comparativa</label>
      <i class="i-collapse" />
    </div>
    <div class="image-compare-form">
      <Upload
        :field="{
          label: 'Imagem original',
          value: images.find((i) => i.language_id === language)?.image1,
        }"
        @upload="setImage(1, $event)"
      />
      <Upload
        :field="{
          label: 'Imagem comparada',
          value: images.find((i) => i.language_id === language)?.image2,
        }"
        @upload="setImage(2, $event)"
      />
      <label
        v-if="
          images.find((i) => i.language_id === language)?.image1 &&
          images.find((i) => i.language_id === language)?.image2
        "
        >Preview:</label
      >
      <div
        v-if="
          images.find((i) => i.language_id === language)?.image1 &&
          images.find((i) => i.language_id === language)?.image2
        "
        class="img-comp-container"
      >
        <div class="img-comp-img">
          <img
            :src="
              $store.getters.appConfig.url +
              images.find((i) => i.language_id === language)?.image2
            "
          />
        </div>
        <div class="img-comp-slider"></div>
        <div class="img-comp-img img-comp-overlay">
          <img
            :src="
              $store.getters.appConfig.url +
              images.find((i) => i.language_id === language)?.image1
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddonImageCompare",
  props: {
    content: {
      type: Object,
      default: null,
    },
    language_index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      images: [{ image1: null, image2: null, language_id: this.language }],
      open: false,
    };
  },
  beforeMount() {
    if (this.content.imagecompares.length > 0)
      this.images = this.content.imagecompares;
  },
  mounted() {
    if (this.content.imagecompares.length > 0)
      setTimeout(() => {
        this.initComparisons();
      }, 250);
  },
  methods: {
    initComparisons() {
      var x, i;
      /* Find all elements with an "overlay" class: */
      x = document.getElementsByClassName("img-comp-overlay");
      for (i = 0; i < x.length; i++) {
        /* Once for each "overlay" element:
    pass the "overlay" element as a parameter when executing the compareImages function: */
        compareImages(x[i]);
      }
      function compareImages(img) {
        var slider,
          clicked = 0,
          w,
          h;
        /* Get the width and height of the img element */
        w = img.offsetWidth;
        h = img.offsetHeight;
        console.log("here");
        document.querySelectorAll(".img-comp-img img").forEach((el) => {
          el.style.width = w + "px";
        });
        /* Set the width of the img element to 50%: */
        img.style.width = w / 2 + "px";
        /* Create slider: */
        slider = document.querySelector(".img-comp-slider");
        /* Position the slider in the middle: */
        slider.style.top = h / 2 - slider.offsetHeight / 2 + "px";
        slider.style.left = w / 2 - slider.offsetWidth / 2 + "px";
        /* Execute a function when the mouse button is pressed: */
        slider.addEventListener("mousedown", slideReady);
        /* And another function when the mouse button is released: */
        window.addEventListener("mouseup", slideFinish);
        /* Or touched (for touch screens: */
        slider.addEventListener("touchstart", slideReady);
        /* And released (for touch screens: */
        window.addEventListener("touchend", slideFinish);
        function slideReady(e) {
          /* Prevent any other actions that may occur when moving over the image: */
          e.preventDefault();
          /* The slider is now clicked and ready to move: */
          clicked = 1;
          /* Execute a function when the slider is moved: */
          window.addEventListener("mousemove", slideMove);
          window.addEventListener("touchmove", slideMove);
        }
        function slideFinish() {
          /* The slider is no longer clicked: */
          clicked = 0;
        }
        function slideMove(e) {
          var pos;
          /* If the slider is no longer clicked, exit this function: */
          if (clicked == 0) return false;
          /* Get the cursor's x position: */
          pos = getCursorPos(e);
          /* Prevent the slider from being positioned outside the image: */
          if (pos < 0) pos = 0;
          if (pos > w) pos = w;
          /* Execute a function that will resize the overlay image according to the cursor: */
          slide(pos);
        }
        function getCursorPos(e) {
          var a,
            x = 0;
          e = e.changedTouches ? e.changedTouches[0] : e;
          /* Get the x positions of the image: */
          a = img.getBoundingClientRect();
          /* Calculate the cursor's x coordinate, relative to the image: */
          x = e.pageX - a.left;
          /* Consider any page scrolling: */
          x = x - window.pageXOffset;
          return x;
        }
        function slide(x) {
          /* Resize the image: */
          img.style.width = x + "px";
          /* Position the slider: */
          slider.style.left = img.offsetWidth - slider.offsetWidth / 2 + "px";
        }
      }
    },
    setImage(img, data) {
      if (!this.images.find((i) => i.language_id === this.language))
        this.images.push({
          image1: null,
          image2: null,
          language_id: this.language,
        });
      if (img === 1)
        this.images.find((i) => i.language_id === this.language).image1 = data;
      else if (img === 2)
        this.images.find((i) => i.language_id === this.language).image2 = data;
      setTimeout(() => {
        this.initComparisons();
      }, 10);
    },
    getInfo() {
      return this.images;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/layout/addons.scss";

* {
  box-sizing: border-box;
}

.img-comp-slider {
  position: absolute;
  z-index: 9;
  cursor: ew-resize;
  /*set the appearance of the slider:*/
  width: 40px;
  height: 40px;
  background-color: #2196f3;
  opacity: 0.7;
  border-radius: 50%;
}

.img-comp-slider:before {
  content: "";
  height: 180px;
  width: 4px;
  position: absolute;
  background: #2196f3;
  top: -180px;
  left: 18px;
}

.img-comp-slider:after {
  content: "";
  height: 180px;
  width: 4px;
  position: absolute;
  background: #2196f3;
  bottom: -180px;
  left: 18px;
}

.img-comp-container {
  position: relative;
  height: 400px;
}

.img-comp-img {
  position: absolute;
  width: auto;
  height: auto;
  overflow: hidden;
}

.img-comp-img img {
  display: block;
  vertical-align: middle;
  height: 400px;
}
</style>
