import { v1 as UUID} from "uuid";

import Init from "../../libs/api/init";

export default {
    state: {
        clientToken: sessionStorage.getItem("clientToken") || null,
        identity: localStorage.getItem("identity") || null,
        node: localStorage.getItem("node") || null,
        clientInfo: sessionStorage.getItem("clientInfo") ? JSON.parse(sessionStorage.getItem("clientInfo")) : null,
    },

    getters: {
        init: state => ({
        clientToken: state.clientToken,
        }),
        clientInfo: ({clientInfo}) => ({
        info: clientInfo,
        title: (clientInfo && clientInfo.name && clientInfo.name !== '') ? clientInfo.name : process.env.VUE_APP_TITLE,
        logo: (clientInfo && clientInfo.logo) ? clientInfo.logo : false,
        node: (clientInfo && clientInfo.node) ? clientInfo.node : false
        }),
        identity: state => state.identity,
        namespace: state => state.namespace,
    },

    mutations: {
        setClientToken: (state, token) => {
            state.clientToken = token;

            if (token == null) sessionStorage.removeItem("clientToken");
            else sessionStorage.setItem("clientToken", token);
        },
        setIdentity: (state, identity) => {
            if (!state.identity) {
                state.identity = identity;
                localStorage.setItem("identity", identity);
            }
        },
        setNodeURL: (state, nodeURL) => {
            state.node = nodeURL;
            localStorage.setItem("node", nodeURL);
        },
        setClientInfo: (state, client) => {
            state.clientInfo = client;
            if (client) sessionStorage.setItem("clientInfo", JSON.stringify(client));
        },
    },

    actions: {
        init: async ({ commit }) => {
			// set identity
            commit("setIdentity", UUID());
            // init infinitum
            const data = await Init.init();
            let { access_token, client } = data;

            client.node = data.node;

            commit("setClientToken", access_token);
            if (client) {
                commit("setClientInfo", client);
            }

            return access_token;
		},
		
    },
};
