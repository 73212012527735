<template>
      <div class="addon maps" :class="[{'open' : open}]"> 
        <div class="collapsible" @click="open = !open">
            <label>Mapas</label>
            <i class="i-collapse" />
        </div>
        <div class="maps-form">
            <fyi-text
                v-bind:value="maps && maps[0] ? maps[0].title : ''"
                v-on:input-text="maps && maps[0] ? maps[0].title = $event : tmp.title = $event"
                :label="'Título'"
                :icon_box="false" :full="true"
            />
            <SimpleSelect 
                :label="'Tipo'"
                :className="'type'"
                :previewIcon="'i-order'"
                @select-data-value="maps && maps[0] ? maps[0].map_type = $event : tmp.map_type = $event" :options="map_types.map( s => { return {label: s.label, value: s.value, checked: maps && maps[0] ? maps[0].map_type == s.value : false}})"
            />
            <SimpleSelect 
                :label="'Cor dos Marcadores'"
                :className="'color'"
                :previewIcon="'i-order'"
                @select-data-value="maps && maps[0] ? maps[0].marker_color = $event : tmp.marker_color = $event" :options="marker_color.map( s => { return {label: s.label, value: s.value, checked: maps && maps[0] ? maps[0].marker_color == s.value : false}})"
            />
            <GMapMap
                :center="center"
                :zoom="13"
                map-type-id="terrain"
                ref="map"
                @click="setMarker"
            >
                <GMapMarker
                    v-if="tmp.latitude"
                    :position="{lat:tmp.latitude,lng:tmp.longitude}"
                    :clickable="true"
                    :draggable="true"
                    @dragend="updateCoord"
                    @click="center={lat:tmp.latitude,lng:tmp.longitude}"
                />
                <GMapMarker
                    :key="index"
                    v-for="(m, index) in maps.filter( m => m.language_id == this.language)"
                    :position="{lat:parseFloat(m.latitude),lng:parseFloat(m.longitude)}"
                    :clickable="true"
                    :draggable="true"
                    @dragend="updateCoord($event, index)"
                    @click="center={lat:m.latitude,lng:m.longitude}"
                />
            </GMapMap>
            <div class="markers-container">
                <div  v-for="(marker, k) in maps.filter( m => m.language_id == this.language)" :key="k">
                    <div class="icon-selector">
                        <div :class="marker.icon" class="preview" />
                        <SimpleSelect 
                        :className="'field-type'"
                        :previewIcon="'i-order'"
                        @select-data-value="marker.icon = $event" :options="icons.map( s => { return {label: s.label, value: s.value, checked: marker.icon == s.value}})"
                        />
                    </div>
                    <!-- <fyi-text
                        v-bind:value="marker.address"
                        v-on:input-text="marker.address = $event"
                        :label="'Morada'"
                        :icon_box="false" :full="true"
                    /> -->
                    <div class="address-autocomplete">
                        <label>Morada</label>
                        <GMapAutocomplete
                            :value="marker.address"
                            placeholder="Morada"
                            @place_changed="setPlace($event, k)"
                        />
                    </div>
                    <fyi-number
                        :classe="'lat'"
                        v-bind:value="marker.latitude"
                        v-on:input-number="marker.latitude = $event"
                        :label="'Latitude'"
                        :show="true"
                        :placeholder="''"
                        :disabled="false"
                        :required="true"
                        :inputError="false"
                    />
                    <fyi-number
                        :classe="'long'"
                        v-bind:value="marker.longitude"
                        v-on:input-number="marker.longitude = $event"
                        :label="'Longitude'"
                        :show="true"
                        :placeholder="''"
                        :disabled="false"
                        :required="true"
                        :inputError="false"
                    />
                    <fyi-text
                        :className="'desc'"
                        v-bind:value="marker.description"
                        v-on:input-text="marker.description = $event"
                        :label="'Descrição'"
                        :icon_box="false" :full="true"
                    />
                    <i @click="removePlace(k)" class="i-remove" />
                </div>
                <div>
                    <div class="icon-selector">
                        <div :class="tmp.icon" class="preview" />
                        <SimpleSelect 
                        :className="'change-icon'"
                        :previewIcon="'i-order'"
                        @select-data-value="tmp.icon = $event" :options="icons.map( s => { return {label: s.label, value: s.value, checked: tmp.icon == s.value}})"
                        />
                    </div>
                    <!-- <fyi-text
                        v-bind:value="marker.address"
                        v-on:input-text="marker.address = $event"
                        :label="'Morada'"
                        :icon_box="false" :full="true"
                    /> -->
                    <div class="address-autocomplete">
                        <label>Morada</label>
                        <GMapAutocomplete
                            placeholder=""
                            :value="tmp.address"
                            @place_changed="setPlace"
                        />
                    </div>
                    <fyi-number
                        :classe="'lat'"
                        v-bind:value="tmp.latitude"
                        v-on:input-number="tmp.latitude = $event"
                        :label="'Latitude'"
                        :show="true"
                        :placeholder="''"
                        :disabled="false"
                        :required="true"
                        :inputError="false"
                    />
                    <fyi-number
                        :classe="'long'"
                        v-bind:value="tmp.longitude"
                        v-on:input-number="tmp.longitude = $event"
                        :label="'Longitude'"
                        :show="true"
                        :placeholder="''"
                        :disabled="false"
                        :required="true"
                        :inputError="false"
                    />
                    <fyi-text
                        :className="'desc'"
                        v-bind:value="tmp.description"
                        v-on:input-text="tmp.description = $event"
                        :label="'Descrição'"
                        :icon_box="false" :full="true"
                    />
                    <i />
                </div>
            </div>
            <fyi-button
            :label="'Adicionar marcador'"
            :className="'default'"
            :onclick="() => {addPlace()}"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'AddonMaps',
    props: {
        content: {
            type: Object,
            default: null
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            loaded: false,
            mapsKey: 0,
            maps: [],
            open: false,
            center: {lat: 41.157723, lng: -8.629211},
            icons: [
                {
                    label: 'Default',
                    value: 'default',
                    checked: false
                }
            ],
            marker_color: [
                {
                    label: 'Default',
                    value: 'default',
                    checked: false
                },
            ],
            map_types: [
                {
                    label: 'Satélite',
                    value: 'satellite',
                    checked: false
                },
                {
                    label: 'Mapa',
                    value: 'map',
                    checked: false
                },
            ],
            marker_color: [
                {
                    label: 'Default',
                    value: 'default',
                    checked: false
                },
            ],
            tmp:{
                latitude:'',
                longitude:'',
                title:'',
                address:'',
                icon:'default',
                country:'',
                address:'',
                zipcode:'',
                locality:'',
                description:'',
                map_type: '',
                marker_color: ''
            }
        }
    },
    beforeMount(){
        this.maps = this.content.maps ? this.content.maps : [];
        if(this.content.maps && this.content.maps.length > 0){
            this.center = {lat: parseFloat(this.maps[0].latitude), lng: parseFloat(this.maps[0].longitude)}
        }
    },
    methods: {
        setMarker(event){
            this.center = {lat: event.latLng.lat(),lng:event.latLng.lng()}
            this.tmp.address = event.formatted_address
            this.tmp.latitude = event.latLng.lat()
            this.tmp.longitude = event.latLng.lng()
        },
        setPlace(event, key = null){
            this.center = {lat: event.geometry.location.lat(),lng:event.geometry.location.lng()}
            if(key == null){
                this.tmp.address = event.formatted_address
                event.address_components.forEach( add => {
                    if(add.types.includes('locality'))
                        this.tmp.locality = event.address_components[0].long_name

                    if(add.types.includes('postal_code'))
                        this.tmp.zipcode = event.address_components[3].long_name  

                    if(add.types.includes('country'))
                        this.tmp.country = event.address_components[2].long_name
                })

                this.tmp.latitude = event.geometry.location.lat()
                this.tmp.longitude = event.geometry.location.lng()
            }else{
                this.maps[key].address = event.formatted_address
                event.address_components.forEach( add => {
                    if(add.types.includes('locality'))
                        this.maps[key].locality = event.address_components[0].long_name

                    if(add.types.includes('postal_code'))
                        this.maps[key].zipcode = event.address_components[3].long_name  

                    if(add.types.includes('country'))
                        this.maps[key].country = event.address_components[2].long_name
                })

                this.maps[key].latitude = event.geometry.location.lat()
                this.maps[key].longitude = event.geometry.location.lng()
            }

            // this.mapsKey++;
        },
        updateCoord(event, key){
            if(key == null){
                this.tmp.latitude = event.latLng.lat()
                this.tmp.longitude = event.latLng.lng()
            }else{
                this.maps[key].latitude = event.latLng.lat()
                this.maps[key].longitude = event.latLng.lng()
            }
        },
        addPlace(){
            this.tmp.language_id = this.language
            this.maps.push(this.tmp)

            this.tmp = {
                latitude:'',
                longitude:'',
                title:'',
                address:'',
                icon:'default',
                country:'',
                address:'',
                zipcode:'',
                locality:'',
                description:'',
                map_type: '',
                marker_color: ''
            }

            this.$emit('update-maps', this.maps)
        },
        removePlace(key){
            this.maps.splice(key, 1)
            this.$emit('update-maps', this.maps)
        }
    },
    mounted(){
        this.loaded = true
        this.$emit('update-maps', this.maps)
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>