<template>
    <div class="content">
        <label>{{$t('users')}}</label>
        <div class="page-content">
            <Table ref="table" :tableClass="'users'" :items="this.users" :paginationLoading="paginationLoading" :loading="loadTable" :limit="limit"
            :availableOrders="availableOrders" :availableDirections="availableDirections"
            @handlePagination="handlePagination" @apply-order="updateOrder"
            @search="searchValue = $event" :contextMenu="contextMenu" :optionButtons="optionButtons"
            @remove="removeItem" @edit="editItem" @resend="resendInv" :showEntries="false"/>
        </div>
        <teleport to="body">
            <transition name="fade">
                <LoadingPage v-if="transition" />
            </transition>
        </teleport>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
        <teleport to="body">
            <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
        </teleport>
    </div>
</template>

<script>
import { mapActions } from "vuex";


export default {
    name:"DashboardUsers",
    data() {
        return {
            loading: true,
            transition: true,
            availableOrders: [
                {
                    alias:"name",
                    label:this.$t('name'),
                    checked: false
                },
                {
                    alias:"date",
                    label:this.$t('date_created'),
                    checked: true
                },
                {
                    alias:"updated",
                    label:this.$t('date_updated'),
                    checked: false
                },
            ],
            availableDirections:[
                {
                    alias:"asc",
                    label:this.$t('asc'),
                    checked: false
                },
                {
                    alias:"desc",
                    label:this.$t('desc'),
                    checked: true
                },
            ],
            contextMenu: [
                // {label:this.$t('Importar utilizadores'), class:"i-export", func: this.imporTable},
                // {label:this.$t('Exportar utilizadores'), class:"i-export", func: this.exportTable},
            ],
            optionButtons: [
                {label:this.$t('Adicionar Utilizador'), class:"i-plus", func: this.addItem},
            ],
            loadTable: false,
            paginationLoading: false,
            filterOn: false,
            searchValue: "",
            toast_type: '',
            toast_message: '',
            toast_show: false,
            limit: 10,
            limitTimeout: null,
            limitOnResize: true,
            order: 'date_desc',
            users: []
        }
    },
    watch: {
        async order(_new, _old){
            if(this.searchValue){
                this.search()
                return
            }

            this.$refs.table.resetPage(1)

            this.loadTable = true;
            this.paginationLoading = true;

            this.users = await this.getUsers('limit='+this.limit+"&order="+this.order+'&page=1&search='+this.searchValue)

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async searchValue(_new, _old){
            if(_new.trim() == "" && _old == '' )
                return

            await this.search()
        }
    },
    async beforeMount(){
        this.changeLimit();
        this.users = await this.getUsers('limit='+this.limit+"&order="+this.order+'&page=1')
        this.loading = false;
        this.transition = false;
    },
    methods: {
        ...mapActions(["getUsers","deleteUser", "resendInvite"]),
        setLimit(value){
            //handles content limit set on the UI
            if(value != 0){
                this.limitOnResize = false;
                this.limit = parseInt(value);
                if(this.users.data != undefined){
                    this.reload();
                }
            }else{
                this.limitOnResize = true;
                this.changeLimit();
            }
        },
        changeLimit(){
            if(!this.$parent.$parent.$parent.$parent.expired && this.limitOnResize){
                //throttling so it doesnt make the same request while still performing a resize
                clearTimeout(this.limitTimeout);
                this.limitTimeout = setTimeout(() => {
                    let availableHeight = window.innerHeight - 144 - 144 - 88 - 30; // 144 - page header; 144 - table filters; 30 - table header; 88 - pagination element
                    let rows = 0, rowHeight = 60;
                    //while theres still available space increase the number of rows
                    while(availableHeight > rowHeight){
                        availableHeight -= rowHeight;
                        rowHeight = rowHeight == 60 ? 44 : 60; //row height changes every other row
                        rows++;
                    }
                    this.limit = rows == 0 ? 9 : rows //9 is default number of rows
        
                    //if there is content available reload
                    if(this.users.data != undefined){
                        this.reload();
                    }
                }, 500)
            }
        },
        async handlePagination(page){
            //set current table size to maintain gap for the pagination widget so it doesnt move around
            this.$refs.table.$el.querySelector(".pagination").style.top = this.$refs.table.$el.querySelector(".template-table").clientHeight + 47 + "px";

            //if theres a value in the search input perform search action otherwise continue
            if(this.searchValue){
                this.search(page)
                return
            }

            //show loading inside the table element
            this.loadTable = true;

            this.users = await this.getUsers('limit='+this.limit+"&order="+this.order+'&page='+page)

            //stop loading widget
            this.loadTable = false;
            this.$refs.table.$el.querySelector(".pagination").style = ""
        },
        updateOrder(info){
            let order = ''
            for(let k in info["states"][0])
                if(info["states"][0][k]) order = k+"_"

            for(let k in info["states"][1])
                if(info["states"][1][k]) order += k
            
            this.order = order
        },
        editItem(id){
            this.$router.push({ name: "users_edit", params: { id: id } })
        },
        addItem(){
            this.$router.push({ name: "users_create" })
        },
        async removeItem(id){
            const ok = await this.$refs.modal.show({
                message: "Deseja eliminar o utilizador?",
                okButton: 'Eliminar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.deleteUser(id).then( r => {
                    this.reload();
                });
            }
        },
        async resendInv(id){
            await this.resendInvite(id)
            this.toast_type = "success";
            this.toast_message = "Convite reenviado com sucesso!";
            this.toast_show = true;
        },
        async reload(){
            this.loadTable = true;
            this.paginationLoading = true;
            this.users = await this.getUsers('limit='+this.limit+"&order="+this.order+'&page='+this.page);

            this.loadTable = false;
            this.paginationLoading = false;
        },
        async search(page = false){
            this.loadTable = true;
            if(!page){
                this.paginationLoading = true;
                this.$refs.table.resetPage(1)
            }

            if(this.searchValue == '')
                this.users = await this.getUsers('limit='+this.limit+"&order="+this.order+'&page=1')
            else if(page)
                this.users = await this.getUsers('limit='+this.limit+"&order="+this.order+'&page='+this.page+"&search="+this.searchValue)
            else
                this.users = await this.getUsers('limit='+this.limit+"&order="+this.order+"&search="+this.searchValue)

            this.loadTable = false;

            if(!page)
                this.paginationLoading = false;
        },
        async imporTable(){

        },
        async exportTable(){
            
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../styles/pages/dashboard.scss";   
</style>