<template>
    <div v-if="parsedFields.length > 0" class="addon fields" :class="[{ 'open': open }]">
        <div class="collapsible" @click="open = !open">
            <label>Campos extra</label>
            <i class="i-collapse" />
        </div>
        <div class="fields-form">
            <template v-for="(field, k) in parsedFields" :key="k">
                <fyi-textarea v-if="field.field != undefined && field.field.type === 'textarea'"
                    v-bind:value="field.values[language_index].value"
                    v-on:input="field.values[language_index].value = $event"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    :placeholder="field.field.placeholder" :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1" :toolbar="'full'" />
                <fyi-email v-else-if="field.field != undefined && field.field.type === 'email'"
                    v-bind:value="field.values[language_index].value"
                    @input-email="field.values[language_index].value = $event"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    :placeholder="field.field.placeholder" :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1" :inputError="true" :icon_box="false" :full="true" />
                <SimpleSelect v-else-if="(field.field != undefined && field.field.type === 'dropdown')"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    @select="field.values[language_index].value = $event" :previewIcon="'i-order'"
                    :options="createDropdownInfo(!field.field.languages[language_index] ? (field.field.languages[0].data ? field.field.languages[0].data : field.field.data) : (field.field.languages[language_index].data ? field.field.languages[language_index].data : field.field.data), field.values[language_index].value)" />
                <SimpleSelect v-else-if="(field.field != undefined && field.field.type === 'select')"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    @select-data-value="field.values[language_index].value = $event" :previewIcon="'i-order'"
                    :options="createDropdownInfo(!field.field.languages[language_index] ? (field.field.languages[0].data ? field.field.languages[0].data : field.field.data) : (field.field.languages[language_index].data ? field.field.languages[language_index].data : field.field.data), field.values[language_index].value)" />
                <Upload v-else-if="field.field != undefined && field.field.type === 'file'" :field="field"
                    @upload="field.values[language_index].value = $event" />
                <fyi-phone v-else-if="field.field != undefined && field.field.type === 'phone'"
                    v-bind:value="field.values[language_index].value"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    :disabled="field.field.disabled == 1" :required="field.field.required == 1"
                    @input-phone="field.values[language_index].value = $event" />
                <fyi-checkbox v-else-if="field.field != undefined && field.field.type === 'checkbox'"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    :value="field.values[language_index].value" :checked="field.values[language_index].value"
                    v-on:input="field.values[language_index].value = $event.checked" :key="language_index"/>
                <fyi-radio v-else-if="field.field != undefined && field.field.type === 'radio'"
                    v-bind:value="field.values[language_index].value"
                    v-on:input="field.values[language_index].value = $event"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    :options="createDropdownInfo(!field.field.languages[language_index] ? (field.field.languages[0].data ? field.field.languages[0].data : field.field.data) : (field.field.languages[language_index].data ? field.field.languages[language_index].data : field.field.data), field.values[language_index].value)"
                    :disabled="field.field.disabled == 1" :required="field.field.required == 1"
                    @input-value="field.values[language_index].value = $event" :key="language_index"/>
                <Switch v-else-if="field.field != undefined && field.field.type === 'toggle'"
                    :enabled="field.values[language_index].value == 1" :showLabel="true"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    @input="field.values[language_index].value = $event == 1 ? true : false" :key="language_index"/>
                <fyi-text v-else-if="field.field != undefined" v-bind:value="field.values[language_index].value"
                    v-on:input-text="field.values[language_index].value = $event"
                    :label="field.field.languages[language_index] ? field.field.languages[language_index].label : field.field.languages[0].label"
                    :placeholder="field.field.placeholder" :disabled="field.field.disabled == 1"
                    :required="field.field.required == 1" :icon_box="false" :full="true" />
            </template>
        </div>
    </div>
    <!--
email
password
text
specific-event (?)
phone
datepicker
timepicker
schedule
url
toggle (switch)
textarea
radio
checkbox
select
table
relate-contents
upload
            -->
</template>

<script>
export default {
    name: "AddonFields",
    props: {
        content: {
            type: Object,
            default: null
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        },
        updatedValues: {
            type: Array,
            default: []
        }
    },
    watch: {
        parsedFields: {
            handler: function (_new, _old) {
                this.$emit('update-fields', _new)
            },
            deep: true
        },
    },
    computed: {
        parsedFields() {
            let fields = this.content.type.fields;

            fields.forEach(field => {
                let values = this.content.fields.filter(f => f.id == field.field_id)
                field.values = [];

                this.$store.getters.languages.forEach(l => {
            
                    values.forEach(v => {
                        if(v.pivot.language_id == l.id)
                            field.values.push({ value: v.pivot.value, language_id: v.pivot.language_id })
                    })

                    if(field.values.length == 0 || field.values[field.values.length-1].language_id != l.id)
                        field.values.push({ value: '', language_id: l.id })

                })

            });

            return fields
        }
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        createDropdownInfo(data, selected) {
            let options = JSON.parse(data);
            let list = [];

            for (let i = 0; i < options.length; i++) {
                if (options[i].value != undefined)
                    list.push(
                        {
                            label: options[i].label,
                            value: options[i].value,
                            checked: options[i].value == selected
                        })
                else
                    list.push(
                        {
                            label: options[i],
                            value: i,
                            checked: options[i] == selected
                        })
            }

            return list
        }
    },
    mounted() {
        this.$emit('update-fields', this.parsedFields)
    }
}
</script>
    
<style lang="scss" scoped>@import "../../../../styles/components/layout/addons.scss";</style>