import Categories from "../../../libs/api/cms/categories";

export default {
    state: {
        categories: [],
        category: {}
    },

    getters: {
        category: state => state.category,
        categories: state => state.categories,
    },
       
    mutations: {
        setCategories: (state, categories) => {
            state.categories = categories;
        },
        setCategory: (state, category) => {
            state.category = category;
            state.categories.push(category);
        }
    },

    actions: {
        getCategory: async ({ commit }, id) => {
            const category = await Categories.getCategory(id);
            commit("setCategory", category);
            return category;
        },
        getCategories: async ({ commit }, params) => {
            params = params || "";
            const { data, total, nextPage } = await Categories.getCategories(params);
            commit("setCategories", data);
            return { data, total, nextPage };
        },
        createCategory: async ({ commit }, params) => {
            await Categories.create(params);
        },
        deleteCategory: async ({ commit }, id) => {
            await Categories.deleteCategory(id);
        },
        editCategory: async (_, {id, params}) => {
            await Categories.edit(id, params);
        }
    }
}
