import Notifications from "../../../libs/api/notifications/notifications";
import store from "../..";

export default {
    state: {
        notifications: [],
        notificationsLog: [],
        unreadNotifications: [],
        unreadNotificationsTotal: 0,
        panelNotifications: [],
    },

    getters: {
        notifications: (state) => state.notifications,
        notificationsLog: (state) => state.notificationsLog,
        unreadNotifications: (state) => state.unreadNotifications,
        unreadNotificationsTotal: (state) => state.unreadNotificationsTotal,
        panelNotifications: (state) => state.panelNotifications,
    },

    mutations: {
        setNotifications: (state, notifications) => {
            state.notifications = notifications;
        },
        
        setNotificationsLog: (state, notificationsLog) => {
            state.notificationsLog = notificationsLog;
        },
        setUnreadNotifications: (state, unreadNotifications) => {
            state.unreadNotifications = unreadNotifications;
        },
        setUnreadNotificationsTotal: (state, unreadNotificationsTotal) => {
            state.unreadNotificationsTotal = unreadNotificationsTotal;
        },


        setPanelNotifications: (state, panelNotifications) => {
            state.panelNotifications = panelNotifications;
        },

        copyUnreadToPanelNotifications: (state) => {
            state.unreadNotifications = state.unreadNotifications.reduce((a, c) => {
                c.unread = false;
                a.push(c);
                return a;
            }, []);
            state.panelNotifications = state.panelNotifications.concat(state.unreadNotifications);
        },
        addUnreadNotification: (state, unreadNotification) => {
            state.unreadNotifications.push(unreadNotification);
            state.unreadNotificationsTotal = state.unreadNotificationsTotal +1;
        },
        markReadNotificationsLog: (state) => {
            let logs = state.unreadNotifications.reduce((a,c) => {
                a.push(c.log_id)
                return a;
            }, [])
            let data = {
                user_id: store.getters.auth.user.id,
                logs: logs
            }
            Notifications.markReadNotificationsLog(data);
        },
        markReadSingleNotificationsLog: (state, id) => {
            let data = {
                user_id: store.getters.auth.user.id,
                logs: [id]
            }
            Notifications.markReadNotificationsLog(data);
        }
    },

    actions: {
        getNotificationsLog: async ({ commit }, params) => {
            params = params || "";
            const { data, total, nextPage } = await Notifications.getNotificationsLog(params);
            commit("setNotificationsLog", data);
            return { data, total, nextPage };
        },
		
        sendNotification: async ({}, data) => {
            await Notifications.sendNotification(data);
        },
        getUnreadNotifications: async ({ commit }, params) => {
            params = params || "";
            let { data, total, nextPage } = await Notifications.getNotificationsLog(params);
            let menos = 0;
            data = data.reduce((supera,c) => {
                let content = c.content;
                content.log_id = c.id;
                content.unread = true;
                content.created_at = c.created_at
                content.key = c.action;
                if(!content.sender) {
                    content.sender = "System";
                }

                if(store.getters.auth.user && store.getters.auth.user.apps) {
                    let userApps = store.getters.auth.user.apps.reduce((a,c) => {
                        a.push(Number(c.id));
                        return a;
                    }, []);

                    let appTokens = store.getters.apps.reduce((a,c) => {
                        if(userApps.includes(Number(c.id))) {
                            a.push(c.token) 
                        }
                        return a;
                    }, []);

                    
                    let appToken = c.content.app || c.content.appToken;
                    if((appToken == null || appTokens.includes(appToken)) && content.sender !== store.getters.auth.user.id) {
                        supera.push(content);
                    } else {
                        menos = menos +1;
                    }
                }
                
                return supera;
            }, [])

            commit("setUnreadNotifications", data);
            commit("setUnreadNotificationsTotal", total - menos);
            return { data, total, nextPage };
        },

        markReadAll: ({ commit }) => {
            commit("copyUnreadToPanelNotifications");
            commit("markReadNotificationsLog")

            commit("setUnreadNotifications", []);
            commit("setUnreadNotificationsTotal", 0);
        },

        
        clearNotifications: ({ commit }) => {
            commit("setPanelNotifications", []);
            commit("setUnreadNotifications", []);
            commit("setUnreadNotificationsTotal", 0);
		},
		
		markRead: ({ commit, state }, id) => {
            commit("markReadSingleNotificationsLog", id)
            let temp_not = state.unreadNotifications;
            for (let i = 0; i < temp_not.length; i++) {
                if(temp_not[i].log_id == id){
                    temp_not.splice(i, 1);
                    break;
                }
            }
            commit("setUnreadNotifications", temp_not);
            commit("setUnreadNotificationsTotal", state.unreadNotificationsTotal - 1);
        }
    },
};
