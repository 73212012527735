<template>
    <div
     v-on:click.stop="select(this)" :class="{'fyi-checkbox': true, 'disabled': disabled}">
        <input
            ref="input"
            type="checkbox"
            v-model="checked"
            v-on:blur="$emit('blur')"
            v-on:input="select"
        >
        <span class="checkmark"></span>
        <label class="label" v-html="label"></label>
    </div>
</template>

<script>
export default {
    name: "fyi-checkbox",
    props: {
        value: {
            type: [Boolean, Number, String, Object]
        },
        isChecked: {
            type: Boolean,
            default: false
		},
		
        id: {
            type: Number,
            default: null
        },
        type: [String, Object],
        alias: String,
        options: Array,
        rowid: [Number, String],
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        selectedList:Array
    },
    watch: {
        isChecked: function(newVal, oldVal) {
            this.checked = newVal;
        },
        
        disabled: function(newVal, oldVal) {
        },


        checked: function(newVal, oldVal) {
        }
    },
    data() {
        return {
            checked: false
        };
    },
    methods: {
        select(el) {
            if (this.checked) {
                this.checked = false;
                this.$refs.input.checked = false
            } else {
                this.checked = true;
                this.$refs.input.checked = true
            }
            
            this.$emit(
                "input",
                {
                    type: this.type,
                    value: this.value,
                    id: this.id || this.value,
                    label: this.label,
                    rowid: this.rowid,
                    checked: this.checked
                },
                "checkbox"
            );
        },
        uncheck() {
            this.checked = false;
        },
        handleRowClick(params) {
            if(this.rowid !== null && this.rowid === params.id) {

                if (this.checked) {
                    this.checked = false;
                } else {
                    this.checked = true;
                }

                this.$emit(
                "input",
                    {
                        type: this.type,
                        value: this.value,
                        id: this.id || this.value,
                        label: this.label,
                        rowid: this.rowid,
                        checked: this.checked
                    },
                    "checkbox"
                );
            }
        }
    },
    created() {
        if(this.selectedList)
            this.checked = this.selectedList.includes(this.value) ? true : false;
        else
            this.checked = this.value ? this.value : this.isChecked;
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/components/form/input";
</style>
