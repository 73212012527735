<template>
    <div class="addon nutrition" :class="[{ 'open': open }]">
        <div class="collapsible" @click="open = !open">
            <label>Nutrição</label>
            <i class="i-collapse" />
        </div>
        <div class="nutrition-form" v-if="doneFetching">
            <fyi-number
            v-for="(field, k) in fields"
            v-bind:value="addon[field.field]"
            v-on:input-number="addon[field.field] = $event;$emit('update-nutrition', addon)"
            :label="field.nome"
            :icon_box="false"
            :key="k"
            />
        </div>
    </div>
</template>

<script>
import {  mapActions } from "vuex";

export default {
    name: "AddonNutrition",
    props: {
        content: {
            type: Object,
            default: null
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            open: false,
            doneFetching: false,
            fields: []
        }
    },
    async beforeMount(){
        this.fields = await this.getAddonNutritionUnits();
        this.addon = this.content.nutritions

        if(this.addon.length == 0){
            let tmp = {};
            this.fields.map( f => { return f.field }).reduce( (a,v) => {
                return {...a, [v] : ''}
            })
            this.addon = tmp;
        }else{
            this.addon = this.addon[0]
        }

        this.doneFetching = true
    },
    methods: {
        ...mapActions(["getAddonNutritionUnits"]),
    }
}
</script>
    
<style lang="scss" scoped>@import "../../../../styles/components/layout/addons.scss";</style>