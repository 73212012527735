<template>
	<div class="fyi-textarea" :key="alias" >
		<label v-if="label">{{label}}</label>
		<div class="textarea-container">
			<TextEditor
				ref="editor"
				:key="alias"
				:alias="alias"
				:value="value"
				:html="html"
				:textAreaID="textAreaID"
				:config="toolbar"
				:disabled="disabled"
				@input="$emit('input', $event)"
			/>
		</div>
	</div>
</template>

<script>
/*
				:key="alias"
				:alias="alias"
				:value="value"
				:placeholder="placeholder"
				v-on:blur="$emit('blur')"
				:toolbar="toolbar"
				:html="html"
				:tabindex="tabindex"
				@quill="$emit('input', $event)"
**/
export default {
	name: "fyi-textarea",
	props: {
		value: String,
		alias: String,
		label: {
			type: String,
			default: ""
		},
		placeholder: {
			type: String,
			default: ""
		},
		disabled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: false
		},
		html: {
			type: Boolean,
			default: false
		},
		toolbar: {
			type: String,
			default: "mini"
		},
		tabindex: {
			type: Number,
			default: 1
		},
		selectedLanguage: {
			type: Object,
			default: () => ({})
		},
		textAreaID: {
            type: String,
            default: "default"
        },
	},
	data(){
		return {
			
		}
	},
	beforeMount(){

	},
	data() {
		return {
			content: this.value,
			config: {
				placeholder: this.placeholder
			}
		};
	},
	methods:{
		getWordCount(){
			return this.$refs.editor.wordCount();
		}
	}
};
</script>

<style lang="scss">
.textarea-container{
	clear: both;
}
//@import "@/styles/components/form/textarea";
</style>
