<template>
    <div class="addon events" :class="[{'open' : open}]">
        <div class="collapsible" @click="open = !open">
            <label>Eventos</label>
            <i class="i-collapse" />
        </div>
        <div class="form" :class="[{'range': type == 'range'}]">
            <SimpleSelect 
            :label="'Frequência'"
            :className="'event-types'"
            :previewIcon="'i-order'"
            @select-data-value="type = $event" :options="options"/>
            <Date :value="parsedEvents.start.split(' ')[0]" 
            @update:modelValue="parsedEvents.start = (new Date($event)).toLocaleDateString('en-CA', {
                format: 'short',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }) + ' ' + parsedEvents.start.split(' ')[1];if(type!='range')parsedEvents.end = (new Date($event)).toLocaleDateString('en-CA', {
                format: 'short',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }) + ' ' + parsedEvents.end.split(' ')[1]; forceUpdateDate++; forceUpdateTime++; emit()" 
            :label="'Data Início'"/> 
            <Time :value="parsedEvents.start.split(' ')[1]"
            @update:modelValue="parsedEvents.start = parsedEvents.start.split(' ')[0] + ' ' + $event.hours + ':' + $event.minutes + ':00'; forceUpdateTime++; emit()"
            :label="'Hora Início'"/>
            <Date v-if="type=='range'" :value="parsedEvents.end.split(' ')[0]" 
            @update:modelValue="parsedEvents.end = (new Date($event)).toLocaleDateString('en-CA', {
                format: 'short',
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }) + ' ' + parsedEvents.end.split(' ')[1]; emit()" 
            :label="'Data Fim'"
            :minDate="parsedEvents.start.split(' ')[0]" :key="forceUpdateDate"/> 
            <Time :value="parsedEvents.end.split(' ')[1]"
            @update:modelValue="parsedEvents.end = parsedEvents.end.split(' ')[0] + ' ' + $event.hours + ':' + $event.minutes + ':00'; emit()"
            :label="'Hora Fim'"
            :minTime="(type != 'range' && parsedEvents.start != '') ? {hours: parsedEvents.start.split(' ')[1].split(':')[0], minutes: parsedEvents.start.split(' ')[1].split(':')[1]} 
            : (parsedEvents.start != '' && parsedEvents.start.split(' ')[0] == parsedEvents.end.split(' ')[0] ? {hours: parsedEvents.start.split(' ')[1].split(':')[0], minutes: parsedEvents.start.split(' ')[1].split(':')[1]} : null)"
            :key="forceUpdateTime"/>

            <fyi-number
                v-if="type == 'weekly' || type == 'monthly' || type == 'anual'"
                v-bind:value="parsedEvents.repeating"
                v-on:input-number="parsedEvents.repeating = $event; emit()"
                :label="'Repetir'"
                :classe='"repeating"'
                :alias="'repeating'"
                :show="true"
                :disabled="false"
                :inputError="false"
            />

            <div class="days" v-if="type == 'weekly'">
                <fyi-checkbox alias="remember-user" :label="'Seg'"    
                :value="days[1]"  
                :checked="days[1]"                      
                v-on:input="days[1] = $event.checked;emit()"/>
                <fyi-checkbox alias="remember-user" :label="'Ter'"    
                :value="days[2]"  
                :checked="days[2]"                      
                v-on:input="days[2] = $event.checked;emit()"/>
                <fyi-checkbox alias="remember-user" :label="'Qua'"    
                :value="days[3]"  
                :checked="days[3]"                      
                v-on:input="days[3] = $event.checked;emit()"/>
                <fyi-checkbox alias="remember-user" :label="'Qui'"    
                :value="days[4]"  
                :checked="days[4]"                      
                v-on:input="days[4] = $event.checked;emit()"/>
                <fyi-checkbox alias="remember-user" :label="'Sex'"    
                :value="days[5]"  
                :checked="days[5]"                      
                v-on:input="days[5] = $event.checked;emit()"/>
                <fyi-checkbox alias="remember-user" :label="'Sab'"    
                :value="days[6]"  
                :checked="days[6]"                      
                v-on:input="days[6] = $event.checked"/>
                <fyi-checkbox alias="remember-user" :label="'Dom'"    
                :value="days[0]"  
                :checked="days[0]"                      
                v-on:input="days[0] = $event.checked;emit()"/>
            </div>
            
            <fyi-text
                v-bind:value="parsedEvents.local"
                :label="'Local'"
                :className='"local"'
                @input-text="parsedEvents.local = $event; emit()"
                :icon_box="false" :full="true"
            />

            <fyi-button
                v-if="type == 'specific'"
                :label="'Adicionar data'"
                :className="'default add'"
                :onclick="() => addEvent()"
            />

            <div v-if="type == 'specific' && specific_events.length > 0" class="event-list">
                <div class="ev" v-for="(ev, index) in specific_events" :key="index">
                    <label><span>Local:</span> {{ev.local}}</label>
                    <label><span>Início:</span> {{ev.start}}</label>
                    <label><span>Fim:</span> {{ev.end}}</label>
                    <i class="i-remove" @click="removeEvent(index)"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"AddonEvents",
    props: {
        content: {
            type: Object,
            default: null
        }
    },
    data(){
        return{
            open: false,
            firstTime: true,
            options: [
                {
                    label: "Intervalo de Datas",
                    value: "range",
                    checked: false
                },
                {
                    label: "Data(s) Específica(s)",
                    value: "specific",
                    checked: true
                },
                {
                    label: "Repetir Semanalmente",
                    value: "weekly",
                    checked: false
                },
                {
                    label: "Repetir Mensalmente",
                    value: "monthly",
                    checked: false
                },
                {
                    label: "Repetir Anualmente",
                    value: "anual",
                    checked: false
                },
            ],
            days:[false,false,false,false,false,false,false],
            type: 'specific',
            specific_events: [],
            forceUpdateDate: 0,
            forceUpdateTime: 0
        }
    },
    watch:{
        type(_new){
            if(this.parsedEvents){
                this.parsedEvents.type = _new
                this.emit()
            }
        }
    },
    computed: {
        parsedEvents(){
            if(this.content.events.length == 0)
                return { type: this.type,
                    repeating: 1,
                    start: '',
                    end: '',
                    local: ''
                }

            let event = {
                type: this.content.events[0].event_type,
                repeating: (this.content.events[0].repeating == 'once' ? this.content.events.length : 1),
                start: this.content.events[0].start,
                end: this.content.events[this.content.events.length - 1].end,
                local: this.content.events[0].local
            }

             if(this.content.events[0].event_type == "specific"){
                event.start = ""
                event.end = ""
                event.local = ""
                event.repeating = 1
                this.specific_events = this.content.events.map( e => {
                    return {
                        start: e.start,
                        end: e.end,
                        local: e.local
                    }
                })
             }

            let days = 0;

            if(this.content.events[0].event_type == "weekly"){
                event = {
                    type: this.content.events[0].event_type,
                    repeating: Math.ceil(Math.abs((new Date(this.content.events[this.content.events.length - 1].start)) - (new Date(this.content.events[0].start))) / (1000 * 60 * 60 * 24 * 7)),
                    start: this.content.events[0].start,
                    end: this.content.events[this.content.events.length - 1].end,
                    local: this.content.events[0].local
                }

                days = Math.round(this.content.events.length / event.repeating)
            }

            if(this.firstTime){
                this.type = this.content.events[0].event_type
                this.firstTime = false

                for (let i = 0; i < days; i++) {
                    let dow = (new Date(this.content.events[i].start)).getDay();
                    this.days[dow] = true
                }
            }

            let options = this.options

            options.forEach( opt => {
                if(opt.value == this.type)
                    opt.checked = true
                else
                    opt.checked = false
            })  
            this.options = options

            return event
        }
    },
    methods:{
        emit(){
            let events = []

            for (let i = 0; i < this.parsedEvents.repeating; i++) {
                if(this.parsedEvents.start != ''){
                    if(this.parsedEvents.type == "specific"){
                        continue;
                    }else if(this.parsedEvents.type == "range"){
                        events.push(
                            {
                                start: this.parsedEvents.start,
                                end:  this.parsedEvents.end,
                                local: this.parsedEvents.local,
                                event_type: this.parsedEvents.type,
                                repeating: 'once',
                                timezone: "Europe/Lisbon"
                            }
                        )
                        continue;
                    }else if(this.parsedEvents.type == "weekly"){
                        let dates = [];
                        this.days.forEach( (d, index) => {
                            if(d){
                                let date = new Date(this.parsedEvents.start.split(" ")[0])
                                date.setDate(date.getDate() + (((7 - date.getDay() + index) % 7 + (7*i)) || (7*i)));
                                dates.push(date);
                            }
                        })
    
                        if(dates.length == 0){
                            let date = new Date(this.parsedEvents.start.split(" ")[0])
                            date.setDate(date.getDate() + (7*i));
                            dates.push(date);
                        }
     
                        dates.forEach( dt => {
                            events.push(
                                {
                                    start:  dt.toLocaleDateString('en-CA', {
                                            format: 'short',
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        }) + ' ' + this.parsedEvents.start.split(" ")[1],
                                    end:  dt.toLocaleDateString('en-CA', {
                                            format: 'short',
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit'
                                        }) + ' ' + this.parsedEvents.end.split(" ")[1],
                                    local: this.parsedEvents.local,
                                    event_type: 'weekly',
                                    repeating: 'once',
                                    timezone: "Europe/Lisbon"
                                }
                            )
                        })
                    }else if(this.parsedEvents.type == "monthly"){
                        let date = new Date(this.parsedEvents.start.split(" ")[0])
                        date.setMonth(date.getMonth() + (1*i));
                        events.push(
                            {
                                start:  date.toLocaleDateString('en-CA', {
                                        format: 'short',
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    }) + ' ' + this.parsedEvents.start.split(" ")[1],
                                end:  date.toLocaleDateString('en-CA', {
                                        format: 'short',
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    }) + ' ' + this.parsedEvents.end.split(" ")[1],
                                local: this.parsedEvents.local,
                                event_type: 'monthly',
                                repeating: 'once',
                                timezone: "Europe/Lisbon"
                            }
                        )
                    }else if(this.parsedEvents.type == "anual"){
                        let date = new Date(this.parsedEvents.start.split(" ")[0])
                        date.setFullYear(date.getFullYear() + (1*i));
                        events.push(
                            {
                                start:  date.toLocaleDateString('en-CA', {
                                        format: 'short',
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    }) + ' ' + this.parsedEvents.start.split(" ")[1],
                                end:  date.toLocaleDateString('en-CA', {
                                        format: 'short',
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit'
                                    }) + ' ' + this.parsedEvents.end.split(" ")[1],
                                local: this.parsedEvents.local,
                                event_type: 'anual',
                                repeating: 'once',
                                timezone: "Europe/Lisbon"
                            }
                        )
                    }
                }
            }

            for (let i = 0; i < this.specific_events.length; i++) {
                events.push(
                    {
                        start: this.specific_events[i].start,
                        end:  this.specific_events[i].end,
                        local: this.specific_events[i].local,
                        event_type: 'specific',
                        repeating: 'once',
                        timezone: "Europe/Lisbon"
                    }
                )
            }

            events.sort( (a,b) => {
                return new Date(a.start) - new Date(b.start);
            });

            this.$emit('update-events', events)
        },
        addEvent(){
            this.specific_events.push({
                start: this.parsedEvents.start,
                end: this.parsedEvents.end,
                local: this.parsedEvents.local
            })
            this.emit()
        },
        removeEvent(id){
            this.specific_events.splice(id, 1)
            this.emit()
        }
    },
    mounted(){
        this.emit()
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>