import Blocks from "../../../libs/api/cms/blocks";

export default {
    state: {
        blocks: [],
        blocksPage: 0,
        blocksCount: 0,
        currentBlocksPageCount: 0,
        block: null
    },

    getters: {
        blocks: state => state.blocks,
        blocksCount: state => state.blocksCount,
        currentBlocksPageCount: state => state.currentBlocksPageCount,
        block: state => state.block
    },

    mutations: {
        setBlocks: (state, blocks) => {
            state.blocks = blocks;
        },
        setBlock: (state, block) => {
            state.block = block;
        },
        setBlocksCount: (state, count) => {
            state.blocksCount = count;
        },
        setBlockPage: (state, page) => {
            state.blockPage = page;
        },
        setCurrentPageCount: (state, count) => {
            state.currentBlocksPageCount = count;
        }
    },

    actions: {
        getBlocks: async ({ commit }, params) => {
            const response = await Blocks.getBlocks(params);
            if (response.data !== null && response.count !== null) {
                commit("setBlocks", response.data);
                commit("setBlocksCount", response.total);
                commit("setCurrentPageCount", response.count);
            } else {
                commit("setBlocks", response);
            }

            return response
        },

        getBlock: async ({ commit }, { params, id }) => {
            const response = await Blocks.getBlock(params, id);
            commit("setBlock", response);

            return response
        },

        getBlockRevisions: async ({ commit }, { params, id }) => {
            const response = await Blocks.getBlockRevisions(params, id);

            return response
        },

        getBlocksCount: async ({ commit }, params) => {
            const response = await Blocks.getBlocksCount();
            commit("setBlocksCount", response.count);
        },

        updateBlock: async ({ commit }, { params, data, id }) => {
            const response = await Blocks.updateBlock(params, data, id);
            commit("setBlock", response);
        },

        createBlock: async ({ commit }, { params, data }) => {
            const response = await Blocks.createBlock(params, data);
            commit("setBlock", response);
        },

        deleteBlock: async ({ commit }, { params, id }) => {
            const response = await Blocks.deleteBlock(params, id);
            return response
        },

        removeSchedule: async ({ commit },  id ) => {
            const response = await Blocks.removeSchedule(id);
            return response
        },
    }
};
