<template>
    <div class="dashboard">
        <CardList
            :title="$t('quick_access')"
            :list="customMenu.length > 0 ? customMenu : menu"
            :loading='features == null'
            :icon="!qaDisabled"
            @titleFunc="openQASettings"
            :key="showQASettings"
        />
        <div class="grid-column-3 gap-20">
            <Card 
                :title="$t('notifications')"
                :action="$t('remove_all')"
                :list="unreadNot"
                :func="removeNotifications"
                :card_func="removeNotification"
                :className="'notification'"
                :emptyMessage="$t('notifications_empty')"
            />
            <Card 
                :title="$t('stats')"
                :action="$t('view_all')"
                :emptyMessage="$t('stats_empty')"
                :className="'stats'"
                :loading="false"
            />
            <Card 
                :title="$t('environments')"
                :emptyMessage="$t('environment_empty')"
                :list="$parent.$parent.available_apps"
                :className="'environment'"
                :loading="($parent.$parent.available_apps == undefined || Object.keys($parent.$parent.available_apps).length == 0) && loading"
                :card_func="goToEnv"
            />
        </div>
    </div>
    <teleport to="body">
        <QuickAccessPopup v-if="showQASettings" :app="$parent.$parent.app.id" :options="this.features"/>
    </teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";


export default {
    name:"DashboardCms",
    data() {
        return {
            menu: [
                {
                    label:this.$t('pages'),
                    icon:"i-pages",
                    route:"pages_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver páginas"
                },
                {
                    label:this.$t('blocks'),
                    icon:"i-blocks",
                    route:"blocks_cms",
                    params: this.$route.params,
                    query: {},
                    permissions: ["blocks"],
                    buttonLabel: "Ver blocos"
                },
                {
                    label:this.$t('news'),
                    icon:"i-news",
                    route: "pages_cms",
                    params: {},
                    query: {filter:'noticias'},
                    permissions: [],
                    buttonLabel: "Ver notícias"
                },
                // {
                //     label:this.$t('sliders'),
                //     icon:"i-sliders",
                //     route:"none",//"sliders",
                //     params: {},
                //     query: {},
                //     permissions: [],
                //     buttonLabel: "Ver sliders"
                // },
                {
                    label:this.$t('menus'),
                    icon:"i-menus",
                    route:"menus_cms",
                    params: {},
                    query: {},
                    permissions: ["menus"],
                    buttonLabel: "Ver menus"
                },
                {
                    label:this.$t('media'),
                    icon:"i-media",
                    route:"media",
                    params: {},
                    query: {},
                    permissions: [],
                    buttonLabel: "Ver media"
                },
                {
                    label:this.$t('forms'),
                    icon:"i-forms",
                    route:"forms",
                    params: {},
                    query: {},
                    permissions: ["forms"],
                    buttonLabel: "Ver formulários"
                },
                {
                    label:this.$t('fields'),
                    icon:"i-fields",
                    route:"fields",
                    params: {},
                    query: {},
                    permissions: ["fields"],
                    buttonLabel: "Ver campos extra"
                },
                {
                    label:this.$t('templates'),
                    icon:"i-pages",
                    route:"templates",
                    params: {},
                    query: {},
                    permissions: ["templates"],
                    buttonLabel: "Ver templates"
                },
                {
                    label:this.$t('types'),
                    icon:"i-types",
                    route:"types",
                    params: {},
                    query: {},
                    permissions: ["types"],
                    buttonLabel: "Ver tipos"
                },
            ],
            loading: true,
            showQASettings: false,
            features: null,
            qaDisabled: false
        }
    },
    computed: {
        unreadNot(){
            let final = [];
            this.$store.getters.unreadNotifications.forEach(not => {
                if(not.appToken == this.$parent.$parent.app.token){
                    final.push(not)
                }
            });
            return final;
        },
        customMenu(){
            let base_routes = ["types", "fields", "pages_cms", "blocks_cms", "menus_cms", "forms", "templates", "media"]
            let custom = [];
            
            this.features?.json?.forEach( f => {
                custom.push(                {
                    label:f.label,
                    icon:f.icon,
                    route: base_routes.includes(f.route) ? f.route : 'pages_cms',
                    params: this.$route.params,
                    query: !base_routes.includes(f.route) ? {filter:f.route} : {},
                    permissions: base_routes.includes(f.route) ? [f.route.split("_")[0]] : [],
                    buttonLabel: "Ver " + f.label.toLowerCase()
                },)
            })
            return custom
        }
    },
    watch: {
        async showQASettings(_new, _old){
            if(!_new)
                this.features = await this.getCmsFeatureOrder({app_id: this.$parent.$parent.app.id, user_id: this.$store.getters.auth.user.id})
        }
    },
    async beforeMount(){
        if(await this.featureEnabled('quick_access')){
            this.qaDisabled = true
            this.features = await this.getCmsFeatureOrder({app_id: this.$parent.$parent.app.id, user_id: this.$store.getters.auth.user.id})
        }
        await this.getUnreadNotifications("limit=99999999999999&unreadBy="+this.$store.getters.auth.user.id);
        this.loading = false;
        this.$parent.$parent.transition = false;
    },
    methods: {
        ...mapActions(["getUnreadNotifications", "markReadAll", "clearNotifications", "markRead", "getCmsFeatureOrder", "featureEnabled"]),
        removeNotifications(){
            this.markReadAll();
            this.clearNotifications();
        },
        removeNotification(id){
            this.markRead(id);
        },
        goToEnv(id){
            this.$router.push({name:"dashboard_cms", params: {id: id}})
        },
        openQASettings(){
            this.showQASettings = true;
        }
    }, mounted(){
        this.$parent.$parent.view = this.$t('dashboard')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/dashboard.scss";   
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    ::v-deep{
        .card-list > .i-settings{
            width: 16px;
            height: 16px;
            background-image: url('../../../assets/icons/settings.svg');
            display: inline-block;
            transform: translate(10px, 2px);
            cursor: pointer;
        }
    }
</style>