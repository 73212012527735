<template>
  <div class="addon plays" :class="[{ open: open }]">
    <div class="collapsible" @click="open = !open">
      <label>Peça</label>
      <i class="i-collapse" />
    </div>
    <div class="plays-form">
      <fyi-textarea
        v-bind:value="addon[language_index].synopsis"
        v-on:input="
          addon[language_index].synopsis = $event;
          $emit('update-plays', addon);
        "
        :label="'Sinopse'"
        :required="true"
        :toolbar="'full'"
        :key="language_index"
      />
      <InputTags
        v-model="app"
        :tags="addon[language_index].categories.split(',')"
        :label="'Categorias'"
        :disabled="false"
        :icon_box="false"
        :full="true"
        :noUrlRedirect="true"
        @input-tags="
          addon[language_index].categories = $event;
          $emit('update-plays', addon);
        "
      />
      <Time
        :value="addon[language_index].duration"
        @update:modelValue="
          addon[language_index].duration = $event.hours + ':' + $event.minutes;
          $emit('update-plays', addon);
        "
        :label="'Duração'"
      />
      <fyi-textarea
        v-bind:value="addon[language_index].company"
        v-on:input="
          addon[language_index].company = $event;
          $emit('update-plays', addon);
        "
        :label="'Companhia/Artista'"
        :required="true"
        :toolbar="'full'"
        :key="language_index"
      />
      <fyi-textarea
        v-bind:value="addon[language_index].technical_sheet"
        v-on:input="
          addon[language_index].technical_sheet = $event;
          $emit('update-plays', addon);
        "
        :label="'Ficha Técnica'"
        :required="true"
        :toolbar="'full'"
        :key="language_index"
      />
      <fyi-textarea
        v-bind:value="addon[language_index].suggestions"
        v-on:input="
          addon[language_index].suggestions = $event;
          $emit('update-plays', addon);
        "
        :label="'Sugestões'"
        :required="true"
        :toolbar="'full'"
        :key="language_index"
      />
      <fyi-text
        v-bind:value="addon[language_index].video"
        v-on:input-text="
          addon[language_index].video = $event;
          $emit('update-plays', addon);
        "
        :label="'Link VIMEO'"
        :icon_box="false"
        :full="true"
      />
      <fyi-text
        v-bind:value="addon[language_index].trailer_video"
        v-on:input-text="
          addon[language_index].trailer_video = $event;
          $emit('update-plays', addon);
        "
        :label="'Trailer link VIMEO'"
        :icon_box="false"
        :full="true"
      />
      <SimpleSelect
        :label="'Disponibilidade de Conteúdo'"
        @select-data-value="
          addon[language_index].availability = $event;
          $emit('update-plays', addon);
        "
        :previewIcon="'i-order'"
        :options="
          availability.map((p) => {
            return {
              label: p.label,
              value: p.value,
              checked: addon[language_index].availability == p.value,
            };
          })
        "
        :key="addon[language_index].availability"
      />
      <fyi-number
        :label="'Duração da disponibilidade (horas)'"
        v-bind:value="addon[language_index].availability_period"
        v-on:input-number="
          addon[language_index].availability_period = $event;
          $emit('update-plays', addon);
        "
        :alias="''"
        :classe="''"
        :show="true"
        :inputError="false"
      />
      <fyi-number
        :label="'Preço'"
        v-bind:value="addon[language_index].price"
        v-on:input-number="
          addon[language_index].price = $event;
          $emit('update-plays', addon);
        "
        :alias="''"
        :classe="''"
        :show="true"
        :inputError="false"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AddonPlays",
  props: {
    content: {
      type: Object,
      default: null,
    },
    language_index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      open: false,
      availability: [
        {
          label: "Grátis",
          value: "gratis",
          checked: false,
        },
        {
          label: "Payperview",
          value: "payperview",
          checked: false,
        },
        {
          label: "Subscrição",
          value: "subscription",
          checked: false,
        },
      ],
    };
  },
  beforeMount() {
    this.addon = this.content.plays;

    this.$store.getters.languages.forEach((l) => {
      if (
        !this.addon
          .map((a) => {
            return a.language_id;
          })
          .includes(l.id)
      )
        this.addon.push({
          language_id: l.id,
          synopsis: "",
          suggestions: "",
          technical_sheet: "",
          availability: "",
          price: "",
          categories: "",
          video: "",
          trailer_video: "",
          company: "",
          duration: "",
          availability_period: ""
        });
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/layout/addons.scss";
</style>
