<template>
    <div v-if="show && patch.html" class="bg">
        <div class="container">
            <i class='i-close' :onclick="() => { close() }" />
            <div v-html="patch.html" class="body">
            </div>
            <fyi-checkbox alias="remember-notes" :checked="checkbox" @input="checkbox = $event.checked" :label="'Não mostrar novamente até à próxima release'"/>
        </div>

    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name:"PatchNotes",
    data(){
        return{
            show: Boolean(parseInt(localStorage.getItem("showPatch"))) || (!Boolean(parseInt(localStorage.getItem("showPatch"))) && localStorage.getItem("currentPatch") != this.$store.getters.appVersion),
            checkbox: false,
            patch: []
        }
    },
    methods:{
		...mapActions([
			"getPatchNotes"
	  ]),
      close(){
        if(this.checkbox){
            localStorage.setItem("showPatch", 0)
            localStorage.setItem("currentPatch", this.$store.getters.appVersion )
        }else{
            localStorage.setItem("showPatch", 1)
            localStorage.setItem("currentPatch", this.$store.getters.appVersion )
        }
        this.show = false;
      }
    },
    async mounted(){
        this.patch = await this.getPatchNotes()
    }
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/layout/patch";
</style>