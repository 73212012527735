import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import AppGeneral from "./../AppGeneral";
import AppAuth from "./../AppAuth";

import Home from "./../pages/geral/Home";
import Dashboard from "./../pages/geral/DashboardApp";
import DashboardUsers from "./../pages/geral/DashboardUsers";
import Users from "./../pages/geral/Users";
import Logs from "./../pages/geral/Logs";
import Cms from "./../pages/cms/Cms";
import NotFound from "./../pages/geral/NotFound";
import Unauthorized from "./../pages/geral/Unauthorized";
import UnexpectedError from "./../pages/geral/UnexpectedError";

// router
import authRouter from "./modules/authRouter";
import cmsRouter from "./modules/cmsRouter";

// middleware
// import middlewarePipeline from "./middleware/middlewarePipeline";
import lang from "./middleware/lang";
import auth from "./middleware/auth";

const middlewareDefault = [lang];

const router = createRouter({
    history: createWebHistory(),
    routes: [
        // NOT FOUND PAGE
        {
            path: "/admin/cms",
            redirect: '/admin/erro'
        },
        // DASHBOARD
        {
            path: "/admin",
            component: AppGeneral,
            beforeEnter: (to, from, next) => {
                //redirect to dashboard home if user already logged
                if(to.fullPath === "/admin")
                    next({
                        name: "dashboard"
                    })

                next();
            },
            children: [
                {
                    path: "/admin",
                    component: Home,
                    children: [
                        {
                            path: "/admin/dashboard",
                            name: "dashboard",
                            component: Dashboard,
                            meta: {
                                middleware: [auth],
                                title: 'Dashboard'
                            }
                        },
                        {
                            path: "/admin/users",
                            name: "users",
                            component: DashboardUsers,
                            meta: {
                                middleware: [auth],
                                title: 'Utilizadores',
                            }
                        },
                        {
                            path: "/admin/users/:id",
                            name: "users_edit",
                            component: Users,
                            meta: {
                                middleware: [auth],
                                title: 'Editar Utilizador'
                            }
                        },
                        {
                            path: "/admin/users/create",
                            name: "users_create",
                            component: Users,
                            meta: {
                                middleware: [auth],
                                title: 'Criar Utilizador'
                            }
                        },
                        {
                            path: "/admin/profile",
                            name: "profile",
                            component: Users,
                            meta: {
                                middleware: [auth],
                                title: 'Perfil',
                            }
                        },
                        {
                            path: "/admin/logs",
                            name: "logs",
                            component: Logs,
                            meta: {
                                middleware: [auth],
                                title: 'Logs',
                            }
                        },
                    ]
                },
                // CMS
                {
                    path: "/admin/cms",
                    component: Cms,
                    children: cmsRouter
                },

        ]
        },
        // AUTH
        {
            path: "/admin/auth",
            component: AppAuth,
            children: authRouter
        },
         // UNAUTHORIZED PAGE
        {
            path: "/admin/erro",
            component: AppGeneral,
            children: [{
                path: "unauthorized",
                name: "401",
                component: Unauthorized,
                meta: {
                    middleware: [auth],
                    title: '401'
                }
            }]
        },
        // UNEXPECTED ERROR PAGE
        {
            path: "/admin/erro",
            component: AppGeneral,
            children: [{
                path: "",
                name: "erro",
                component: UnexpectedError,
                meta: {
                    title: 'Erro'
                }
            }]
        },
        // NOT FOUND PAGE
        {
            path: "",
            redirect: '/admin/dashboard'
        },
        // NOT FOUND PAGE
        {
            path: "/:pathMatch(.*)*",
            component: AppGeneral,
            children: [{
                path: "",
                name: "404",
                component: NotFound,
                meta: {
                    middleware: [auth],
                    title: '404'
                }
            }]
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    let authRoutes = [
        "register",
        "recover-password",
        "recover-email",
        "otp",
        "password"
    ];
    
    //unset environment of Cms
	if (!to.fullPath.includes("/cms")) {
		store.dispatch("setApp", "backoffice");
		store.dispatch("setCmsApp", null);
	}

    // Set page title
    let title = to.meta && to.meta.title ? to.meta.title : ""
    document.title = 'Backoffice - '+ title + ( store.getters.clientInfo.title !== "Backoffice" ? " " + store.getters.clientInfo.title : "");
    if (process.env.VUE_APP_CLIENT_FAVICON && process.env.VUE_APP_CLIENT_FAVICON !== '') {
        document.getElementById("favicon").href = process.env.VUE_APP_CLIENT_FAVICON
    }

    //set middlewares
    const middlewareArray = to.meta.middleware ? middlewareDefault.concat(to.meta.middleware) : middlewareDefault;

    const middleware = to.meta.middleware;

    //set client in case of client query param
    if(to.query.client) {
        // se for para redirecionar para cliente e for rota de auth
        await store.dispatch("setHeaderDomain", process.env.VUE_APP_API_DOMAIN);
        let domainList = await store.dispatch("clients");

        let keyQuery = domainList.findIndex(d => to.query.client === d.domain || d.domain.includes(to.query.client));
        if(domainList[keyQuery]) {
            await store.dispatch("setHeaderDomain", domainList[keyQuery].domain);

            delete to.query.client;
            to.params.popup = false; 
            next( {
                name: to.name,
                query: to.query,
                params: to.params
            });
        }else{
            next({
                name: 'login',
                params: to.params
            });
        }
    }else {
        //in every route that isnt login set the domain and init node
        if (!authRoutes.includes(to.name) && to.name !== "login") {
            let clientInfo = store.getters.clientInfo;
            let domain = process.env.VUE_APP_API_DOMAIN;

            if (store.getters.headerDomain) {
                domain =  store.getters.headerDomain;
            } else if (process.env.VUE_APP_API_DYNAMIC === "true") {
                domain =  window.location.host;
            }
                
            if (clientInfo.node && domain && store.getters.app && store.getters.identity) {
                store.dispatch("initSocket", {
                    node: clientInfo.node,
                    namespace: domain,
                    token: store.getters.app,
                    identity: store.getters.identity
                });
            }
        }

        //Middleware handling
        if (middlewareArray.length === 0) 
            next();
        else if (middleware) {
            let res = [];
            for (const i in middleware) {
                res.push(await middleware[i]({store, to}))
            }
            if(res.filter( r => r == true).length == res.length){
                next();
            }else{
                next(res.filter( r => r != true)[0]);
            }
        }else{
            next();
        }
    }
});


export default router;