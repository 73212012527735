<template>
  <div class="table-container" ref="table_container">
    <div v-if="showLayout" class="layout">
      <i @click="setLayout('grid')" :class="['i-grid', {'active': layoutView == 'grid'}]" />
      <i @click="setLayout('lines')" :class="['i-table', {'active': layoutView == 'lines'}]" />
    </div>
    <div class="filters" v-if="showFilters">
      <ComplexSelect v-show="availableFilters.length > 0 || availableStates.length > 0 || availableFields.length > 0" ref="filter" :className="'filter'" :previewLabel="$t('filter_by')" :optionsLabel="$t('location')" :options="availableFilters" :fieldsLabel="'Pesquisa Avançada'" :fields="availableFields" :checkboxMulti="[false]" :checkboxLabels="[ (availableFilters.length == 0 ? $t('filter_by') : $t('state'))]" :checkboxes="[availableStates]" 
      :buttonsLabels="[$t('restore'), $t('apply_filters')]"
      @apply="$emit('apply', $event)" @rendered="$emit('filter-rendered')"/>
      <ComplexSelect v-show="availableOrders.length > 0" ref="order" :previewIcon="'i-order'" :className="'order'" :checkboxes="[availableOrders, availableDirections]" :previewLabel="$t('order_by')" :checkboxMulti="[false,false]" :checkboxLabels="[$t('order_by'), $t('order_type')]"
      :buttonsLabels="['Default', $t('order')]"
      @apply="$emit('apply-order', $event)"/>
      <Search
        @done-search="$emit('search', $event)"
        :placeholder="$t('fast_search')"
      />
      <div v-if="optionButtons.length > 0" class="option-buttons">
        <fyi-button v-for="(bt,k) in optionButtons" :key="k"
          :label="bt.label"
          :icon="bt.icon"
          :className="'default '+ bt.className"
          :onclick="() => bt.func()"
        />
      </div>
      <ContextMenu v-if="contextMenu.length > 0" :options="contextMenu"/>
    </div>
    <label v-if="showTotal" class="total">{{ items.total }} <span>{{$t('results')}}</span></label>
    <div v-if="showEntries" class="entries">
      <label>{{$t('entries')}}</label>
      <SimpleSelect @select="$emit('entry-select', $event)" :previewIcon="'i-order'" :options="availableEntries"/>
    </div>
    <div ref="template_table" v-if="(!loading && items.total > 0) || (tableClass == 'related' && !loading) || tableClass == 'templates' || tableClass == 'type-related'" :class="'template-table ' + layoutView + ' ' + tableClass" @scroll="scrollEvent">
      <template v-if="tableClass == 'related'">
        <div class="table-header">
          <label>{{$t('Ordem')}}</label>
          <label>{{$t('Localização')}}</label>
          <label>{{$t('Elemento')}}</label>
        </div>
        <div :class="['table-row']" v-for="(item, k) in items.data" :key="item.id">
          <div class="mobile-info">
            <label>{{$t('Ordenar')}}</label>
            <i class="i-up" :class="[{'disabled': (k == 0)}]" @click="$emit('addon-order-related', {newOrder: k, item: item, oldOrder: k+1})"/>
            <i class="i-down" :class="[{'disabled': (k == items.data.length - 1)}]" @click="$emit('addon-order-related', {newOrder: k+2, item: item, oldOrder: k+1})"/>
            <i class="i-remove"  @click="$emit('remove', item.id)"/>
          </div>
          <SimpleSelect :className="tableOrder" @select="$emit('addon-order-related', {newOrder: parseInt($event), item: item, oldOrder: k+1})" :previewIcon="'i-order'" :options="attachmentsOrder(item, items.data.length, k)"/>
          <label class="mobile-info">{{$t('Localização')}}</label>
          <SimpleSelect @select-value="item.contentRelatedType = $event" :allowSearch="true" :previewIcon="'i-collapse white'" :options="relatedItems(item)"/>
          <label class="mobile-info">{{$t('Elemento')}}</label>
          <SimpleSelect @select-value="item.content_related_id = $event" :allowSearch="true" :previewIcon="'i-collapse grey'" :options="relatedItemsContent(item)"/>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
        </div>
        <div :class="['table-row']">
          <div class="mobile-info">
            <label>{{$t('Adicionar')}}</label>
            <i class="i-add" @click="$emit('add', addRel); addRel = {}"/>
          </div>  
          <span></span>
          <label class="mobile-info">{{$t('Localização')}}</label>
          <SimpleSelect @select-value="addRel.contentRelatedType = $event" :allowSearch="true" :previewIcon="'i-collapse grey'" :options="relatedItems(addRel)"/>
          <label class="mobile-info">{{$t('Elemento')}}</label>
          <SimpleSelect @select-value="addRel.content_related_id = $event" :allowSearch="true" :previewIcon="'i-collapse grey'" :options="relatedItemsContent(addRel)"/>
          <label class="add" :class="{'disabled': addDisabled}" @click="$emit('add', addRel); addRel = {}">{{$t('add')}}</label>
        </div>
      </template>
      <template v-else-if="tableClass == 'templates'">
        <div class="table-header">
          <label>{{$t('Ordem')}}</label>
          <label>{{$t('Template')}}</label>
          <label>{{$t('Posição')}}</label>
          <label>{{$t('Plugin')}}</label>
        </div>
        <div :class="['table-row']" v-for="(item, k) in items.data" :key="item.id">
          <div class="mobile-info">
            <label>{{$t('Ordenar')}}</label>
            <fyi-number
              v-bind:value="item.orderposition"
              v-on:input-number="item.orderposition = $event"
              :alias="'order_position'"
              :classe="'order_position'"
              :show="true"
              :placeholder="''"
              :disabled="false"
              :required="true"
              :inputError="false"
            />
          </div>
          <fyi-number
            v-bind:value="item.orderposition"
            v-on:input-number="item.orderposition = $event"
            :alias="'order_position'"
            :classe="'order_position'"
            :show="true"
            :placeholder="''"
            :disabled="false"
            :required="true"
            :inputError="false"
          />
          <label class="mobile-info">{{$t('Template')}}</label>
          <SimpleSelect @select-value="item.contentRelatedType = $event" :previewIcon="'i-collapse white'" :options="relatedItems(item)"/>
          <label class="mobile-info">{{$t('Posição')}}</label>
          <SimpleSelect @select="item.content_related_id = $event" :previewIcon="'i-collapse grey'" :options="relatedItemsContent(item)"/>
          <fyi-text
            v-bind:value="item.plugin"
            v-on:input-text="item.plugin = $event"
            :class="'plugin'"
            :icon_box="false" :full="true"
          />
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
        </div>
        <div :class="['table-row']">
          <div class="mobile-info">
            <label>{{$t('Adicionar')}}</label>
            <i class="i-add" @click="$emit('add', addRel); addRel = {}"/>
          </div>  
          <fyi-number
            v-bind:value="addRel.orderposition"
            v-on:input-number="addRel.orderposition = $event"
            :alias="'order_position'"
            :classe="'order_position'"
            :show="true"
            :placeholder="''"
            :disabled="false"
            :required="true"
            :inputError="false"
          />
          <label class="mobile-info">{{$t('Localização')}}</label>
          <SimpleSelect @select-value="addRel.contentRelatedType = $event" :previewIcon="'i-collapse grey'" :options="relatedItems(addRel)"/>
          <label class="mobile-info">{{$t('Elemento')}}</label>
          <SimpleSelect @select="addRel.content_related_id = $event" :previewIcon="'i-collapse grey'" :options="relatedItemsContent(addRel)"/>
          <fyi-text
            v-bind:value="addRel.plugin"
            v-on:input-text="addRel.plugin = $event"
            :class="'plugin'"
            :icon_box="false" :full="true"
          />
          <label class="add" :class="{'disabled': addDisabled}" @click="$emit('add', addRel); addRel = {}">{{$t('add')}}</label>
        </div>
      </template>
      <template v-else-if="tableClass == 'attachments'">
        <div class="table-header">
          <label>{{$t('Ordem')}}</label>
          <label>{{$t('Preview')}}</label>
          <label>{{$t('name')}}</label>
        </div>
        <div :class="['table-row']" v-for="(item, k) in items.data.filter( a => a.language_id == undefined || a.language_id === language)" :key="item.id" :category-id="item.category_id">
          <label class="mobile-info">{{$t('Ordem')}}</label>
          <SimpleSelect :className="tableOrder" @select="$emit('addon-order', {newOrder: $event, item: item})" :previewIcon="'i-order'" :options="attachmentsOrder(item, items.data.filter( a => a.language_id == undefined || a.language_id === language).length)"/>
          <div class="bgImg" v-if="item.mimetype !=undefined && item.mimetype.includes('image')" :style="setBackgroundImage((item.file.includes('http') ? item.file : $store.getters.appConfig.url + item.file))" ></div>
          <i v-else :class="setIconExtension(item, true)" />
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.name }}</label>
          <label class="edit" @click="$emit('edit', item)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', k)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item)}},
            {label:$t('remove'), func: function(){$emit('remove', k)}}
          ]"/>
          <i class="far fa-eye" @click="$emit('preview', item)"></i>
        </div>
      </template>
      <template v-else-if="tableClass == 'vision'">
        <div class="table-header">
          <label>{{$t('Preview')}}</label>
          <label>{{$t('name')}}</label>
        </div>
        <div :class="['table-row']" v-for="(item, k) in items.data" :key="item.id" :category-id="item.category_id">
          <div class="bgImg" :style="setBackgroundImage((item.image.includes('http') ? item.image : $store.getters.appConfig.url + item.image))" ></div>
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.image }}</label>
          <label class="remove" @click="$emit('remove', k)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('remove'), func: function(){$emit('remove', k)}}
          ]"/>
          <i class="far fa-eye" @click="$emit('preview', item)"></i>
        </div>
      </template>
      <template v-else-if="tableClass == 'users'">
        <div class="table-header">
          <fyi-checkbox v-if="multiSelect"
            :value="allSelected"
            :isChecked="allSelected"
            v-on:input="allSelected = $event.checked"/>
          <label v-else />
          <label></label>
          <label>{{$t('Utilizador')}}</label>
          <label>{{$t('Email')}}</label>
          <label>{{$t('Role')}}</label>
          <label>{{$t('Status')}}</label>
          <label>{{$t('Atualizado')}}</label>
        </div>
        <div :class="['table-row']" v-for="(item, k) in items.data" :key="item.id" :data-item-id="item.id">
          <fyi-checkbox    
            :value="selected[item.id]"  
            :isChecked="selected[item.id]"
            v-on:input="setSelected($event, item.id)"/>
          <keep-alive>
            <img v-if="item.info && item.info.photo !=null" class="avatar" :src="item.info.photo">
            <div v-else :style="'background: #'+ color(item)" class="avatar">
                <span>{{ pseudo_name(item.name) }}</span>
            </div>
          </keep-alive>
          <label>{{ item.name }}</label>
          <label>{{ item.email }}</label>
          <label class="role">{{ item.roles.length != 0 ? item.roles[0].name : '' }}</label>
          <label :class="'status ' + (item.state.id == 1? 'active' : 'pending')">{{item.state.id == 1 ? 'Ativo' : 'Pendente'}}</label>
          <label>{{
            date_formatted(item.created_at)
          }}</label>
          <!-- <Switch
            @click="$emit('toggleSwitch', { toggle: $event, item: item.id })"
            :enabled="item.backoffice_access"
            :disabled="true"
          /> -->
          <i v-if="item.state.id != 1" class="i-letter" @click="$emit('resend', item.id)"></i>
          <span v-else />
          <label class="edit" @click="$emit('edit', item.id)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item.id)}},
            {label:$t('remove'), func: function(){$emit('remove', item.id)}}
          ]"/>
        </div>
      </template>
      <template v-else-if="tableClass == 'block'">
        <div class="table-header">
          <label class="sortable" @click="sort">{{$t('location')}}</label>
          <label class="sortable" @click="sort">{{$t('name')}}</label>
          <label class="sortable" @click="sort">{{$t('type')}}</label>
          <label class="sortable" @click="sort">{{$t('update')}}</label>
          <label class="sortable" @click="sort">{{$t('creation')}}</label>
          <label>{{$t('state')}}</label>
        </div>
        <div class="table-row" v-for="(item, k) in items.data" :key="item.id">
          <label class="mobile-info">{{$t('location')}}</label>
          <label class="location" @click="''">{{
            showBlockLocation(item.pages)
          }}</label>
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.name }}</label>
          <label>{{ item.type }}</label>
          <label>{{
            date_formatted(item.updated_at)
          }}</label>
          <label>{{
            date_formatted(item.created_at)
          }}</label>
          <Switch
            @click="$emit('toggleSwitch', { toggle: $event, item: item.id })"
            :enabled="item.published == 1"
          />
          <label class="edit" @click="$emit('edit', item.id)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item.id)}},
            {label:$t('remove'), func: function(){$emit('remove', k)}}
          ]"/>
        </div>
      </template>
      <template v-else-if="tableClass == 'menus'">
        <div class="table-header">
          <label class="sortable" @click="sort">{{$t('location')}}</label>
          <label class="sortable" @click="sort">{{$t('name')}}</label>
          <label class="sortable" @click="sort">{{$t('update')}}</label>
          <label class="sortable" @click="sort">{{$t('creation')}}</label>
          <label>{{$t('state')}}</label>
        </div>
        <div class="table-row" v-for="(item, k) in items.data" :key="item.id">
          <label class="mobile-info">{{$t('location')}}</label>
          <label class="location" @click="''">{{
            showBlockLocation(item.pages)
          }}</label>
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.name }}</label>
          <label>{{
            date_formatted(item.updated_at)
          }}</label>
          <label>{{
            date_formatted(item.created_at)
          }}</label>
          <Switch
            @click="$emit('toggleSwitch', { toggle: $event, item: item.id })"
            :enabled="item.published == 1"
          />
          <label class="edit" @click="$emit('edit', item.id)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item.id)}},
            {label:$t('remove'), func: function(){$emit('remove', k)}}
          ]"/>
        </div>
      </template>
      <template v-else-if="tableClass == 'template-pages'">
        <div class="table-header">
          <label class="sortable" @click="sort">{{$t('name')}}</label>
          <label class="sortable" @click="sort">{{$t('update')}}</label>
          <label class="sortable" @click="sort">{{$t('creation')}}</label>
        </div>
        <div class="table-row" v-for="(item, k) in items.data" :key="item.id">
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.name ? item.name : item.languages[0].name }}</label>
          <label>{{
            date_formatted(item.updated_at)
          }}</label>
          <label>{{
            date_formatted(item.created_at)
          }}</label>
          <label class="edit" @click="$emit('edit', item.id)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item.id)}},
            {label:$t('remove'), func: function(){$emit('remove', k)}}
          ]"/>
        </div>
      </template>
      <template v-else-if="tableClass == 'type-related'">
        <div class="table-header">
          <label>{{$t('Tipo')}}</label>
          <label>{{$t('Obrigatório')}}</label>
          <label>{{$t('Limite de Conteúdos')}}</label>
        </div>
        <div :class="['table-row']" v-for="(item, k) in items.data" :key="item.id">
          <label class="mobile-info">{{$t('Tipo')}}</label>
          <SimpleSelect @select-value="item.type_related_id = $event" :previewIcon="'i-collapse white'" :options="typeRelatedItems(item)"/>
          <label class="mobile-info">{{$t('Obrigatório')}}</label>
          <SimpleSelect @select="item.required = $event" :previewIcon="'i-collapse grey'" :options="typeRelatedRequired(item)"/>
          <label class="mobile-info">{{$t('Limite de Conteúdos')}}</label>
          <fyi-number
            v-bind:value="item.contents_limit"
            v-on:input-number="item.contents_limit = $event"
            :classe="'limit'"
            :icon_box="false" :full="true"
          />
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
        </div>
        <div :class="['table-row']">
          <div class="mobile-info">
            <label>{{$t('Adicionar')}}</label>
            <i class="i-add" @click="$emit('add', addRel); addRel = {}"/>
          </div>  
          <span></span>
          <label class="mobile-info">{{$t('Tipo')}}</label>
          <SimpleSelect @select-value="addRel.type_related_id = $event" :previewIcon="'i-collapse grey'" :options="typeRelatedItems(addRel)"/>
          <label class="mobile-info">{{$t('Obrigatório')}}</label>
          <SimpleSelect @select="addRel.required = $event" :previewIcon="'i-collapse grey'" :options="typeRelatedRequired(addRel)"/>
          <label class="mobile-info">{{$t('Limite de Conteúdos')}}</label>
          <fyi-number
            v-bind:value="addRel.contents_limit"
            v-on:input-number="addRel.contents_limit = $event"
            :classe="'limit'"
            :icon_box="false" :full="true"
          />
          <label class="add" :class="{'disabled': addDisabled}" @click="$emit('add', addRel); addRel = {}">{{$t('add')}}</label>
        </div>
      </template>
      <template v-else-if="tableClass == 'extra-fields'">
        <div class="table-header">
          <label class="sortable" @click="sort">{{$t('name')}}</label>
          <label class="sortable" @click="sort">{{$t('type')}}</label>
          <label class="sortable" @click="sort">{{$t('update')}}</label>
          <label class="sortable" @click="sort">{{$t('creation')}}</label>
        </div>
        <div class="table-row" v-for="(item, k) in items.data" :key="item.id">
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.languages[0].label }}</label>
          <label class="mobile-info">{{$t('type')}}</label>
          <label>{{ item.type }}</label>
          <label>{{
            date_formatted(item.updated_at)
          }}</label>
          <label>{{
            date_formatted(item.created_at)
          }}</label>
          <label class="edit" @click="$emit('edit', item.id)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item.id)}},
            {label:$t('remove'), func: function(){$emit('remove', k)}}
          ]"/>
        </div>
      </template>
      <template v-else-if="tableClass == 'qa'">
        <div class="table-header">
          <label>{{$t('Ordem')}}</label>
          <label>{{$t('item')}}</label>
          <label>{{$t('icon')}}</label>
        </div>
        <div :class="['table-row']" v-for="(item, k) in items.data" :key="item.id">
          <div class="mobile-info">
            <label>{{$t('Ordenar')}}</label>
            <i class="i-up" :class="[{'disabled': (k == 0)}]" @click="$emit('addon-order-related', {newOrder: k, item: item, oldOrder: k+1})"/>
            <i class="i-down" :class="[{'disabled': (k == items.data.length - 1)}]" @click="$emit('addon-order-related', {newOrder: k+2, item: item, oldOrder: k+1})"/>
            <i class="i-remove"  @click="$emit('remove', item.id)"/>
          </div>
          <SimpleSelect :className="tableOrder" @select="$emit('order-qa', {newOrder: parseInt($event), item: item, oldOrder: k+1})" :previewIcon="'i-order'" :options="attachmentsOrder(item, items.data.length, k)"/>
          <label class="mobile-info">{{$t('item')}}</label>
          <SimpleSelect @select-value="item.route = $event" :allowSearch="true" :previewIcon="'i-collapse grey'" :options="items2.filter( i => !items.data.map( it => it.route ).includes(i.value) || item.route == i.value).map( i => { return { ...i, checked: item.route == i.value}})"/>
          <label class="mobile-info">{{$t('icon')}}</label>
          <SimpleSelect @select-value="item.icon = $event" :allowSearch="false" :noLabel="true" :previewIcon="'i-collapse grey'" :options="items3.map( i => { return { ...i, checked: item.icon == i.value}})"/>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
        </div>
        <div :class="['table-row']">
          <div class="mobile-info">
            <label>{{$t('Adicionar')}}</label>
            <i class="i-add" @click="$emit('add', addRel); addRel = {}"/>
          </div>  
          <span></span>
          <label class="mobile-info">{{$t('item')}}</label>
          <SimpleSelect @select-data-value="addRel.route = $event" :allowSearch="true" :previewIcon="'i-collapse grey'" :options="items2.filter( i => !items.data.map( it => it.route ).includes(i.value))" :key="addRel"/>
          <label class="mobile-info">{{$t('icon')}}</label>
          <SimpleSelect @select-data-value="addRel.icon = $event" :allowSearch="false" :noLabel="true" :previewIcon="'i-collapse grey'" :options="items3" :key="addRel"/>
          <label class="add" :class="{'disabled': addDisabled}" @click="$emit('add', addRel); addRel = {}">{{$t('add')}}</label>
        </div>
      </template>
      <template v-else-if="tableClass == 'logs'">
        <div class="table-header">
          <label class="sortable" @click="sort">{{$t('id')}}</label>
          <label class="sortable" @click="sort">{{$t('status')}}</label>
          <label class="sortable" @click="sort">{{$t('action')}}</label>
          <label class="sortable" @click="sort">{{$t('request')}}</label>
          <label class="sortable" @click="sort">{{$t('ip')}}</label>
          <label class="sortable" @click="sort">{{$t('user')}}</label>
          <label class="sortable" @click="sort">{{$t('timestamp')}}</label>
        </div>
        <div class="table-row" v-for="(item, k) in items.data" :key="item.id">
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.id }}</label>
          <label>{{ item.status }}</label>
          <label>{{ item.action }}</label>
          <label>{{ item.request }}</label>
          <label>{{ item.ip }}</label>
          <label>{{ item.user_id }}</label>
          <label>{{
            new Date(item.timestamp).toLocaleDateString("pt-PT", {
              format: "short",
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit"
            })
          }}</label>
        </div>
      </template>
      <template v-else-if="items.data && items.data[0].languages != null">
        <div class="table-header" :style="customTableStyle">
          <label class="sortable" @click="sort">{{$t('location')}}</label>
          <label class="sortable" @click="sort">{{$t('name')}}</label>
          <label v-for="c in customColumns" class="sortable" @click="sort">{{c.label}}</label>
          <label class="sortable" @click="sort">{{$t('update')}}</label>
          <label class="sortable" @click="sort">{{$t('creation')}}</label>
          <label>{{$t('state')}}</label>
        </div>
        <div class="table-row" v-for="(item, k) in items.data" :key="item.id" :style="customTableStyle">
          <label class="mobile-info">{{$t('location')}}</label>
          <label class="location" @click="$emit('filter-type', item.type.id)">{{
            item.type.languages[0].name
          }}</label>
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.languages.length > 0 ? item.languages[0].name  : "" }}</label>
          <label v-for="c in customColumns" @click="sort">{{customField(c.field, item)}}</label>
          <label>{{
            date_formatted(item.updated_at)
          }}</label>
          <label>{{
            date_formatted(item.created_at)
          }}</label>
          <Switch
            @click="$emit('toggleSwitch', { toggle: $event, item: item.id })"
            :enabled="item.published == 1"
          />
          <label class="edit" @click="$emit('edit', item.id)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item.id)}},
            {label:$t('remove'), func: function(){$emit('remove', k)}}
          ]"/>
          <i class="far fa-eye" :title="item.type.templates.length == 0 ? 'Este conteúdo não contem preview.' : ''" :class="[{'disabled': item.type.templates.length == 0}]" @click="item.type.templates.length != 0 ? $emit('preview', item) : () => {}"></i>
        </div>
      </template>
      <template v-else>
        <div class="table-header">
            <fyi-checkbox v-if="multiSelect" 
            :value="allSelected"  
            :isChecked="allSelected"
            v-on:input="allSelected = $event.checked"/>
            <label v-else />
          <label>{{$t('Preview')}}</label>
          <label>{{$t('location')}}</label>
          <label>{{$t('name')}}</label>
        </div>
        <div :class="['table-row', {'selected': selected[item.id]}]" v-for="(item, k) in items.data" :key="item.id" :data-item-id="item.id" @click=" ($event) => {if(layoutView == 'grid' || checkMobile()) setSelected({checked: !$event.target.classList.contains('selected'), shiftKey:$event.shiftKey, el: $event.target}, item.id)}">
          <fyi-checkbox    
            :value="selected[item.id]"  
            :isChecked="selected[item.id]"
            v-on:input="setSelected($event, item.id)"/>
            <div class="bgImg" v-if="item.mime.includes('image')" :style="setBackgroundImage($store.getters.appConfig.url + item.path)" ></div>
            <i v-else :class="setIconExtension(item)" />
          <label class="mobile-info">{{$t('location')}}</label>
          <label class="location">
            <span v-for="(i, k) in itemLocations(item)" :key="k" @click="$emit('filter-type', i.id)">{{i.name}}</span>
          </label>
          <label class="mobile-info">{{$t('name')}}</label>
          <label>{{ item.name }}</label>
          <label class="edit" @click="$emit('edit', item.id)">{{$t('edit')}}</label>
          <label class="remove" @click="$emit('remove', item.id)">{{$t('remove')}}</label>
          <ContextMenu class="mobile-info" :icon="'i-settings'" :options="[
            {label:$t('edit'), func: function(){$emit('edit', item.id)}},
            {label:$t('remove'), func: function(){$emit('remove', item.id)}}
          ]"/>
          <i class="far fa-eye" @click="$emit('preview', item)"></i>
        </div>
      </template>
    </div>
    <div
      v-if="loading"
      :class="['loading-animation', { 'table-load': !paginationLoading }]"
    ></div>
    <Pagination
      v-if="!paginationLoading && (items.total > 0) && layoutView == 'lines' && pagination"
      :className="loading ? 'no-table' : ''"
      :totalPages="Math.ceil(items.total / limit)"
      :perPage="limit"
      :currentPage="currentPage"
      :maxVisibleButtons="setMaxVisibleButtons(items, limit)"
      @pagechanged="onPageChange"
      @go-to="$emit('go-to', $event)"
    />
  </div>
</template>

<script>
import { forEach, forIn } from 'lodash-es';
export default {
  name: "Table",
  data() {
    return {
      currentPage: 1,
      spamPrevent: null,
      selected: [],
      allSelected: false,
      layoutView: 'lines',
      scrollCooldown: false,
      addRel: {}
    };
  },
  props: {
    pagination: {
      type: Boolean,
      default: true,
    },
    tableClass: {
      type: String,
      default: ''
    },
    tableOrder: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 9,
    },
    items: {
      type: Object,
      default: {},
    },
    items2: {
      type: Object,
      default: [],
    },
    items3: {
      type: Object,
      default: [],
    },
    availableFilters: {
      type: Array,
      default: [],
    },
    availableFields: {
      type: Array,
      default: [],
    },
    availableStates: {
      type: Array,
      default: [],
    },
    availableOrders: {
      type: Array,
      default: [],
    },
    availableDirections: {
      type: Array,
      default: [],
    },
    availableEntries: {
      type: Array,
      default: [],
    },
    contextMenu: {
      type: Array,
      default: [],
    },
    loading: {
      Type: Boolean,
      default: false,
    },
    paginationLoading: {
      Type: Boolean,
      default: false,
    },
    showEntries: {
      Type: Boolean,
      default: true,
    },
    showTotal: {
      Type: Boolean,
      default: true,
    },
    showLayout: {
      type: Boolean,
      default: false
    },
    multiSelect: {
      Type: Boolean,
      default: true,
    },
    optionButtons: {
      Type: Array,
      default: []
    },
    showFilters: {
      Type: Boolean,
      default: true
    },
    addDisabled: {
      Type: Boolean,
      default: false
    },
    language_index: {
        type: Number,
        default: 0
    },
    language: {
        type: Number,
        default: 1
    },
    customColumns: {
      type: Array,
      default: []
    }
  },
  watch: {
    loading(_new) {
      if (_new) {
        const _this = this;
        setTimeout(() => {
          _this.$refs.table_container.querySelector(
            ".loading-animation"
          ).innerHTML = "";
          bodymovin.loadAnimation({
            container:
              _this.$refs.table_container.querySelector(".loading-animation"),
            animationData: {
              v: "5.5.2",
              fr: 29.9700012207031,
              ip: 0,
              op: 30.0000012219251,
              w: 2134,
              h: 2134,
              nm: "Layer 2",
              ddd: 0,
              assets: [],
              layers: [
                {
                  ddd: 0,
                  ind: 2,
                  ty: 4,
                  nm: "1 to 4",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.667, y: 1 },
                          o: { x: 0.167, y: 0.167 },
                          t: 0,
                          s: [766, 536, 0],
                          to: [689.667, -416.667, 0],
                          ti: [66.333, -391.333, 0],
                        },
                        { t: 30.0000012219251, s: [1823, 1069, 0] },
                      ],
                      ix: 2,
                    },
                    a: { a: 0, k: [756, 2, 0], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                          o: {
                            x: [0.167, 0.167, 0.167],
                            y: [0.167, 0.167, -10.997],
                          },
                          t: 0,
                          s: [291, 291, 100],
                        },
                        { t: 30.0000012219251, s: [100, 100, 100] },
                      ],
                      ix: 6,
                    },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          d: 1,
                          ty: "el",
                          s: { a: 0, k: [146.453, 146.453], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          nm: "Ellipse Path 1",
                          mn: "ADBE Vector Shape - Ellipse",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [
                              0.22745098039215686, 0.7176470588235294,
                              0.8313725490196079, 1,
                            ],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [
                              0.22745098039215686, 0.7176470588235294,
                              0.8313725490196079, 1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [756.062, 1.645], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [102.889, 102.889], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Ellipse 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 300.00001221925,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 3,
                  ty: 4,
                  nm: "1 to 3",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.667, y: 1 },
                          o: { x: 0.167, y: 0.167 },
                          t: 0,
                          s: [792, 1535, 0],
                          to: [-450.907, -496.349, 0],
                          ti: [4.167, 0, 0],
                        },
                        { t: 30.0000012219251, s: [766, 536, 0] },
                      ],
                      ix: 2,
                    },
                    a: { a: 0, k: [756, 2, 0], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                          o: {
                            x: [0.167, 0.167, 0.167],
                            y: [0.167, 0.167, 0.099],
                          },
                          t: 0,
                          s: [401, 401, 100],
                        },
                        { t: 30.0000012219251, s: [291, 291, 100] },
                      ],
                      ix: 6,
                    },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          d: 1,
                          ty: "el",
                          s: { a: 0, k: [146.453, 146.453], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          nm: "Ellipse Path 1",
                          mn: "ADBE Vector Shape - Ellipse",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [
                              0.22745098039215686, 0.7176470588235294,
                              0.8313725490196079, 1,
                            ],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [
                              0.22745098039215686, 0.7176470588235294,
                              0.8313725490196079, 1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [756.062, 1.645], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [102.889, 102.889], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Ellipse 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 300.00001221925,
                  st: 0,
                  bm: 0,
                },
                {
                  ddd: 0,
                  ind: 4,
                  ty: 4,
                  nm: "1 to 2",
                  sr: 1,
                  ks: {
                    o: { a: 0, k: 100, ix: 11 },
                    r: { a: 0, k: 0, ix: 10 },
                    p: {
                      a: 1,
                      k: [
                        {
                          i: { x: 0.667, y: 1 },
                          o: { x: 0.167, y: 0.167 },
                          t: 0,
                          s: [1823, 1069, 0],
                          to: [11.333, 260.667, 0],
                          ti: [656.667, 363.333, 0],
                        },
                        { t: 30.0000012219251, s: [792, 1535, 0] },
                      ],
                      ix: 2,
                    },
                    a: { a: 0, k: [756, 2, 0], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                          o: {
                            x: [0.167, 0.167, 0.167],
                            y: [0.167, 0.167, -0.041],
                          },
                          t: 0,
                          s: [100, 100, 100],
                        },
                        { t: 30.0000012219251, s: [401, 401, 100] },
                      ],
                      ix: 6,
                    },
                  },
                  ao: 0,
                  shapes: [
                    {
                      ty: "gr",
                      it: [
                        {
                          d: 1,
                          ty: "el",
                          s: { a: 0, k: [146.453, 146.453], ix: 2 },
                          p: { a: 0, k: [0, 0], ix: 3 },
                          nm: "Ellipse Path 1",
                          mn: "ADBE Vector Shape - Ellipse",
                          hd: false,
                        },
                        {
                          ty: "st",
                          c: {
                            a: 0,
                            k: [
                              0.22745098039215686, 0.7176470588235294,
                              0.8313725490196079, 1,
                            ],
                            ix: 3,
                          },
                          o: { a: 0, k: 100, ix: 4 },
                          w: { a: 0, k: 0, ix: 5 },
                          lc: 1,
                          lj: 1,
                          ml: 4,
                          bm: 0,
                          nm: "Stroke 1",
                          mn: "ADBE Vector Graphic - Stroke",
                          hd: false,
                        },
                        {
                          ty: "fl",
                          c: {
                            a: 0,
                            k: [
                              0.22745098039215686, 0.7176470588235294,
                              0.8313725490196079, 1,
                            ],
                            ix: 4,
                          },
                          o: { a: 0, k: 100, ix: 5 },
                          r: 1,
                          bm: 0,
                          nm: "Fill 1",
                          mn: "ADBE Vector Graphic - Fill",
                          hd: false,
                        },
                        {
                          ty: "tr",
                          p: { a: 0, k: [756.062, 1.645], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [102.889, 102.889], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 },
                          nm: "Transform",
                        },
                      ],
                      nm: "Ellipse 1",
                      np: 3,
                      cix: 2,
                      bm: 0,
                      ix: 1,
                      mn: "ADBE Vector Group",
                      hd: false,
                    },
                  ],
                  ip: 0,
                  op: 300.00001221925,
                  st: 0,
                  bm: 0,
                },
              ],
              markers: [],
            },
            renderer: "svg",
            loop: true,
            autoplay: true,
          });
        }, 100);
      }
    },
    paginationLoading() {
      this.currentPage = 1;
    },
    items(){
      let selected = []

      if(this.items.data)
        this.items.data.forEach( i => {
          selected[i.id] = false
        })

      this.selected = selected
    },
    selected:{
      handler: function(_new, _old){
        this.$emit('checkboxSelected', _new)
      },
      deep: true
    },
    allSelected(_new, _old){
      let selected = []
      this.items.data.forEach( i => {
        selected[i.id] = _new
      })
      this.selected = selected
    },
    layoutView(){
      this.$emit("layoutChange", this.layoutView);
    }
  },
  computed: {
    customTableStyle(){
      let style='';
      this.customColumns.forEach( c => {
        if(c.width != null && c.width != undefined && c.width != '')
          style += c.width + ' '
        else
          style += 'auto '
      })
      return 'grid-template-columns: 200px auto '+style+'110px 110px 70px 70px 70px 70px;'
    }
  },
  methods: {
    color(item){
      if(!item.color)
        item.color = (Math.random()*0xFFFFFF<<0).toString(16)
        
      return item.color
    },
    pseudo_name(name){
        if(name == undefined)
            return ''
            
        let splt = name.split(" ");
        return splt[0][0].toUpperCase() + (splt[splt.length-1] != undefined ? splt[splt.length-1][0].toUpperCase() : '')
    },
    setBackgroundImage(url){
      return 'background-image: url("' + url + '")'
    },
    date_formatted(d){
      if(d != null)
            return new Date(d).toLocaleDateString("pt-PT", {
              format: "short",
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
            })

      return 'N/A'
    },
    customField(field, item){
      if(field == undefined)
        return ''
      
      let fields = field.split(".");
      if(fields[0] == 'languages'){
        return item.languages[this.language_index][fields[1]]
      }else if(fields[0].includes('field')){
        let f = fields[0].split("_");
        let val = ''
        if(!isNaN(f[1])){
          item.fields.forEach( fi => {
            if(fi.id == parseInt(f[1]) && fi.pivot.language_id == this.language){
              val = fi.pivot.value
            }
          })
          return val
        }
      }

      return ''
    },
    resetPage(page){
      this.currentPage = page;
    },
    onPageChange(page) {
      this.currentPage = page;
      clearTimeout(this.spamPrevent);
      this.spamPrevent = setTimeout(() => {
        this.$emit("handlePagination", page);
      }, 500);
    },
    setSelected(event, id){
      let selected = []

      if(!this.multiSelect){
        this.items.data.forEach( i => {
          if(i.id == id)
            selected[i.id] = !this.selected[i.id]
          else
            selected[i.id] = false
        })
        this.selected = selected
      }else{
        //if clicked with shift select all contents between shift selected element and first element selected (checks in reverse order)
        if(event.shiftKey){
          selected = this.selected
          let target = event.el;

          while(!target.classList.contains('table-row')){
            target = target.parentElement
          }

          while(target.previousElementSibling && !target.previousElementSibling.classList.contains('selected')){
            selected[target.dataset.itemId] = true
            target = target.previousElementSibling;
          }

          selected[target.dataset.itemId] = true

          this.selected = selected
        }else if(this.layoutView == "grid"){
          let target = event.el;
          while(!target.classList.contains('table-row')){
            target = target.parentElement
          }
          this.selected[id] = !target.classList.contains('selected');
        }else{
          this.selected[id] = event.checked
        }
      }
    },
    itemLocations(item){
      let locations = [], ids = [];
      item.attachments.forEach( a => {
        if(a.content_language && a.content_language.length > 0 && a.content_language[0].deleted_at == null && !ids.includes(a.content_language[0].content_id)){
          locations.push({
            id: a.content_language[0].content_id,
            name: a.content_language[0].name
          })
          ids.push(a.content_language[0].content_id)
        }
      })
      item.contents_main.forEach( a => {
        if(a.deleted_at == null && !ids.includes(a.content_id)){
          locations.push({
            id: a.content_id,
            name: a.name
          })
          ids.push(a.content_id)
        }
      })
      item.contents_alternative.forEach( a => {
        if(a.deleted_at == null && !ids.includes(a.content_id)){
          locations.push({
            id: a.content_id,
            name: a.name
          })
          ids.push(a.content_id)
        }
      })

    if(locations.length == 0)
      locations.push({
        id: null,
        name: "Sem Localização"
      })

      return locations;
    },
    showBlockLocation(pages){
      let loc = ''

      if(pages != undefined)
        loc = pages.map( p => {return p.page})

      if(loc.join(", ") == 'all')
        loc = 'Todas as páginas'
      else if(loc.length > 1)
        loc = 'Múltiplas páginas'
      else if(loc.length == 0)
        loc = 'Sem associação'
      else{
        loc = this.$parent.types.filter( t => { return t.id == loc[0].split("_")[1]})

        if(loc.length > 0)
          loc = loc[0].languages[0].name
        else
          loc = 'Conteúdo'
      }

      return loc 
    },
    attachmentsOrder(attach, total, index = null){
      let list = [];
      //set the select dropdowns with all the possible positions for each item, and the current one
      for (let i = 0; i < total; i++) {
        list.push(                
          {
            label: i+1,
            value: i+1,
            checked: attach.orderattachment ? (attach.orderattachment == i+1) : (i == index)
          })
      }
      return list
    },
    relatedItems(item){
        let final = []
        this.items2.forEach( t => {
          if(item && t.value == item.contentRelatedType)
            final.push({...t, checked:true})
          else
            final.push({...t, checked:false})
        })

      return final
    },
    relatedItemsContent(item){
      let final = []
      if(typeof this.items3 === "string"){
        this.items2.forEach( j => {
          if(j.value == item.contentRelatedType){
            j[this.items3].forEach( (i,k) => {
                final.push({
                  label: i,
                  value: k,
                  checked: (i == item.content_related_id) ? true : false
                })
            })
          }
        })
      }else if(typeof this.items3 === "object"){
        this.items3.forEach( i => {
          if(i.type.id == item.contentRelatedType && i.languages.length > 0)
            final.push({
              label: i.languages[0].name,
              value: i.id,
              checked: (i.id == item.content_related_id) ? true : false
            })
        })
      }
      
      return final
    },
    typeRelatedItems(item){
      let final = []

      this.items2.forEach( t => {
        if(item && t.id == item.type_related_id)
          final.push({label: t.languages[0].name, value: t.id, checked:true})
        else
          final.push({label: t.languages[0].name, value: t.id, checked:false})
      })

      return final
    },
  	typeRelatedRequired(item){
      let final = []
      
      this.items3.forEach( t => {
        if(item && t.value == item.required)
          final.push({...t, checked:true})
        else
          final.push({...t, checked:false})
      })

      return final
    },
    setLayout(type){
      this.layoutView = type
    },
    scrollEvent(){
        // add more contents if user scrolled down enough
        if (this.layoutView == 'grid' && !this.scrollCooldown && event.target.scrollTop + event.target.offsetHeight > event.target.scrollHeight) {
          this.scrollCooldown = true
          this.$emit("onScrollRequest");
          return
        }
    },
    sort(event) {
      //deprecated for now
      return
      if (
        event.target.classList.contains("asc") ||
        event.target.classList.contains("desc")
      ) {
        event.target.classList.toggle("asc");
        event.target.classList.toggle("desc");
      } else {
        if (this.$refs.table_container.querySelector(".asc"))
          this.$refs.table_container
            .querySelector(".asc")
            .classList.remove("asc");
        if (this.$refs.table_container.querySelector(".desc"))
          this.$refs.table_container
            .querySelector(".desc")
            .classList.remove("desc");
        event.target.classList.add("desc");
      }
    },
    setMaxVisibleButtons(items, limit){
      if(window.innerWidth < 768)
        return Math.min(4, Math.ceil(items.total / limit))
      
      return Math.min(7, Math.ceil(items.total / limit))
    },
    checkMobile(){
      return window.innerWidth < 768;
    },
    setIconExtension(item, file){
      if(item.name){
        let extension = item.name.split(".")[item.name.split(".").length - 1];
        if(file && item.file)
          extension = item.file.split(".")[item.file.split(".").length - 1];
        else if(file && item.image)
          extension = item.image.split(".")[item.image.split(".").length - 1];
          
        let available = ["svg", "pdf", "apk", "css", "doc", "iso", "js", "ppt", "php", "psd", "sql", "txt", "ttf", "xls", "zip"]
        let groups = []
  
        for (let i = 0; i < available.length; i++) {
          if(extension.toLowerCase().includes(available[i]) && extension.toLowerCase() != 'json')
            return 'i-'+available[i]
        }
      }

      return 'i-other'
    }
  },
  mounted(){
  }
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/layout/table.scss";
</style>