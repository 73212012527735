<template>
      <div v-if="parsedCategories.length > 0" class="addon categories" :class="[{'open' : open}]"> 
        <div class="collapsible" @click="open = !open">
            <label>Categorias</label>
            <i class="i-collapse" />
        </div>
        <div class="categories-form">
            <template v-for="(cat, k) in parsedCategories" :key="k">
                <fyi-checkbox 
                    :label="cat.category.languages[language_index] ? cat.category.languages[language_index].description : cat.category.languages[0].description"    
					:value="cat.value"  
					:checked="cat.value"                      
					v-on:input="cat.value = $event.checked"
					/>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name:"AddonCategories",
    props: {
        content: {
            type: Object,
            default: null
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        }
    },
    watch: {
        parsedCategories:{
            handler: function(_new, _old){
                this.$emit('update-categories', _new)
            },
            deep: true
        },
    },
    computed:{
        parsedCategories(){
            let fields = this.content.type.categories;

            fields.forEach(field => {
                let value = this.content.categories.filter( f => f.id == field.category_id)
                if(value.length > 0)
                    field.value = true
                else
                    field.value = false
            });

            return fields
        }
    }, 
    data(){
        return{
            open: false,
        }
    },
    mounted(){
        this.$emit('update-categories', this.parsedCategories)
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>