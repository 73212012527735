import _ from "lodash";
import Api from "./api";

class Configs extends Api {
    constructor() {
        super();
        this.uri = "configs";
        this.requiresAuth = true;
    }

    getConfig(id) {
        return this.get(this.uri + "/" + id, {}, this.requiresAuth);
    }
}

export default new Configs();
