import Api from "../api";

class Activities extends Api {
    constructor() {
        super();
        this.uri = "zelo/activities";
        this.requiresAuth = true;
    }

	getDashboardActivities(params) {
        return this.get(this.uri + "/dashboard" + (params ? "?" + params : ""));
	}
	getPresencasTable(params) {
        return this.get(this.uri + "/table" + (params ? "?" + params : ""));
	}
    export(params) {
        return this.get(this.uri + "/export" + (params ? "?" + params : ""));
    }

}

export default new Activities();