<template>
  <div
    v-if="open"
    class="notifications"
    v-on:blur="$parent.showNoti = !$parent.showNoti"
  >
    <label class="title">Notificações</label>
    <label
      v-if="action != '' && list.length != 0"
      class="action"
      @click="removeNotifications"
      >Remover todas</label
    >
    <div class="container">
      <div
        :class="['item', 'notification']"
        v-for="(item, k) in list"
        :key="item.app_name"
      >
        <img v-if="item.avatar != null" class="avatar" :src="item.avatar" />
        <div
          v-else
          :style="
            'background: #' + ((Math.random() * 0xffffff) << 0).toString(16)
          "
          class="avatar"
        >
          <span>{{ pseudo_name(item.user) }}</span>
        </div>
        <label>{{ item.user }}</label>
        <label
          >{{
            item.key.includes("update")
              ? $t("edited")
              : item.key.includes("create")
              ? $t("created")
              : $t("deleted")
          }}
          <a v-if="item.key.includes('content') && !item.key.includes('delete')" @click="goTo(item)">{{
            item.title
          }}</a>
          <a v-else-if="item.key.includes('user')" @click="goToUser(item)">{{
            item.title
          }}</a>
          <a v-else-if="item.key.includes('block')" @click="goToBlock(item)">{{
            item.title
          }}</a>
          <a v-else>{{ item.title }}</a>
        </label>
        <label>{{ item.app_name ? item.app_name : 'Backoffice' }}</label>
        <label
          >{{
            new Date(item.created_at).toLocaleString("pt-PT", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })
          }}
          . {{ item.created_at.split(" ")[1].slice(0, -3) }}</label
        >
        <i @click="removeNotification(item.log_id)" class="i-close"></i>
      </div>
      <div class="empty" v-if="list.length == 0">
        {{ $t("notifications_empty") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "NotificationsModal",
  data: function () {
    return {
      search: false,
      showNoti: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    list() {
      let final = [];
      this.$store.getters.unreadNotifications.forEach((not) => {
        final.push(not);
      });
      return final;
    },
  },
  methods: {
    ...mapActions(["markReadAll", "clearNotifications", "markRead"]),
    pseudo_name(name) {
      if(!name)
        return ""
      
      let splt = name.split(" ");
      return (
        splt[0][0].toUpperCase() +
        (splt[splt.length-1] != undefined ? splt[splt.length-1][0].toUpperCase() : "")
      );
    },
    goTo(item) {
      let id;
      this.$store.getters.apps.forEach((a) => {
        if (a.token == item.appToken) {
          id = a.id;
        }
      });
      this.$router.push({
        name: "pages_cms_edit",
        params: { id: id, content_id: item.url },
      });
      this.$parent.showNoti = false;
    },
    goToUser(item) {
      this.$router.push({
        name: "users_edit",
        params: { id: item.url },
      });
      this.$parent.showNoti = false;
    },
    goToBlock(item) {
      let id;
      this.$store.getters.apps.forEach((a) => {
        if (a.token == item.appToken) {
          id = a.id;
        }
      });
      this.$router.push({
        name: "blocks_cms_edit",
        params: { id: id, block_id: item.url },
      });
      this.$parent.showNoti = false;
    },
    removeNotifications() {
      this.markReadAll();
      this.clearNotifications();
    },
    removeNotification(id) {
      this.markRead(id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/navigation/notifications.scss";
</style>
