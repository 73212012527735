<template>
  <div :class="['card', className]" ref="card">
    <label class="title">{{ title }}</label>
    <label
      v-if="action != '' && list.length != 0"
      class="action"
      @click="func"
      >{{ action }}</label
    >
    <div class="list">
      <!--Notification Template-->
      <template v-for="(item, k) in list" :key="item.app_name">
        <div v-if="className == 'notification'" :class="['item', className]">
          <img v-if="item.avatar != null" class="avatar" :src="item.avatar" />
          <div
            v-else
            :style="
              'background: #' + ((Math.random() * 0xffffff) << 0).toString(16)
            "
            class="avatar"
          >
            <span>{{ pseudo_name(item.user) }}</span>
          </div>
          <label>{{ item.user }}</label>
          <label
            >{{
              item.key.includes("update")
                ? $t("edited")
                : item.key.includes("create")
                ? $t("created")
                : $t("deleted")
            }}
            <a
              v-if="
                item.key.includes('content') && !item.key.includes('delete')
              "
              @click="goTo(item)"
              >{{ item.title }}</a
            >
            <a v-else-if="item.key.includes('user')" @click="goToUser(item)">{{
              item.title
            }}</a>
            <a
              v-else-if="item.key.includes('block')"
              @click="goToBlock(item)"
              >{{ item.title }}</a
            >
            <a v-else>{{ item.title }}</a>
          </label>
          <label>{{ item.app_name ? item.app_name : "Backoffice" }}</label>
          <label
            >{{
              new Date(item.created_at).toLocaleString("pt-PT", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            }}
            . {{ item.created_at.split(" ")[1].slice(0, -3) }}</label
          >
          <i @click="card_func(item.log_id)" class="i-close"></i>
        </div>

        <!-- Environment Template-->
        <div
          v-else-if="className == 'environment'"
          :class="['item', className]"
        >
          <img
            v-if="item.data != null && item.data.image"
            :src="item.data.image"
            class="env-avatar"
          />
          <fyi-logo v-else />
          <label>{{ item.name }}</label>
          <span @click="card_func(item.id)">{{ $t("change_env") }}</span>
        </div>

        <!--Users Template-->
        <div v-else :class="['item', className]">
          <img v-if="item.avatar != null" class="avatar" :src="item.avatar" />
          <div
            v-else
            :style="
              'background: #' + ((Math.random() * 0xffffff) << 0).toString(16)
            "
            class="avatar"
          >
            <span>{{ pseudo_name(item.name) }}</span>
          </div>
          <label>{{ item.name }}</label>
          <div class="roles">
            <span v-for="role in item.roles" :key="role.id">{{
              role.name
            }}</span>
          </div>
          <i
            v-if="hasPermission(['users.read'])"
            class="i-settings"
            @click="card_func(item.id)"
          ></i>
        </div>
      </template>
      <div v-if="loading" class="loading-animation">
        {{ loader }}
      </div>
      <div v-else-if="list.length == 0" class="empty">
        {{ emptyMessage }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  data: () => {
    return {};
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    action: {
      type: String,
      default: "",
    },
    className: {
      type: String,
      default: "",
    },
    emptyMessage: {
      type: String,
      default: "Nada a reportar",
    },
    card_func: {
      type: Function,
    },
    func: {
      type: Function,
    },
    list: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loader() {
      const _this = this;
      setTimeout(() => {
        _this.$refs.card.querySelector(".loading-animation").innerHTML = "";
        bodymovin.loadAnimation({
          container: _this.$refs.card.querySelector(".loading-animation"),
          animationData: {
            v: "5.5.2",
            fr: 29.9700012207031,
            ip: 0,
            op: 30.0000012219251,
            w: 2134,
            h: 2134,
            nm: "Layer 2",
            ddd: 0,
            assets: [],
            layers: [
              {
                ddd: 0,
                ind: 2,
                ty: 4,
                nm: "1 to 4",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [766, 536, 0],
                        to: [689.667, -416.667, 0],
                        ti: [66.333, -391.333, 0],
                      },
                      { t: 30.0000012219251, s: [1823, 1069, 0] },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [756, 2, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, -10.997],
                        },
                        t: 0,
                        s: [291, 291, 100],
                      },
                      { t: 30.0000012219251, s: [100, 100, 100] },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [146.453, 146.453], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.22745098039215686, 0.7176470588235294,
                            0.8313725490196079, 1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 0, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        bm: 0,
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.22745098039215686, 0.7176470588235294,
                            0.8313725490196079, 1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        bm: 0,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [756.062, 1.645], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [102.889, 102.889], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Ellipse 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 300.00001221925,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 3,
                ty: 4,
                nm: "1 to 3",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [792, 1535, 0],
                        to: [-450.907, -496.349, 0],
                        ti: [4.167, 0, 0],
                      },
                      { t: 30.0000012219251, s: [766, 536, 0] },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [756, 2, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, 0.099],
                        },
                        t: 0,
                        s: [401, 401, 100],
                      },
                      { t: 30.0000012219251, s: [291, 291, 100] },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [146.453, 146.453], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.22745098039215686, 0.7176470588235294,
                            0.8313725490196079, 1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 0, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        bm: 0,
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.22745098039215686, 0.7176470588235294,
                            0.8313725490196079, 1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        bm: 0,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [756.062, 1.645], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [102.889, 102.889], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Ellipse 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 300.00001221925,
                st: 0,
                bm: 0,
              },
              {
                ddd: 0,
                ind: 4,
                ty: 4,
                nm: "1 to 2",
                sr: 1,
                ks: {
                  o: { a: 0, k: 100, ix: 11 },
                  r: { a: 0, k: 0, ix: 10 },
                  p: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.167, y: 0.167 },
                        t: 0,
                        s: [1823, 1069, 0],
                        to: [11.333, 260.667, 0],
                        ti: [656.667, 363.333, 0],
                      },
                      { t: 30.0000012219251, s: [792, 1535, 0] },
                    ],
                    ix: 2,
                  },
                  a: { a: 0, k: [756, 2, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                        o: {
                          x: [0.167, 0.167, 0.167],
                          y: [0.167, 0.167, -0.041],
                        },
                        t: 0,
                        s: [100, 100, 100],
                      },
                      { t: 30.0000012219251, s: [401, 401, 100] },
                    ],
                    ix: 6,
                  },
                },
                ao: 0,
                shapes: [
                  {
                    ty: "gr",
                    it: [
                      {
                        d: 1,
                        ty: "el",
                        s: { a: 0, k: [146.453, 146.453], ix: 2 },
                        p: { a: 0, k: [0, 0], ix: 3 },
                        nm: "Ellipse Path 1",
                        mn: "ADBE Vector Shape - Ellipse",
                        hd: false,
                      },
                      {
                        ty: "st",
                        c: {
                          a: 0,
                          k: [
                            0.22745098039215686, 0.7176470588235294,
                            0.8313725490196079, 1,
                          ],
                          ix: 3,
                        },
                        o: { a: 0, k: 100, ix: 4 },
                        w: { a: 0, k: 0, ix: 5 },
                        lc: 1,
                        lj: 1,
                        ml: 4,
                        bm: 0,
                        nm: "Stroke 1",
                        mn: "ADBE Vector Graphic - Stroke",
                        hd: false,
                      },
                      {
                        ty: "fl",
                        c: {
                          a: 0,
                          k: [
                            0.22745098039215686, 0.7176470588235294,
                            0.8313725490196079, 1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        bm: 0,
                        nm: "Fill 1",
                        mn: "ADBE Vector Graphic - Fill",
                        hd: false,
                      },
                      {
                        ty: "tr",
                        p: { a: 0, k: [756.062, 1.645], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [102.889, 102.889], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: "Transform",
                      },
                    ],
                    nm: "Ellipse 1",
                    np: 3,
                    cix: 2,
                    bm: 0,
                    ix: 1,
                    mn: "ADBE Vector Group",
                    hd: false,
                  },
                ],
                ip: 0,
                op: 300.00001221925,
                st: 0,
                bm: 0,
              },
            ],
            markers: [],
          },
          renderer: "svg",
          loop: true,
          autoplay: true,
        });
      }, 100);
    },
  },
  methods: {
    pseudo_name(name) {
      let splt = name.split(" ");
      return (
        splt[0][0].toUpperCase() +
        (splt[splt.length-1] != undefined ? splt[splt.length-1][0].toUpperCase() : "")
      );
    },
    goTo(item) {
      let id;
      this.$store.getters.apps.forEach((a) => {
        if (a.token == item.appToken) {
          id = a.id;
        }
      });

      this.$router.push({
        name: "pages_cms_edit",
        params: { id: id, content_id: item.url },
      });
    },
    goToUser(item) {
      this.$router.push({
        name: "users_edit",
        params: { id: item.url },
      });
      this.$parent.showNoti = false;
    },
    goToBlock(item) {
      let id;
      this.$store.getters.apps.forEach((a) => {
        if (a.token == item.appToken) {
          id = a.id;
        }
      });
      this.$router.push({
        name: "blocks_cms_edit",
        params: { id: id, block_id: item.url },
      });
      this.$parent.showNoti = false;
    },
    hasPermission(perm) {
      let permissions = this.$store.getters.auth.permissions;
      if (permissions.includes("all")) {
        return true;
      } else {
        let flag = 0;
        permissions.forEach((p) => {
          if (perm.some((item) => item == p.split(".")[0])) {
            flag++;
          }
        });

        return flag == perm.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/layout/card.scss";
</style>
