<template>
    <div class="fyi-radio">
        <label v-if="label && showLabel">{{ label }}</label>
        <div
            v-on:click="select"
            v-for="(option, key) in options"
            v-bind:key="key"
            class="item-radio"
        >
            <input
                type="radio"
                :value="option.value"
                v-model="pick"
                :checked="option.checked"
                :data-checked="option.checked"
                v-on:input="$emit('input', $event.target.value)"
            />

            <span class="checkmark"></span>
            <label>{{option.label}}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: "fyi-radio",
    props: {
        value: [String, Number],
        alias: String,
        options: Array,
        label: {
            type: String,
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        showLabel: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            pick: this.value
        };
    },
    methods: {
        select(e) {
            let el = e.target.parentElement;
            let inputElement = el.querySelector("input");
            let allInputs = document.querySelectorAll(".fyi-radio input");
            if (inputElement.checked !== true) {
                allInputs.forEach(i => {
                    i.checked = false;
                    i.dataset.checked = false;
                });
                inputElement.checked = true;
                inputElement.dataset.checked = true;
            }

            this.$emit("input", inputElement.parentElement.querySelector("label").innerText);
            this.$emit("input-value", inputElement.parentElement.querySelector("input").value);
        }
    }
};
</script>

<style lang="scss" scoped>
// //@import "@/styles/components/form/input";

/* Customize the label (the container) */
.item-radio {
    display: block;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 5px;

    > label {
        line-height: 16px;
        vertical-align: top;
        margin-bottom: 0;
        color: $bottle-green;
        margin-left: 5px;
        transform: translateY(5px);
        @extend %small-text;
    }
}

/* Hide the browser's default radio */
.fyi-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom radio */
.fyi-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid $light-grey;
    border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.fyi-radio:hover input ~ .checkmark {
}

/* When the radio is checked, add a blue background */
.fyi-radio input[data-checked="true"] ~ .checkmark,
.fyi-radio input:checked ~ .checkmark {
    background-color: $ziggurat;
        border: 1px solid $ziggurat;
}

.fyi-radio input[data-checked="true"] + span + label,
.fyi-radio input:checked + span + label {
    color: black;
}

</style>
