<template>
    <div v-if="b" :class="'menu ' + className">
        <div class="form">
            <div class="topbar">
                <SimpleSelect 
                v-if="showToolbar && menus"
                @select-value="filter = $event"
                :options="createDropdownInfo()"
                :previewIcon="'i-collapse grey'"/>
                <span v-else />
                <fyi-button   
                v-if="showToolbar"              
                :label="scheduleEdit == filter ? 'Repor' : 'Selecionar'"
                :className="'default select'"
                :onclick="() => filterMenu()"/>
                <span v-else />
                <span @click="expandAll">Expandir todos</span>
                <span @click="collapseAll">Colapsar todos</span>
                <fyi-button                 
                :label="'Adicionar'"
                :className="'default add'"
                :onclick="() => addItem()"/>        
            </div>
            <div class="items" v-if="b && menuCreated">
                <MenuList ref="list" :language="language" :language_index="language_index" @infoUp="updateInfo" :types="typesModel" :level="1" :menu="parsedMenu" :key="update"/>
            </div>
        </div>
        <div v-if="showToolbar" class="options-sidebar">
            <label class="title">{{ b.published == 1 ? 'Publicado' : 'Despublicado' }}</label>
            <i v-if="$route.name.includes('edit')" class="i-swap" @click="togglePublish"/>
            <label><span>Autor: </span> <span>-</span></label>
            <label><span>Update:</span> <span>
                {{
                new Date(b.updated_at).toLocaleDateString("pt-PT", {
                    format: "short",
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                })
            }}</span><i class="i-info"></i></label>
            <div class="buttons">
                <span v-if="$route.name.includes('edit')" :onclick="removeContent">Eliminar</span>
                <fyi-button
                    :label="($route.name.includes('edit') ? 'Atualizar' : 'Criar')"
                    :loading="submitStatus=='PENDING'"
                    :className="'default ' + ($route.name.includes('edit') ? 'update' : 'create')"
                    :onclick="saveMenu"
                />
            </div>

            <div class="section scheduler" ref="scheduler" >
                <div class="bar" @click="openBar('scheduler')">
                    <i class="i-collapse"/>
                    <label>Agendar publicação</label>
                </div>
                <div class="options">
                    <Date :value="b['schedule_'+schedulerState+'ed_at'] != null ? before['schedule_'+schedulerState+'ed_at'].split(' ')[0] : ''" 
                    @update:modelValue="schedulerDate = $event"
                    :label="'Data publicação'"/> 
                    <Time :value="b['schedule_'+schedulerState+'ed_at'] != null ? b['schedule_'+schedulerState+'ed_at'].split(' ')[1].slice(0, -3) : ''"
                    @update:modelValue="schedulerTime = $event"
                    :label="'Hora'"/>
                    <div class="next-schedule" v-if="b.schedule.length > 0">
                        <label>Agenda:</label>
                        <ul>
                            <li v-for="sch in b.schedule" :key="sch.id" @click="loadInfo($event, sch.data)"><label>{{
                                new Date(sch.schedule_published_at).toLocaleDateString("pt-PT", {
                                    format: "short",
                                    year: "2-digit",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit"
                                })
                            }}</label>
                            <i @click="removeScheduling(sch.id)" class="i-close"></i>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div onclick="this.previousElementSibling.classList.toggle('open')" class="sidebar-mobile-tab">
            <i class="i-collapse"></i>
        </div>
    </div>
    <teleport v-if="showToolbar" to="body">
        <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
    </teleport>
</template>

<script>
import { toHandlers } from "vue";
import MenuList from "./MenuList.vue";
import { mapActions, mapGetters } from "vuex";

export default {
    name:"MenusEdit",
    components: {
        MenuList
    },
    data(){
        return {
            b: null,
            update: 1,
            menus: null,
            parsedMenu: [],
            typesModel: [],
            menuCreated: false,
            filter: this.$route.params.block_id,
            toast_type: '',
            toast_message: '',
            toast_show: false,
            schedulerState: 'publish',
            schedulerTime: null,
            schedulerDate: null,
            scheduleEdit: false,
            submitStatus: "READY",
            revisionId: 1,
            ignoreSave: false
        }
    },
    props: {
        block:{
            type: Object,
            default: {}
        },
        showToolbar: {
            type: Boolean,
            default: true
        },
        className: {
            type: String,
            default: "default"
        },
        language_index: {
            type: Number,
            default: 0
        },
        language: {
            type: Number,
            default: 1
        },
    },
    beforeDestroy() {
        this.$store.getters.languages.forEach( (l,k) => {
            localStorage.removeItem('menu_'+l.id)
        });
    },
    async beforeMount(){
        if(this.block.data){
            this.b = this.block
        }else{
            this.b = await this.getBlock({id: this.$route.params.block_id, params: 'edit=1'})
            this.menus = await this.getBlocks('paginate=true&type=menu&limit=999999999');
        }
        this.parseMenu()
        this.update++;
        this.$forceUpdate();
        this.typesModel = await this.getTypes()
        this.$parent.$parent.loading = false;
        this.$parent.$parent.transition = false;
    },
    computed: {
        schedulerValue(){
            return (new Date(this.schedulerDate)).toLocaleDateString("en-CA", {
                format: "short",
                year: "2-digit",
                month: "2-digit",
                day: "2-digit"
            }) + " " + this.schedulerTime.hours + ":" + this.schedulerTime.minutes + ":00"
        }
    },
    methods: {
        ...mapActions(["getBlocks", "getBlock", "updateBlock", "getTypes", "removeSchedule"]),
        getChildren(id){
            let menu = [];

            this.b.data.forEach( b => {
                if(b.parent === id){
                    b.children = []
                    menu.push(b)
                    menu[menu.length-1].children = this.getChildren(b.id)
                    //sort
                    menu[menu.length-1].children.sort( (a,b) => {
                        return (a.ordermenu >= b.ordermenu) ? 1 : -1
                    })
                }
            });

            return menu
        },
        createDropdownInfo(){
            let list = [];

            for (let i = 0; i < this.menus.data.length; i++) {
                list.push(                
                {
                    label: this.menus.data[i].name,
                    value: this.menus.data[i].id,
                    checked: this.filter ? this.filter == this.menus.data[i].id : this.menus.data[i].id == this.$route.params.block_id
                })
            }

            return list
        },
        async filterMenu(){
            this.$store.getters.languages.forEach( (l,k) => {
                localStorage.removeItem('menu_'+l.id)
            });
            this.$parent.$parent.loading = true;
            this.$parent.$parent.transition = true;

            this.b = await this.getBlock({id: this.filter})

            this.scheduleEdit = false
            this.parsedMenu = [];
            this.parseMenu();
            this.ignoreSave = true
            this.update++;
            let bodyPath = this.$route.fullPath.split('/');
            bodyPath.pop();
            bodyPath = bodyPath.join('/') + "/" + this.filter;
            history.pushState('', '', bodyPath);
            this.$forceUpdate();
            this.$parent.$parent.loading = false;
            this.$parent.$parent.transition = false;
            setTimeout( () => {
                this.ignoreSave = false
            }, 1000)
        },
        expandAll(){
            let all = document.querySelectorAll(".level:not(.level-1)")

            all.forEach( a => {
                a.classList.remove("hide")
                a.previousElementSibling.lastElementChild.innerHTML = "Colapsar"
            })
        },
        collapseAll(){
            let all = document.querySelectorAll(".level:not(.level-1)")

            all.forEach( a => {
                a.classList.add("hide")
                a.previousElementSibling.lastElementChild.innerHTML = "Expandir"
            })
        },
        async saveMenu(){
            this.submitStatus = "PENDING"
            let menuInfo = this.$refs.list.gatherInfo();
            
            let block = {}

            block.data = menuInfo

            if(this.schedulerDate != null)
                block.schedule_published_at = this.schedulerValue

            try{
                await this.updateBlock({data: block, id: this.b.id})
                this.submitStatus = "READY"
                this.toast_type = "success";
                this.toast_message = "Alterado com sucesso";
                this.toast_show = true;
            }catch(error){
                this.submitStatus = "ERROR"
                console.log(error)
                this.toast_type = "error";
                this.toast_message = "Aconteceu um erro. Tente novamente.";
                this.toast_show = true;
            }
        },
        addItem(){
            this.$refs.list.editing = {
                
            }
        },
        updateInfo(evt){
            this.parsedMenu = evt.data
        },
        parseMenu(){
            let menu = [];
            if(this.b && this.b.data[0].elements){
                let clone = JSON.parse(JSON.stringify(this.b.data))
                this.revisionId = 1
                clone.forEach( d => {
                    menu.push(this.parseRevision(d));
                })
                localStorage.setItem('menu_'+this.language, JSON.stringify(menu))
            }else if(this.b && this.b.data[0].elements == undefined)
                this.b.data.forEach( b => {
                if((!b.parent || b.parent === 0) && b.language_id == this.language){
                    b.children = []
                    menu.push(b)
                    menu[menu.length-1].children = this.getChildren(b.id)
                    //sort
                    menu[menu.length-1].children.sort( (a,b) => {
                        return (a.ordermenu >= b.ordermenu) ? 1 : -1
                    })
                }
            });

            this.parsedMenu = menu;
            this.menuCreated = true;
        },
        parseRevision(element, parent = 0){
            element.id = this.revisionId
            this.revisionId++;
            element.children = element.elements;
            element.parent = parent
            delete element.elements;

            element.children.forEach( c => {
                this.parseRevision(c, element.id)
            })

            return element
        },
        openBar(element){
            document.querySelectorAll(".section.active").forEach( el => {if(!el.classList.contains(element)) el.classList.remove("active")})
            this.$refs[element].classList.toggle('active')
        },
        loadInfo(evt, data){
            let menu = [];

            if(evt.target.parentElement.parentElement.querySelector(".active")) evt.target.parentElement.parentElement.querySelector(".active").classList.remove('active')
            evt.target.classList.add('active');
            let count = 1;
            if(data)
                data.data.forEach( b => {
                if(b.language_id == this.language){
                    b.children = []
                    b.id = count;
                    count++;
                    menu.push(b)
                    menu[menu.length-1].children = this.loadInfoChildren(b.elements, count)
                    //sort
                    menu[menu.length-1].children.sort( (a,b) => {
                        return (a.ordermenu >= b.ordermenu) ? 1 : -1
                    })

                }
            });

            this.scheduleEdit = this.filter
            this.parsedMenu = [];
            this.parsedMenu = menu;
            this.update++;
        },
        loadInfoChildren(data, count){
            let menu = [];

            data.forEach( b => {
                if(b.language_id == this.language){
                    b.children = []
                    b.id = count;
                    count++;
                    menu.push(b)
                    menu[menu.length-1].children = this.loadInfoChildren(b.elements, count)
                    //sort
                    menu[menu.length-1].children.sort( (a,b) => {
                        return (a.ordermenu >= b.ordermenu) ? 1 : -1
                    })
                }
            });

            return menu
        },
        async removeScheduling(id){
            await this.removeSchedule(id);
            this.b.schedule.forEach( (s,k) => {
                if(s.id == id)
                    this.b.schedule.splice(k, 1)
            })
        }
    },
    mounted() {
        if(this.block == null){
            this.$parent.$parent.view = this.$t('menus')
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/menus.scss";   
</style>