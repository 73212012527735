import axios from 'axios'
import store from '../../store'
import router from '../../router'
import $bus from '../../plugins/event'

class Api {
    constructor() {
        this.http = axios.create({
            baseURL: this._geBaseUrl()
        })
        this.http.interceptors.response.use(r => r, this._handleError)
    }

    _geBaseUrl() {
        if (process.env.VUE_APP_API_DYNAMIC === "true")
            return window.location.origin + "/api/";
        else
            return process.env.VUE_APP_API_URL;
    }

    _getAuthorizationHeader(type, customRequest = false) {
        let token = null;
        if (type === "client")
            token = store.getters.init.clientToken
        else
            token = store.getters.auth.token ? store.getters.auth.token : store.getters.init.clientToken

        let header = {
            AppToken: store.getters.app,
            identity: store.getters.identity,
            Authorization: 'Bearer ' + token
        }

        if(customRequest)
            header.AppToken = customRequest.app

        if (process.env.VUE_APP_API_DOMAIN) {
            header.domain = process.env.VUE_APP_API_DOMAIN
        }

        if (store.getters.headerDomain) {
            header.domain = store.getters.headerDomain
        }

        return header
    }

    _getDomain() {
        
    }

    _handleError(error) {
        return Promise.reject(error.response.data)
    }

    async _request(request, requiresAuth, customRequest = null) {
        let files = [];
        try {
            if (requiresAuth) {
                const authorization = this._getAuthorizationHeader(requiresAuth, customRequest)
                request = {
                    ...request,
                    headers: {
                        ...request.headers,
                        ...authorization
                    }
                }
            } else {
                if (process.env.VUE_APP_API_DOMAIN) {
                    request.headers = {
                        ...request.header,
                        domain: process.env.VUE_APP_API_DOMAIN
                    }
                }
        
                if (store.getters.headerDomain) {
                    request.headers = {
                        ...request.header,
                        domain: store.getters.headerDomain
                    }
                }
            }

            if(request.method == 'post' && request.data instanceof FormData && request.data.get('length')){
                for (let i = parseInt(request.data.get('length')) - 5 ; i < parseInt(request.data.get('length')); i++) {
                    if(request.data.get("files["+i+"]") == undefined)
                        break;

                    files.push(request.data.get("files["+i+"]"));
                }
                request.onUploadProgress = (progressEvent) => {
                    const total = progressEvent.total;
                    const totalLength = progressEvent.lengthComputable ? total : null
                    if(totalLength !== null)
                        $bus.$trigger('file-upload', { progress:Math.round((progressEvent.loaded * 100) / total), nr: request.data.get('nr'), info: files})
                }
            }else if(request.method == 'post' && request.data instanceof FormData){
                request.onUploadProgress = (progressEvent) => {
                    const total = progressEvent.total;
                    const totalLength = progressEvent.lengthComputable ? total : null
                    if(totalLength !== null)
                        $bus.$trigger('file-upload', { progress:Math.round((progressEvent.loaded * 100) / total), info: [request.data.get("file")]})
                }
            }
            

            if(request.url.includes('login')){
                request.headers.AppToken = "backoffice"
            }

            const {
                data
            } = await this.http(request)

            //send event of upload request being successfull
            if(request.method == 'post' && request.data instanceof FormData){
                $bus.$trigger('file-upload', { progress: 'request-finished', nr: request.data.get('nr'), info: files})
            }
            
            return data
        } catch (error) {
            
            let redirect = false;
            if(store.getters.auth.token == null || store.getters.auth.token == undefined)
                redirect = true;

			if(error.status === 401 && (error.message.includes("Unauthenticated") || error.message.includes("Access token expired") )) {
				store.dispatch("logout", {flag:false});
                if(redirect)
				    router.push({name: "login"});
			}

            //send event of upload request being unsuccessfull
            if(request.method == 'post' && request.data instanceof FormData){
                $bus.$trigger('file-upload', { progress: 'request-error', nr: request.data.get('nr'), info: files})
            }
            
            throw error
        }
    }

    get(url, requiresAuth = true, config = null, customRequest = null) {
        return this._request({
                method: 'get',
                url,
                ...config
            },
            requiresAuth, customRequest
        )
    }

    post(url, payload, requiresAuth = true, config = null) {
        return this._request({
                method: 'post',
                url,
                data: payload,
                ...config
            },
            requiresAuth
        )
    }

    put(url, payload, requiresAuth = true, config = null) {
        return this._request({
                method: 'put',
                url,
                data: payload,
                ...config
            },
            requiresAuth
        )
    }

    patch(url, payload, requiresAuth = true, config = null) {
        return this._request({
                method: 'patch',
                url,
                data: payload,
                ...config
            },
            requiresAuth
        )
    }

    delete(url, requiresAuth = true, config = null) {
        return this._request({
                method: 'delete',
                url,
                ...config
            },
            requiresAuth
        )
    }
}

export default Api