import _ from "lodash";
import Api from "./api";

class Auth extends Api {
    constructor() {
        super();
        this.uri = "auth";
        this.requiresAuth = true;
    }

    async login({
        email,
        password
    }) {
        const result = await this.post(`${this.uri}/login`, {
            email,
            password
        }, 'client');

        if (!result) return null;

        return result;
    }

    async loginOTP({
        email,
        password
    }) {
        const result = await this.post(`${this.uri}/login/otp`, {
            email,
            password
        }, 'client');

        if (!result) return null;

        return result;
    }

    async loginOTPValidate({
        email,
        otp
    }) {
        const result = await this.post(`${this.uri}/login/otp/validate`, {
            email,
            otp
        }, 'client');

        if (!result) return null;

        return result;
    }
    
    async logout() {
        const response = await this.post(`${this.uri}/logout`);
        return response;
    }

    async recover({
        email
    }) {
        let callback_url = `${window.location.origin}/admin/auth/recover-password`;

        const result = await this.post(`users/password/email`, {
            email,
            callback_url
        }, 'client');
        if (!result) return null;

        return result;
    }

    async reset({
        password,
        token
    }) {
        let callback_url = `${window.location.origin}/admin/auth/login`;

        const result = await this.post(`users/password/reset`, {
            password,
            token,
            callback_url
        }, 'client');
        if (!result) return null;

        return result;
    }

    async getUserByToken({
        token
    }) {
        const result = await this.get(`users/token/` + token, 'client');
        if (!result) return null;
        return result;
    }

    async getUserByResetToken({
        token
    }) {
        const result = await this.get(`users/reset/token/` + token, 'client');
        if (!result) return null;
        return result;
    }

    async sendOtp({
        id
    }) {
        const result = await this.post(`users/` + id + `/sendotp`, {}, 'client');
        if (!result) return null;
        return true;
    }

    async validateOtp({
        id,
        otp
    }) {
        const result = await this.post(`users/` + id + `/validateotp`, {
            otp
        }, 'client');
        if (!result) return null;
        return result;
    }

    async updateUser({
        id,
        data
    }) {
        const result = await this.put(`users/` + id, data, 'client');
        if (!result) return null;
        return result;
    }

    async updatePassword({
        password,
        token
    }) {
        const result = await this.post(`users/password/update`, {
            password,
            token
        }, 'client');
        if (!result) return null;

        return result;
    }

    async revokePasswordChange({user_id}){
        const result = await this.post(`users/password/revoke`, {
            user_id
        }, 'client');
        if (!result) return null;

        return result;
    }

    async validatePassword({user_id, password}){
        const result = await this.post(`users/password/validate`, {
            user_id, password
        }, 'client');
        if (!result) return null;

        return result;
    }
}

export default new Auth();