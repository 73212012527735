import Search from "../../../libs/api/cms/search";

export default {
    state: {
    },

    getters: {
    },

    mutations: {
    },

    actions: {
		superSearch: async ({ commit }, {text, params}) => {
            const response = await Search.search(text, params);
            return response;
        }
    }
};
