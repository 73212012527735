
import DashboardCms from "./../../pages/cms/dashboard/DashboardCms"; 
import Contents from "./../../pages/cms/contents/Contents"; 
import Pages from "./../../pages/cms/contents/Pages"; 

import Blocks from "./../../pages/cms/blocks/Blocks"; 
import BlocksEdit from "./../../pages/cms/blocks/BlocksEdit"; 

import Fields from "./../../pages/cms/fields/Fields"; 
import FieldsEdit from "./../../pages/cms/fields/FieldsEdit"; 

import Menus from "./../../pages/cms/menus/Menus"; 
import MenusEdit from "./../../pages/cms/menus/MenusEdit"; 

import Template from "./../../pages/cms/templates/Template"; 
import TemplateEdit from "./../../pages/cms/templates/TemplateEdit"; 

import Types from "./../../pages/cms/types/Types"; 
import TypesEdit from "./../../pages/cms/types/TypesEdit"; 

import Forms from "./../../pages/cms/forms/Forms"; 

import Content from "./../../pages/cms/contents/Content"; 
import MediaPage from "./../../pages/cms/media/MediaPage"; 
import init from "./../middleware/init";
import auth from "./../middleware/auth";

export default [
    {
        path: ":id",
        name: "dashboard_cms",
        component: DashboardCms,
        meta: {
            middleware: [init, auth],
            title: 'CMS Dashboard'
        },
    },
    {
        path: ":id/pages",
        name: "pages_cms",
        component: Pages,
        meta: {
            middleware: [init, auth],
            title: 'Páginas'
        },
    },
    {
        path: ":id/pages/create",
        name: "pages_cms_create",
        component: Content,
        meta: {
            middleware: [init, auth],
            title: 'Criar Conteúdo'
        },
    },
    {
        path: ":id/pages/:content_id",
        name: "pages_cms_edit",
        component: Content,
        meta: {
            middleware: [init, auth],
            title: 'Editar Conteúdo'
        },
    },
    {
        path: ":id/blocks",
        name: "blocks_cms",
        component: Blocks,
        meta: {
            middleware: [init, auth],
            title: 'Blocos'
        },
    },
    {
        path: ":id/blocks/:block_id",
        name: "blocks_cms_edit",
        component: BlocksEdit,
        meta: {
            middleware: [init, auth],
            title: 'Editar Bloco'
        },
    },
    {
        path: ":id/blocks/create",
        name: "blocks_cms_create",
        component: BlocksEdit,
        meta: {
            middleware: [init, auth],
            title: 'Criar Bloco'
        },
    },
    {
        path: ":id/menus",
        name: "menus_cms",
        component: Menus,
        meta: {
            middleware: [init, auth],
            title: 'Blocos'
        },
    },
    {
        path: ":id/menus/:block_id",
        name: "menus_cms_edit",
        component: MenusEdit,
        meta: {
            middleware: [init, auth],
            title: 'Editar Bloco'
        },
    },
    {
        path: ":id/media",
        name: "media",
        component: MediaPage,
        meta: {
            middleware: [init, auth],
            title: 'Media'
        },
    },
    {
        path: ":id/templates",
        name: "templates",
        component: Template,
        meta: {
            middleware: [init, auth],
            title: 'Templates'
        },
    },
    {
        path: ":id/templates/:template_id",
        name: "templates_edit",
        component: TemplateEdit,
        meta: {
            middleware: [init, auth],
            title: 'Editar Template'
        },
    },
    {
        path: ":id/templates/create",
        name: "templates_create",
        component: TemplateEdit,
        meta: {
            middleware: [init, auth],
            title: 'Criar Template'
        },
    },
    {
        path: ":id/types",
        name: "types",
        component: Types,
        meta: {
            middleware: [init, auth],
            title: 'Tipos'
        },
    },
    {
        path: ":id/types/:type_id",
        name: "types_edit",
        component: TypesEdit,
        meta: {
            middleware: [init, auth],
            title: 'Editar Tipo'
        },
    },
    {
        path: ":id/types/create",
        name: "types_create",
        component: TypesEdit,
        meta: {
            middleware: [init, auth],
            title: 'Criar Tipo'
        },
    },
    {
        path: ":id/forms",
        name: "forms",
        component: Forms,
        meta: {
            middleware: [init, auth],
            title: 'Formulários'
        },
    },
    {
        path: ":id/fields",
        name: "fields",
        component: Fields,
        meta: {
            middleware: [init, auth],
            title: 'Campos Extra'
        },
    },
    {
        path: ":id/fields/:field_id",
        name: "fields_edit",
        component: FieldsEdit,
        meta: {
            middleware: [init, auth],
            title: 'Editar Campo Extra'
        },
    },
    {
        path: ":id/fields/create",
        name: "fields_create",
        component: FieldsEdit,
        meta: {
            middleware: [init, auth],
            title: 'Criar Campo Extra'
        },
    },
    // {
    //     path: "contents",
    //     name: "contents",
    //     component: Contents,
    //     meta: {
    //         middleware: [init, auth],
    //         title: 'Demo'
    //     }
    // }
];