import _ from "lodash";
import Api from "./api";

class Entity extends Api {
    constructor() {
        super();
        this.uri = "entities";
        this.requiresAuth = true;
    }

    all() {
        return this.get(this.uri);
    }

    allProjects(entity) {
        if (entity)
            return this.get(this.uri + "/" + entity + "/projects");
        else
            return this.get(this.uri + "/projects");
    }
}

export default new Entity();