<template>
  <div class="unexpected">
    <p>Unexpected Error</p>
  </div>
</template>

<script>

export default {
  name: 'error-unexpected'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
