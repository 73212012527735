<template>
    <div class="template edit" v-if="template != null" :class="[{'open' : open}]">

        <fyi-text
            v-bind:value="template.name"
            v-on:input-text="template.name = $event"
            :required="true"
            :inputError="true"
            :label="'Nome'"
            ref="name"
            :icon_box="false" :full="true"
        />

        <fyi-text
            class="alias"
            v-bind:value="template.alias"
            v-on:input-text="template.alias = $event"
            :label="'Alias'"
            :icon_box="false" :full="true"
        />

        <div ref="positions" class="template-edit">
            <div class="collapsible" @click="open($event)">
                <label>Posições</label>
                <i class="i-collapse"/>
            </div>
            <div class="positions-available">
                <div v-for="(item, k) in template.positions" :key="k">
                    <fyi-text 
                        v-bind:value="item"
                        v-on:input-text="item = $event"
                        :icon_box="false" :full="true"
                    />
                    <span
                    @click="template.positions.splice(k, 1)"
                    >Remover</span>
                </div>
                <fyi-text
                    class="add-input"
                    v-bind:value="newPosition"
                    v-on:input-text="newPosition = $event"
                    :icon_box="false" :full="true"
                />
                <fyi-button
                    :label="'Adicionar'"
                    :className="'default add'"
                    :onclick="() =>{ template.positions.push(newPosition); newPosition = ''}"
                />
            </div>
        </div>

        <div class="template-edit">
            <div class="collapsible" @click="open($event)">
                <label>Páginas Associadas</label>
                <i class="i-collapse"/>
            </div>
            <div class="pages-available">
                <div>
                    <label>Link</label>
                    <label>Nome</label>
                    <label>Configuração</label>
                </div>
                <div v-for="(item, k) in pagesParsed" :key="k">
                    <label>{{item.type == "type" ? "Tipo" : (item.type == "content" ? "Conteúdo" : item.name )}}</label>
                    <label>{{item.name}}</label>
                    <label>{{item.config == "contents" ? 'Todas as páginas de conteúdo' : (item.config == "all" ? 'Todas as páginas de tipo e conteúdo' : 'Todas as páginas de tipo')}}</label>
                    <span @click="removePage(item.id)">Eliminar</span>
                </div>
                <fyi-button
                    :label="'Adicionar'"
                    :className="'default add'"
                    :onclick="() => addPage = true"
                />
            </div>
        </div>

        <fyi-button
            :label="'Guardar'"
            :className="'default save'"
            :loading="submitStatus=='PENDING'"
            :onclick="() => save()"
        />
    </div>
    <teleport to="body">
        <div class="bg" v-if="addPage">
            <div class="add-page">
                <label>Adicionar página</label>
                <i class="i-close" @click="addPage = false"/>
                <SimpleSelect 
                    :label="'Especificação'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="pageTemp.specification = $event" :options="specification"/>

                <SimpleSelect 
                    v-if="pageTemp.specification == 'type' || pageTemp.specification == 'content'"
                    :label="'Tipo'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="fetchContent($event)" :options="typesModel.map( t => {return {label: t.languages[0].name,value: t.id, checked: pageTemp.type == t.id }})" :key="1"/>

                <SimpleSelect 
                    v-if="pageTemp.specification == 'content'"
                    :label="'Conteúdo'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="pageTemp.content = $event" :options="addPageContent" :key="addPageContentKey"/>

                <SimpleSelect 
                    v-if="pageTemp.specification == 'type'"
                    :label="'Configuração'"
                    :className="'order'"
                    :previewIcon="'i-order'"
                    @select-data-value="pageTemp.config = $event" :options="addPageConfig"/>

                <fyi-button
                    :label="'Adicionar'"
                    :className="'default add'"
                    :onclick="() => addPageInfo()"
                />
            </div>
        </div>
    </teleport>
    <teleport to="body">
        <Toast v-if="toast_show" :type="toast_type" :message="toast_message"/>
    </teleport>

</template>

<script>
import { mapActions } from "vuex";

export default {
    name:"TemplateEdit",
    data(){
        return{
            specification: [{
                label: "Tudo",
                value: "all",
                checked: false
            },
            {
                label: "Homepage",
                value: "home",
                checked: false
            },
            {
                label: "Tipo",
                value: "type",
                checked: false
            },
            {
                label: "Conteúdo",
                value: "content",
                checked: false
            },],
            addPageConfig: [{
                label: "Todas as páginas de tipo",
                value: "",
                checked: false
            },{
                label: "Todas as páginas de conteúdo",
                value: "_contents",
                checked: false
            },{
                label: "Todas as páginas de conteúdo e tipo",
                value: "_all",
                checked: false
            }],
            types: [],
            submitStatus:"READY",
            typesModel:[],
            template: null,
            contents: [],
            pagesParsed: [],
            toast_type: '',
            toast_message: '',
            toast_show: false,
            addPage: false,
            pageTemp: {},
            addPageContent: [],
            addPageContentKey: 1,
            loadTable: false,
            newPosition: ''
        }
    },
    async beforeMount(){
        if(this.$route.name.includes("edit")){
            this.template = await this.getTemplate({id: this.$route.params.template_id, params: 'with=pages'})
        }else{
            this.template = {
                name:'',
                alias:'',
                positions:[],
                pages:[]
            }
        }
        this.typesModel = await this.getTypes({});
        
        this.parsePages();

        this.$parent.$parent.loading = false;
        this.$parent.$parent.transition = false;
    },
    methods:{
        ...mapActions(["getContents", "getContent", "getTypes", "getTemplates", "getTemplate", "updateTemplate", "createTemplate"]),
        hasPermission(prmsion) {
            let permissions = this.$store.getters.auth.permissions
            if(permissions.includes("all")){
                return true
            }else{
                let flag = 0;
                permissions.forEach(p => {
                    if(prmsion.some(item => item == p.split(".")[0])){
                        flag++;
                    }
                });

                return flag == prmsion.length
            }
        },
        open(ev){
            ev.target.parentElement.classList.toggle('open')
        },
        async parsePages(){
            let pages = [], contents = [];
            this.template.pages.forEach( t => {
                let tmp = { type: t.page.split("_")[0]}

                if(tmp.type == "type"){
                    tmp.id = t.page.split("_")[1]
                    let filt = this.typesModel.filter( t => { return t.id == tmp.id})
                    if(filt.length > 0){
                        tmp.name = filt[0].languages[0].name
                        tmp.config = t.page.split("_")[2]
                    }
                }else if(tmp.type == "content"){
                    contents.push (t.page.split("_")[1])
                    tmp.id = t.page.split("_")[1]
                    tmp.config = t.page.split("_")[2]
                }else if(tmp.type == "all"){
                    tmp.name = "Todas as páginas"
                }else if(tmp.type == "home"){
                    tmp.name = "Homepage"
                }else{
                    tmp.name = tmp.type
                }
                
                tmp.show = t.show;
                tmp.page = t.page

                if(tmp.name || tmp.type == 'content' )
                    pages.push(tmp)
            })

            if(contents.length > 0){
                let c = await this.getContents('noaddons=true&limit=99999999999999&contents='+contents.join(','));

                c.data.forEach( cc => {
                    pages.forEach( p => {
                        if (p.type == "content" && cc.id == p.id){
                            p.name = cc.languages[0].name
                        }
                    })
                })
            }


            this.pagesParsed = pages;

            return pages;
        },
        async fetchContent(t){
            this.pageTemp.type = t; 
            if(this.pageTemp.specification == "content"){
                let c = await this.getContents('noaddons=true&limit=99999999999999&order=date_desc&types='+this.pageTemp.type);
                let content = [];
                c.data.forEach( cc => {
                    content.push({
                        label: cc.languages[0].name,
                        value: cc.id,
                        checked: false
                    })
                })
                this.addPageContent = content;
            }
        },
        removePage(id){
            this.pagesParsed.forEach( (c,k) => {
                if(c.id == id){
                    this.pagesParsed.splice(k,1)
                }
            })
        },
        addPageInfo(){
            this.template.pages.push({
                page: (this.pageTemp.specification == 'home' || this.pageTemp.specification == 'all' ? this.pageTemp.specification : ( this.pageTemp.specification == 'type' ? (this.pageTemp.specification + "_" + this.pageTemp.type + this.pageTemp.config) : (this.pageTemp.specification + "_" + this.pageTemp.content))),
                template_id: this.$route.params.template_id
            })
            this.parsePages()
            this.pageTemp = {}
            this.addPage = false
        },
        async save(){
            try{
                this.submitStatus = "PENDING"
                this.$refs.positions.classList.remove("error")
                this.$refs.name.$el.classList.remove("error")

                let template = {
                        name: this.template.name,
                        alias: this.template.alias,
                        pages: [],
                        positions: this.template.positions,
                        is_default: 0
                    }

                    this.pagesParsed.forEach( t => {
                        template.pages.push(
                            t.page
                        )
                    })
                if(this.$route.name.includes("edit"))
                    await this.updateTemplate({data: template, id: this.template.id})
                else{
                    await this.createTemplate(template)
                    this.$router.push({ name: 'templates', params: { id: this.$route.params.id }})
                }

                this.submitStatus = "READY"
                this.toast_type = "success";
                this.toast_message = "Alterado com sucesso";
                this.toast_show = true;
            }catch(error){
                console.log(error)
                this.submitStatus = "ERROR"
                this.toast_type = "error";
                let message = "Aconteceu um erro. Tente novamente.";
                for (const k in error.error) {
                    if(k == "name"){
                        message+="\n\nNecessita associar um nome."
                        this.$refs.name.$el.classList.add("error")
                    }
                    if(k == "positions"){
                        message+="\n\nNecessita associar uma posição."
                        this.$refs.positions.classList.add("error")
                    }
                }
                this.toast_message = message;
                this.toast_show = true;
            }

        },
    },
    mounted(){
        if(this.$route.name.includes("edit"))
            this.$parent.$parent.view = this.$t('edit')
        else
            this.$parent.$parent.view = this.$t('Criar')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/templates.scss";   
</style>