<template>
    <div v-touch:swipe="swipeHandler" v-touch:press="moveHandler" class="sidebar" ref="sidebar">
        <fyi-logo :side="true"/>
        <i v-if="mobile" class="i-breadcrumb" @click="openMenu()" />
        <div ref="special" v-if="special.length != 0" class="special">
            <span>{{$parent.app.name}}</span>
            <ul>
                <li v-for="(item, k) in special" :key="item.name"  @click="$router.push({name: 'dashboard_cms', params: {id: item.id}})">
                    <label>{{item.name}}</label>
                </li>
            </ul>
        </div>
        <ul ref="menu" class="menu">
            <li v-for="(item, k) in menu" :key="item.label"  @click="$router.push({name: item.route, params: item.params, query: item.query})" :class="[{'active': $route.name == item.route && $route.query.filter == item.query.filter}, {'disabled': item.route == 'none'}, {'hidden': !hasPermission(item.permissions)}]">
                <i :class="item.icon"></i>
                <label>{{item.label}}</label>
            </li>
        </ul>
        <div v-if="special.length == 0" class="special-mobile"></div>
        <div class="manage" v-if="mobile">
            <keep-alive>
                <div>
                    <img v-if="$store.getters.auth.user.avatar!=null" class="avatar" :src="$store.getters.auth.user.avatar">
                    <div v-else :style="'background: #'+color" class="avatar">
                        <span>{{ pseudo_name }}</span>
                    </div>
                    <span>{{ $t('Conta') }}</span>
                </div>
            </keep-alive>
            <ii class="i-notifications"><label>{{ $t('Notificações') }}</label></ii>
            <ii class="i-settings"><label>{{ $t('Definições') }}</label></ii>
            <ii class="i-logout" @click="out"><label>{{ $t('Sair') }}</label></ii>
        </div>
        <label @click="openPatchNotes" class="version">{{ $t('version') }} {{ $store.getters.appVersion }}</label>
        <teleport to="body">
            <Modal ref="modal" />
        </teleport>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name:"Sidebar",
    data: () =>{
        return {
            dragCooldown: false,
            mobile:false
        }
    },
    computed: {
        pseudo_name(){
            if(this.$store.getters.auth.user.name == undefined)
                return ''
                
            let splt = this.$store.getters.auth.user.name.split(" ");
            return splt[0][0].toUpperCase() + (splt[splt.length-1] != undefined ? splt[splt.length-1][0].toUpperCase() : '')
        },
        color(){
            if(window.sessionStorage.getItem('headerColor') == undefined || window.sessionStorage.getItem('headerColor') == null)
                window.sessionStorage.setItem('headerColor', (Math.random()*0xFFFFFF<<0).toString(16));

            return window.sessionStorage.getItem('headerColor');
        }
    },
    props: {
        menu: {
            type: Array,
            default: () => []
        },
        special: {
            type: Array,
            default: () => []
        },
        currentApp: {
            type: String,
            default: ""
        },
    },
    methods: {
        ...mapActions(["logout"]),
        hasPermission(menu_permission) {
            let permissions = this.$store.getters.auth.permissions;
            if(permissions.includes("all")){
                return true
            }else{
                let flag = 0;
                permissions.forEach(p => {
                    if(menu_permission.some(item => item == p.split(".")[0])){
                        flag++;
                    }
                });

                return flag == menu_permission.length
            }
        },
        swipeHandler(mouseEvent){
            if(mouseEvent == "right"){
                this.$refs.sidebar.classList.add("active")
            }else{
                this.$refs.sidebar.classList.remove("active")
            }
            event.stopPropagation()
        },
        moveHandler(mouseEvent){
            if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                this.dragCooldown = true
                if(!mouseEvent.target.classList.contains("sidebar"))
                    return

                if(window.innerWidth <= 555)
                    this.$refs.sidebar.classList.toggle("active")
                
                setTimeout(() => {
                    this.dragCooldown = false
                }, 1000)
            }
        },
        checkMobileSize(){
            this.mobile = window.innerWidth < 768;
        },
        openMenu(){
            this.$refs.menu.classList.toggle("open")
            this.$refs.special.classList.toggle("open")
        },
        async out(){
            const ok = await this.$refs.modal.show({
                message: "Deseja terminar a sessão?",
                okButton: 'Terminar Sessão',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.logout().then(() => {
                    this.$router.push({ name: "login" });
                })
            }
        },
        openPatchNotes(){
            console.log(this.$parent)
            this.$parent.$parent.$parent.$refs.patch.show = true
        }
    },
    mounted(){
        this.checkMobileSize();
        window.addEventListener("resize", () => {
            this.checkMobileSize();
        })
    }
}
</script>

<style lang="scss">
    @import "../../../../styles/components/navigation/sidebar.scss";
</style>