<template>
    <div class="addon seo open" v-if="addon">
        <div class="tab">
            <span id="seo" @click="selectTab" :class="[{'active': tab == 'seo'}]">SEO</span>
            <span id="analysis" @click="selectTab" :class="[{'active': tab == 'analysis'}]">Análise</span>
            <span id="fb" @click="selectTab" :class="[{'active': tab == 'fb'}]">Facebook</span>
            <span id="twitter" @click="selectTab" :class="[{'active': tab == 'twitter'}]">Twitter</span>
            <span id="settings" @click="selectTab" :class="[{'active': tab == 'settings'}]">Definições</span>
        </div>
        <div class="section section-seo" :class="[{'active': tab == 'seo'}]">
            <fyi-text
                v-bind:value="addon[language_index].title"
                v-on:input-text="addon[language_index].title = $event"
                :label="'Título'"
                :icon_box="false" :full="true"
            />
            <fyi-textarea
                v-bind:value="addon[language_index].meta_description"
                v-on:input="addon[language_index].meta_description = $event; analyze()"
                :label="'Descrição meta'"
                :toolbar="'none'"
            />
            <fyi-text
                v-bind:value="addon[language_index].slug ? addon[this.language_index].slug : $parent.content.languages[this.language_index].alias"
                v-on:input-text="addon[language_index].slug = $event"
                :label="'Slug'"
                :icon_box="false" :full="true"
            />
            <!-- <fyi-text
                v-bind:value="''"
                v-on:input-text="''"
                :label="'Palavras-chave'"
                :icon_box="false" :full="true"
            /> -->

            <InputTags
                v-model="app"
                :tags="keywords"
                :label="'Palavras-chave'"
                :disabled="false"
                :icon_box="false" :full="true"
                :noUrlRedirect="true"
                @input-tags="addon[language_index].keywords = $event
                "
                :key="keywordsUpdate"
            />
        </div>

        <div class="section analysis" :class="[{'active': tab == 'analysis'}]">
            <label v-if="analysis.recommend.data.length > 0">Recomendações <span>({{analysis.recommend.total}})</span></label>
            <div>
                <label v-if="analysis.recommend.data.length > 0" v-for="(item, k) in analysis.recommend.data" :key="k">
                    <i :class="item.icon"></i>{{item.label}}
                </label>
            </div>
            <label v-if="analysis.perfect.data.length > 0">Perfeito <span>({{analysis.perfect.total}})</span></label>
            <div>
                <label v-if="analysis.perfect.data.length > 0" v-for="(item, k) in analysis.perfect.data" :key="k">
                    <i :class="item.icon"></i>{{item.label}}
                </label>
            </div>
            <div />
        </div>

        <div class="section fb" :class="[{'active': tab == 'fb'}]">
            <fyi-text
                v-bind:value="addon[language_index].fb_title"
                v-on:input-text="addon[language_index].fb_title = $event; analyze()"
                :label="'Título'"
                :icon_box="false" :full="true"
            />
            <fyi-textarea
                v-bind:value="addon[language_index].fb_description"
                v-on:input="addon[language_index].fb_description = $event; analyze()"
                :label="'Descrição'"
                :toolbar="'none'"
            />
            <label>Imagem</label>
            <div v-if="addon[language_index].fb_image != undefined && addon[this.language_index].fb_image != null && addon[this.language_index].fb_image != ''" class="image-preview">
                <img :src="$store.getters.appConfig.url + addon[this.language_index].fb_image">
            </div>
            <div class="button-bar" v-if="addon[language_index].fb_image != undefined && addon[this.language_index].fb_image != null && addon[this.language_index].fb_image != ''">
                <fyi-button
                    :label="'Alterar Imagem'"
                    :className="'default add'"
                    :onclick="() => mediaOn = true"
                />
                <fyi-button
                    :label="'Remover'"
                    :className="'default cancel'"
                    :onclick="() => {addon[this.language_index].fb_image = '';analyze();}"
                />
            </div>
            <div v-if="addon[language_index].fb_image != undefined && addon[this.language_index].fb_image != null && addon[this.language_index].fb_image != ''" style="clear:both" />
            <fyi-button
                v-else
                :label="'Nova Imagem'"
                :icon="'i-upload'"
                :className="'default new-image'"
                :onclick="() => mediaOn = true"
            />
        </div>

        <div class="section twitter" :class="[{'active': tab == 'twitter'}]">
            <fyi-text
                v-bind:value="addon[language_index].twitter_title"
                v-on:input-text="addon[language_index].twitter_title = $event; analyze()"
                :label="'Título'"
                :icon_box="false" :full="true"
            />
            <fyi-textarea
                v-bind:value="addon[language_index].twitter_description"
                v-on:input="addon[language_index].twitter_description = $event; analyze()"
                :label="'Descrição'"
                :toolbar="'none'"
            />
            <label>Imagem</label>
            <div v-if="addon[language_index].twitter_image != undefined && addon[this.language_index].twitter_image != null && addon[this.language_index].twitter_image != ''" class="image-preview">
                <img :src="$store.getters.appConfig.url + addon[this.language_index].twitter_image">
            </div>
            <div v-if="addon[language_index].twitter_image != undefined && addon[this.language_index].twitter_image != null && addon[this.language_index].twitter_image != ''" class="button-bar">
                <fyi-button
                    :label="'Alterar Imagem'"
                    :className="'default add'"
                    :onclick="() => mediaOn = true"
                />
                <fyi-button
                    :label="'Remover'"
                    :className="'default cancel'"
                    :onclick="() => {addon[this.language_index].twitter_image = '';analyze()}"
                />
            </div>
            <div v-if="addon[language_index].twitter_image != undefined && addon[this.language_index].twitter_image != null && addon[this.language_index].twitter_image != ''" style="clear:both" />
            <fyi-button
                v-else
                :label="'Nova Imagem'"
                :icon="'i-upload'"
                :className="'default new-image'"
                :onclick="() => mediaOn = true"
            />
        </div>

        <div class="section settings" :class="[{'active': tab == 'settings'}]">
            <SimpleSelect 
            :label="'Tipo de página'"
            :className="'field-type'"
            :previewIcon="'i-order'"
            @select-data-value="addon[language_index].schema = $event" :options=" schemas.map( s => { return {label: s.label, value: s.value, checked: addon[this.language_index].schema == s.value}})"
            />

            <label>Robots</label>
            <div class="robots">
                <fyi-checkbox v-for="(r, k) in robots" :alias="r.alias" :label="r.name"    
                :value="addon[language_index].robots.includes(r.alias)"  
                :checked="addon[language_index].robots.includes(r.alias)"                      
                v-on:input="handleRobots( r.alias, $event.checked)" :key="k"/> 
            </div>

            <fyi-text
                v-bind:value="breadcrumb"
                :disabled="true"
                :label="'Título Breadcrumb'"
                :icon_box="false" :full="true"
            />

            <fyi-text
                v-bind:value="canonic"
                :disabled="true"
                :label="'URL Canónico'"
                :icon_box="false" :full="true"
            />
        </div>
    </div>
    <teleport to="body">
        <div v-if="mediaOn" class="modal-media">
            <Media v-if="mediaOn" :modal="true" :mime="'image'" :multiSelect="false"
            @add-files="addImage"/>
        </div>
    </teleport>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { isInteger } from "lodash";

export default {
    name:"AddonSEO",
    props:{
        language_index: {
            type: Number,
            default: 0
        }
    },
    data(){
        return{
            tab:"seo",
            addon: null,
            keywords: [],
            keywordsUpdate: 0,
            mediaOn: false,
            analysis: {recommend:{data:[], total:0} , perfect:{data:[], total:0}},
            countLinks: 0,
            flagErr: false,
            robots: [
                {
                    name: 'Sem indexação',
                    alias: 'no-index',
                },
                {
                    name: 'Sem seguimento',
                    alias: 'no-follow',
                },
                {
                    name: 'Sem arquivo',
                    alias: 'no-archive',
                },
                {
                    name: 'Sem indexação de imagem',
                    alias: 'no-image-index',
                },
                {
                    name: 'Sem amostra',
                    alias: 'no-snippet',
                },
            ],
            schemas: [{
                label: 'Artigo',
                value: 'article',
                checked: false
            },{
                label: 'Evento',
                value: 'event',
                checked: false
            },{
                label: 'Pessoa',
                value: 'person',
                checked: false
            },{
                label: 'Produto',
                value: 'product',
                checked: false
            },{
                label: 'Serviço',
                value: 'service',
                checked: false
            }]
        }
    },
    computed:{
        breadcrumb(){
            let string = ''
            if(this.$parent.parent_types.length != 0){
                this.$parent.parent_types.forEach(element => {
                    string+=element.languages[this.language_index].name + "/"
                });
            }

            string += this.$parent.content.type.languages[this.language_index].name + "/" + this.$parent.content.languages[this.language_index].name

            return string;
        },
        canonic(){
            let string = ''
            if(this.$parent.parent_types.length != 0){
                this.$parent.parent_types.forEach(element => {
                    string+=element.languages[this.language_index].alias + "/"
                });
            }

            string += this.$parent.content.type.languages[this.language_index].alias + "/" + this.$parent.content.languages[this.language_index].alias

            return string;
        }
    },
    beforeMount(){
        let addon = this.$parent.content.seo;

        if(this.$parent.content.seo != undefined && this.$parent.content.seo != null && this.$parent.content.seo[this.language_index]){
            //asd
        }else{
            if(addon instanceof Array == false)
                addon = []

            addon.push({title: '', schema: '', fb_description: '', fb_title: '', fb_image:'', twitter_title: '', twitter_description: '', twitter_image:'',robots:[], meta_description:'', keywords: []})
        }
    
        this.addon = addon
    },
    methods:{
        selectTab(ev){
            this.tab = ev.target.id
        },
        addImage(files){
            if((this.addon[this.language_index].fb_image != undefined && this.addon[this.language_index].fb_image != null && this.addon[this.language_index].fb_image == '') || this.tab == 'fb')
                this.addon[this.language_index].fb_image = files[0].path
                
            if((this.addon[this.language_index].twitter_image != undefined && this.addon[this.language_index].twitter_image != null || this.addon[this.language_index].twitter_image == '') || this.tab == 'twitter')
                this.addon[this.language_index].twitter_image = files[0].path

            this.mediaOn = false
            this.analyze()
        },
        handleRobots(alias, add){
            if(add){
                this.addon[this.language_index].robots.push(alias)
            }else{
                this.addon[this.language_index].robots.splice( this.addon[this.language_index].robots.indexOf(alias) ,1)
            }
        },
        analyze(){
            this.analysis.perfect.data = [], this.analysis.recommend.data = [];
            this.analysis.perfect.total = 0; this.analysis.recommend.total = 0;

            if(this.$parent.content.languages[this.language_index].name.length >= 20 && this.$parent.content.languages[this.language_index].name.length <= 50){
                this.analysis.perfect.total++;
                this.analysis.perfect.data.push({
                    icon:'success',
                    label: 'Título dentro do tamanho recomendado.',
                    link:''
                })                                    
            }else{
                this.analysis.recommend.total++;
                this.analysis.recommend.data.push({
                    icon:'warning',
                    label: 'Título fora do tamanho recomendado ('+ (this.$parent.content.languages[this.language_index].name.length <= 20 ? 20 - this.$parent.content.languages[this.language_index].name.length + ' caracteres abaixo' : this.$parent.content.languages[this.language_index].name.length - 50 + ' caracteres acima') +').',
                    link: ''
                })
            }

            if(this.addon[this.language_index].meta_description.length >= 120 && this.addon[this.language_index].meta_description.length <= 158){
                this.analysis.perfect.total++;
                this.analysis.perfect.data.push({
                    icon:'success',
                    label: 'Descrição da meta dentro do tamanho recomendado.',
                    link: ''
                })
            }else{
                this.analysis.recommend.total++;
                this.analysis.recommend.data.push({
                    icon:'warning',
                    label: 'Descrição da meta fora do tamanho recomendado ('+ (this.addon[this.language_index].meta_description.length <= 120 ? 120 - this.addon[this.language_index].meta_description.length + ' caracteres abaixo' : this.addon[this.language_index].meta_description.length - 158 + ' caracteres acima') +').',
                    link: ''
                })
            }

            if(this.addon[this.language_index].schema == 'article'){
                if(this.$parent.$refs.body.getWordCount() >= 1200 && this.$parent.$refs.body.getWordCount() <= 1500){
                    this.analysis.perfect.total++;
                    this.analysis.perfect.data.push({
                        icon:'success',
                        label: 'Conteúdo com o tamanho recomendado.',
                        link: ''
                    })
                }else{
                    this.analysis.recommend.total++;
                    this.analysis.recommend.data.push({
                        icon:'warning',
                        label: 'Contéudo fora do tamanho recomendado ('+ (this.$parent.$refs.body.getWordCount() <= 1200 ? 1200 - this.$parent.$refs.body.getWordCount() + ' abaixo' : this.$parent.$refs.body.getWordCount() - 1500 + ' acima') +').',
                        link: ''
                    })
                }
            }else{
                if(this.$parent.$refs.body.getWordCount() >= 300 && this.$parent.$refs.body.getWordCount() <= 500){
                    this.analysis.perfect.total++;
                    this.analysis.perfect.data.push({
                        icon:'success',
                        label: 'Conteúdo com o tamanho recomendado.',
                        link: ''
                    })
                }else{
                    this.analysis.recommend.total++;
                    this.analysis.recommend.data.push({
                        icon:'warning',
                        label: 'Contéudo fora do tamanho recomendado ('+ (this.$parent.$refs.body.getWordCount() <= 300 ? 300 - this.$parent.$refs.body.getWordCount() + ' palavras abaixo' : this.$parent.$refs.body.getWordCount() - 500 + ' palavras acima') +').',
                        link: ''
                    })
                }
            }

            if(this.addon[this.language_index].fb_image == '' || this.addon[this.language_index].fb_title == '' || this.fb_title == '' || this.addon[this.language_index].twitter_image == '' || this.addon[this.language_index].twitter_title == '' || this.twitter_title == ''){
                this.analysis.recommend.total++;
                this.analysis.recommend.data.push({
                    icon:'error',
                    label: 'Tags das redes sociais em falta.',
                    link: ''
                })
            }else{
                this.analysis.perfect.total++;
                this.analysis.perfect.data.push({
                    icon:'success',
                    label: 'Tags das redes sociais definidas.',
                    link: ''
                })
            }
            //check alts
            let altError = false;
            if(this.$parent.content.attachments)
                this.$parent.content.attachments.forEach( a => {
                    if(a.description == ''){
                        altError = true
                    }
                })

            if(!altError){
                this.analysis.perfect.total++;
                this.analysis.perfect.data.push({
                    icon:'success',
                    label: 'Todas as alt tags nas imagens definidas.',
                    link: ''
                })
            }else{
                this.analysis.recommend.total++;
                this.analysis.recommend.data.push({
                    icon:'warning',
                    label: 'Alt tags nas imagens em falta.',
                    link: ''
                })
            }

            //check links
            var rawHTML = this.$parent.content.languages[this.language_index].body

            var doc = document.createElement("html");
            doc.innerHTML = rawHTML;
            var links = doc.getElementsByTagName("a")
            this.countLinks = 0, this.flagErr = false;
            for (var i=0; i<links.length; i++) {
                if(links[i].getAttribute("href") != null){
                    axios.get(links[i].getAttribute("href")).then( r => { 
                        this.countLinks++;
                        if(this.countLinks == links.length && !this.flagErr){
                            this.analysis.perfect.total++;
                            this.analysis.perfect.data.push({
                                icon:'success',
                                label: 'Todos os links funcionam.',
                                link: ''
                            })
                        }
                    }).catch( err => {
                        console.log(err)
                        if(!this.flagErr){
                            this.analysis.recommend.total++;
                            this.analysis.recommend.data.push({
                                icon:'error',
                                label: 'Alguns links no texto não funcionam.',
                                link: ''
                            })
                            this.flagErr = true
                        }
                    })
                }else{
                    this.countLinks++;
                }
            }
        }
    },
    mounted() {
        this.$parent.updateSEO(this.$parent.content.languages[this.language_index].body)
        this.analyze();
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/layout/addons.scss";
</style>