import Apis from "../../libs/api/apis/apis";

export default {
    state: {
    },

    getters: {
    },

    mutations: {
    },

    actions: {
        getApi: async ({
            commit
        }, id) => {
            const response = await Apis.getApi(id);
            return response
        },
    }
};