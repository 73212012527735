<template>
    <div class="media-page">
        <Media :modal="false" :mime="''" :multiSelect="true" @add-files="addFiles"/>
    </div>
</template>

<script>

export default {
    name:"MediaPage",
    async beforeMount(){
        this.$parent.$parent.transition = false;
    },
    methods: {
        addFiles(){

        }
    },
    mounted(){
        this.$parent.$parent.view = this.$t('media')
    }
}
</script>

<style lang="scss" scoped>
    @import "../../../styles/pages/media.scss";   
</style>