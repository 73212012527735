import _ from "lodash";
import Api from "./api";

class Roles extends Api {
    constructor() {
        super();
        this.uri = "roles";
        this.requiresAuth = true;
    }

    getRoles() {
        return this.get(this.uri, {}, this.requiresAuth);
    }

    
    getPermissions() {
        return this.get(this.uri + "/permissions", {}, this.requiresAuth);
    }


    getRole(id) {
        return this.get(this.uri + "/" + id, {}, this.requiresAuth);
    }

    remove(id) {
        return this.delete(this.uri + "/" + id, this.requiresAuth);
    }

    edit(id, data) {
        return this.put(this.uri + "/" + id, data, this.requiresAuth);
    }
    
    create(data) {
        return this.post(this.uri, data, this.requiresAuth);
    }
}

export default new Roles();