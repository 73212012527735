import _ from "lodash";
import Api from "./../api";

class Apis extends Api {
    constructor() {
        super();
        this.uri = "apis";
        this.requiresAuth = true;
    }

    getApis() {
        return this.get(this.uri , {}, this.requiresAuth);
    }

    getApi(id) {
        return this.get(this.uri + "/" + id, {}, this.requiresAuth);
    }

}

export default new Apis();
