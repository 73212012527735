<template>
      <div :class="['simple-select', className, {active: opened }]" ref="el">
        <label v-if="label">{{label}}</label> 
        <div :class="['preview', {'filtered': selected.length > 0}]" @click="open()">
            <div v-if="selectedImg != null && !selectedImg.startsWith('i-')" :style="'background-image:url(\''+selectedImg+'\')'"></div>
            <i v-else-if="selectedImg != null && selectedImg.startsWith('i-')" :class="'prev ' + selectedImg"></i>
            <label v-if="!noLabel" :key="selectedLabel">{{selectedLabel}}</label>
            <label v-if="noLabel && selectedImg == null"></label>
            <i :class="previewIcon"></i>
        </div>
        <div ref="select" :class="['select']">
            <div v-if="allowSearch" class="search-drop">
                <i class="i-search"></i>
                <input ref="search_drop" @keyup="searchDrop">
            </div>
            <div class="contain">
                <ul ref="options" class="select-options">
                    <li v-for="(opt, k) in options" :class="[{'active': opt.checked}]" :value="opt.value" :data-value="opt.value" :key="opt.label" @click="select($event.target, opt.value)">
                        <div v-if="opt.img != null && !opt.img.startsWith('i-')" :style="'background-image:url(\''+opt.img+'\')'" :data-value="opt.value"></div>
                        <i v-else-if="opt.img != null && opt.img.startsWith('i-')" :class="'prev ' + opt.img" :data-value="opt.value"></i>
                        {{opt.label}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"SimpleSelect",
    props:{
        label:{
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: []
        },
        className: {
            type: String,
            default: ""
        },
        previewIcon: {
            type: String,
            default: "i-filter"
        },
        itemOrder: {
            type: Number,
            default: null
        },
        allowSearch: {
            type: Boolean,
            default: false
        },
        noLabel: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            selectedValue: '',
            selectedLabel: '',
            selectedImg: null,
            selected: [],
            opened: false,
        }
    },
    watch: {
        options(){
            let flag = false
            let itemOrder = this.itemOrder

            this.options.forEach( opt => {
                if(itemOrder && opt.value == itemOrder){
                    this.selectedLabel = opt.label 
                    this.selectedValue = opt.value
                    if(opt.img){
                        this.selectedImg = opt.img
                    }
                }

                if(opt.checked){
                    this.selectedLabel = opt.label 
                    this.selectedValue = opt.value
                    if(opt.img){
                        this.selectedImg = opt.img
                    }
                    flag = true
                }
            })

            if(!flag){
                this.selectedLabel = ''
                this.selectedValue = ''
                this.selectedImg = null
            }

        }
    },
    created() {
        window.addEventListener("click", this.closeOnBlur)
    },
    destroyed() {
        window.removeEventListener("click", this.closeOnBlur);
    },
    methods: {
        open(){
            this.opened = !this.opened;
        },
        closeOnBlur(event){
            if(this.opened){
                if(this.$refs.el && !this.$refs.el.contains(event.target)){
                    this.opened = false;
                }
            }
        },
        select(el, id){
            this.$refs.select.querySelectorAll(".active").forEach(element => {
                element.classList.remove("active")
            });
            this.selectedValue = el.value
            this.selectedLabel = el.innerText
            this.selectedImg = el.querySelector("div") ?  el.querySelector("div").style.backgroundImage.slice(4, -1).replace(/"/g, "") : (el.classList.contains("prev") ?  el.classList[1] : null);
            el.classList.add("active")
            this.$emit("select", el.innerText)
            this.$emit("select-value", el.value)
            this.$emit("select-data-value", el.dataset.value)
            this.opened = !this.opened;
            if(this.$refs.search_drop){
                this.$refs.search_drop.value = "";
                this.searchDrop({target:{value:''}});
            }
        },
        searchDrop(evt){
            this.$refs.options.querySelectorAll('li').forEach( i => {
                if(i.innerHTML.toLowerCase().includes(evt.target.value.toLowerCase())){
                    i.classList.remove('hidden')
                }else{
                    i.classList.add('hidden')
                }
            })
        }
    },
    beforeMount(){
        let itemOrder = this.itemOrder
        let itemId = this.itemId
        
        this.options.forEach( opt => {
            if(itemOrder && opt.value == itemOrder){
                this.selectedLabel = opt.label 
                this.selectedValue = opt.value
                if(opt.img)
                    this.selectedImg = opt.img
            }

            if(opt.checked){
                this.selectedLabel = opt.label 
                this.selectedValue = opt.value
                if(opt.img)
                    this.selectedImg = opt.img
            }

        })
    }
}
</script>

<style lang="scss">
  @import "@/styles/components/form/simple_select";
</style>