<template>
    <div class="search simple">
        <i class="i-search"></i>
        <input
            v-on:keyup="handleUp"
            v-model="query"
            type="text"
            :placeholder="placeholder"
            :readonly="disabled"
        >
    </div>
</template>

<script>
export default {
    name: "Search",
    props: {
        placeholder: {
            type: String,
            default: "Search..."
        },
        search: {
            type: Function
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            query: "",
            typingTimer: null,
            typingInterval: 1000
        };
    },
    methods: {
        handleUp(event) {
            clearTimeout(this.typingTimer);
            this.typingTimer = setTimeout(() => {this.doneTyping(event)}, 500);
        },
        doneTyping(event) {
            this.$emit('done-search', event.target.value);
        }
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
    @import "../../../../styles/components/form/search.scss";
</style>
