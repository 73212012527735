import _ from "lodash";
import Api from "./api";

class Inbox extends Api {
    constructor() {
        super();
        this.uri = "inbox";
        this.requiresAuth = true;
    }


    storeMessage(data) {
        return this.post(this.uri + "/messages", data);
    }

	getInboxByUser(id, params) {
        return this.get(this.uri + "/messages/user/" + id + (params ? "?" + params : ""));
	}

}

export default new Inbox();