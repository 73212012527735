<template>
  <div class="addon modules" :class="[{ open: open }]">
    <div class="collapsible" @click="open = !open">
      <label>Módulos</label>
      <i class="i-collapse" />
    </div>
    <div class="modules-form">
      <div
        class="module"
        v-for="(m, k) in addon.filter((a) => a.language_id == language)"
        :key="addon"
      >
        <SimpleSelect
          :className="''"
          @select="
            order({ newOrder: $event, item: m, oldOrder: k + 1 });
            $emit('update-module', addon);
          "
          :previewIcon="'i-order'"
          :options="
            moduleOrder(
              addon.filter((a) => a.language_id === language).length,
              k
            )
          "
        />
        <fyi-text
          v-bind:value="m.title"
          :label="''"
          :placeholder="''"
          :icon_box="false"
          v-on:input-text="
            tmp.title = $event;
            $emit('update-module', addon);
          "
        />
        <span
          :id="'collapse'"
          :ref="'collapse'"
          @click="collapseExpand($event)"
          :key="m.id"
          >Expandir</span
        >
        <label class="remove" @click="removeModule(k)">{{
          $t("remove")
        }}</label>
        <div class="modules-attachments hidden">
          <Table
            ref="table"
            :items="{
              data: sort(m.attachments),
              total: m?.attachments?.length,
            }"
            :loading="loadTable"
            :tableClass="'attachments'"
            :pagination="false"
            @addon-order="resortAttachments($event, k)"
            @edit="editFile($event, k)"
            @remove="removeFile($event, k)"
            @preview="previewFile($event, k)"
            :showEntries="false"
            :showTotal="false"
            :showFilters="false"
            :language_index="language_index"
            :language="language"
          />
          <div class="button-bar">
            <fyi-button
              :label="'Adicionar ficheiro'"
              :className="'default add'"
              :onclick="() => addFile(k)"
            />
            <fyi-button
              :label="'Link externo'"
              :className="'default external'"
              :onclick="() => addExternalLink(k)"
            />
          </div>
        </div>
      </div>
      <div class="module add" :key="k">
        <span></span>
        <fyi-text
          v-bind:value="''"
          :label="''"
          :placeholder="''"
          :icon_box="false"
          v-on:input-text="tmp.title = $event"
          :key="tmp"
        />
        <label class="add" @click="addModule">{{ $t("add") }}</label>
      </div>
    </div>
  </div>
  <teleport to="body">
    <div v-if="mediaOn" class="modal-media">
      <Media
        :modal="true"
        :mime="''"
        :multiSelect="true"
        @add-files="addFiles"
      />
    </div>
  </teleport>
  <teleport to="body">
    <div v-if="editing != null" class="bg">
      <div class="edit-file">
        <label>Editar ficheiro</label>
        <i class="i-close" @click="editing = null" />
        <div class="info" v-if="editing.mimetype">
          <div class="preview">
            <img
              v-if="editing.mimetype.includes('image')"
              :src="$store.getters.appConfig.url + editing.file"
            />
            <i v-else :class="setIconExtension(editing)" />
          </div>
          <div class="details">
            <label
              ><span>Criado:</span
              ><span>{{
                new Date(editing.created_at).toLocaleDateString("pt-PT", {
                  format: "short",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              }}</span></label
            >
            <label
              ><span>Autor: </span
              ><span>{{
                editing.fileInfo.user ? editing.fileInfo.user.name : "-"
              }}</span></label
            >
            <label
              ><span>Extensão:</span
              ><span>{{
                !editing.file.includes("https")
                  ? editing.file.split(".")[editing.file.split(".").length - 1]
                  : "-"
              }}</span></label
            >
            <label
              ><span>Tamanho:</span
              ><span>{{
                (editing.fileInfo.size / 1024 ** 2).toFixed(3) + "MB"
              }}</span></label
            >
            <label
              ><span>Dimensões:</span
              ><span>{{
                editing.fileInfo.dimensions ? editing.fileInfo.dimensions : "-"
              }}</span></label
            >
            <div class="bt">
              <fyi-button
                :label="'Preview'"
                :icon="'far fa-eye'"
                :className="'default preview'"
                :onclick="
                  () => {
                    previewFile(editing);
                  }
                "
              />
              <fyi-button
                :label="'Download'"
                :className="'default download'"
                :icon="'i-upload file'"
                :onclick="
                  () => {
                    downloadEditFile();
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="form">
          <fyi-text
            v-bind:value="editing.name"
            v-on:input-text="editing.name = $event"
            :label="'Nome'"
            :placeholder="'Nome'"
            :icon_box="false"
            :full="true"
          />
          <fyi-text
            v-bind:value="editing.description"
            v-on:input-text="editing.description = $event"
            :label="'Texto Alternativo'"
            :placeholder="'Texto Alternativo'"
            :icon_box="false"
            :full="true"
          />
          <fyi-text
            v-bind:value="editing.file"
            v-on:input-text="editing.file = $event"
            :label="'Url'"
            :disabled="!editing.file.includes('https')"
            :icon_box="false"
            :full="true"
          />
        </div>
        <div class="button-bar">
          <fyi-button
            :label="'Atualizar conteúdo'"
            :className="'default add'"
            :onclick="() => edit()"
          />
          <fyi-button
            :label="'Eliminar'"
            :className="'default external'"
            :onclick="() => removeFile(editing.id)"
          />
        </div>
      </div>
    </div>
  </teleport>
  <teleport to="body">
    <div v-if="addExternal != false" class="bg">
      <div class="external-file">
        <label>Adicionar Link Externo</label>
        <div class="form">
          <fyi-text
            v-bind:value="''"
            v-on:input-text="external.name = $event"
            :label="'Nome'"
            :placeholder="'Nome'"
            :icon_box="false"
            :full="true"
          />
          <fyi-text
            v-bind:value="''"
            v-on:input-text="external.description = $event"
            :label="'Texto Alternativo'"
            :placeholder="'Texto Alternativo'"
            :icon_box="false"
            :full="true"
          />
          <fyi-text
            v-bind:value="''"
            v-on:input-text="external.path = $event"
            :label="'Url'"
            :icon_box="false"
            :full="true"
          />
        </div>
        <div class="button-bar">
          <fyi-button
            :label="'Atualizar conteúdo'"
            :className="'default add'"
            :disabled="external.name == '' || external.path == ''"
            :onclick="() => addExternalAttach()"
          />
          <fyi-button
            :label="'Cancelar'"
            :className="'default external'"
            :onclick="() => (addExternal = false)"
          />
        </div>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  name: "AddonModules",
  props: {
    content: {
      type: Object,
      default: null,
    },
    language_index: {
      type: Number,
      default: 0,
    },
    language: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      open: false,
      availability: [
        {
          label: "Grátis",
          value: "gratis",
          checked: false,
        },
        {
          label: "Payperview",
          value: "payperview",
          checked: false,
        },
        {
          label: "Subscrição",
          value: "subscription",
          checked: false,
        },
      ],
      tmp: {},
      editing: null,
      addExternal: false,
      external: { name: "", path: "", category_id: 0 },
      mediaOn: false,
      currentModule: null,
    };
  },
  beforeMount() {
    this.addon = this.content.modulos;
  },
  methods: {
    sort(list) {
      //sort list by order to be shown on table
      return list?.sort((a, b) =>
        a.orderattachment >= b.orderattachment ? 1 : -1
      );
    },
    moduleOrder(total, index) {
      let list = [];
      //set the select dropdowns with all the possible positions for each item, and the current one
      for (let i = 0; i < total; i++) {
        list.push({
          label: i + 1,
          value: i + 1,
          checked: i == index,
        });
      }
      return list;
    },
    collapseExpand(event) {
      event.target.parentElement
        .querySelector(".modules-attachments")
        .classList.toggle("hidden");
      event.target.innerHTML =
        event.target.innerHTML == "Colapsar" ? "Expandir" : "Colapsar";
    },
    addModule() {
      this.tmp.attachments = [];
      this.tmp.language_id = this.language;
      this.tmp.id = this.addon.length + 1;
      this.addon.push(this.tmp);
      this.tmp = {};
      this.$emit("update-module", this.addon);
    },
    removeModule(k) {
      this.addon.splice(k, 1);
    },
    addFiles(files, k) {
      let order =
        this.addon.filter((a) => a.language_id == this.language)[
          this.currentModule
        ].attachments.length + 1;
      let tmpFiles = [];
      //create template_item
      files.forEach((f) => {
        let tmp = {};
        tmp.file = f.path;
        tmp.name = f.name;
        tmp.orderattachment = order;
        tmp.id = Math.floor(Math.random() * Number.MAX_VALUE);
        if (f.description) {
          tmp.description = f.description;
        }
        if (f.mime) {
          tmp.mimetype = f.mime;
          tmp.fileInfo = {
            size: f.size,
            dimensions: f.dimensions,
            user: f.user,
          };
        }
        tmpFiles.push(tmp);
        order++;
      });
      this.addon.filter((a) => a.language_id == this.language)[
        this.currentModule
      ].attachments = this.addon
        .filter((a) => a.language_id == this.language)
        [this.currentModule].attachments.concat(tmpFiles);

      this.$emit("update-module", this.addon);
    },
    addFile(k) {
      this.mediaOn = true;
      this.currentModule = k;
    },
    removeFile(id, k) {
      let attachments = this.addon.filter(
        (a) => a.language_id == this.language
      )[k].attachments;
      if (attachments.length > id + 1)
        //reorder every item after one position below
        for (let i = id + 1; i < attachments.length; i++) {
          attachments[i].orderattachment -= 1;
        }

      //remove the desired item
      attachments.splice(id, 1);
      this.addon.filter((a) => a.language_id == this.language)[k].attachments =
        attachments;
      this.$emit("update-module", this.addon);
    },
    addExternalLink(k) {
      this.addExternal = true;
      this.currentModule = k;
    },
    addExternalAttach() {
      if (!this.external.path.includes("http")) {
        this.external.path = "https://" + this.external.path;
      }
      this.addFiles([this.external], this.currentModule);
      this.external = { name: "", path: "", category_id: 0 };
      this.addExternal = false;
      this.$emit("update-module", this.addon);
    },
    editFile(file, k) {
      this.editing = file;
      this.currentModule = k;
    },
    edit() {
      this.addon
        .filter((a) => a.language_id == this.language)
        [this.currentModule].attachments.forEach((attach) => {
          if (attach.id == this.editing.id) {
            attach = this.editing;
          }
        });

      this.editing = null;
      this.$emit("update-module", this.addon);
    },
    downloadEditFile() {
      //Create XMLHTTP Request.
      let req = new XMLHttpRequest();
      req.open(
        "GET",
        this.$store.getters.appConfig.url + this.editing.path,
        true
      );
      req.responseType = "blob";
      req.onload = () => {
        //Convert the Byte Data to BLOB object.
        let blob = new Blob([req.response], {
          type: "application/octetstream",
        });

        //Check the Browser type and download the File.
        let isIE = false || !!document.documentMode;
        if (isIE) {
          window.navigator.msSaveBlob(blob, this.editing.name);
        } else {
          let url = window.URL || window.webkitURL;
          let link = url.createObjectURL(blob);
          let a = document.createElement("a");
          a.setAttribute("download", this.editing.name);
          a.setAttribute("href", link);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
      req.send();
    },
    resortAttachments(info, k) {
      //create clone of attachments to make only 1 update to the UI, instead of 1 for each item changed
      let attachments = this.addon.filter(
        (a) => a.language_id == this.language
      )[k].attachments;
      let oldOrder = info.item.orderattachment;

      attachments.forEach((attach) => {
        //give new order to updated item
        if (attach.id == info.item.id) {
          attach.orderattachment = parseInt(parseInt(info.newOrder));
          //all items before the updated item decrease by 1
        } else if (
          oldOrder < parseInt(info.newOrder) &&
          attach.orderattachment <= parseInt(info.newOrder) &&
          attach.orderattachment >= oldOrder
        ) {
          attach.orderattachment -= 1;
          //all items after the updated item increase by 1
        } else if (
          oldOrder > parseInt(info.newOrder) &&
          attach.orderattachment >= parseInt(info.newOrder) &&
          attach.orderattachment < oldOrder
        ) {
          attach.orderattachment += 1;
        } else {
          attach.orderattachment = attach.orderattachment;
        }
      });

      this.addon.filter((a) => a.language_id == this.language)[k].attachments =
        attachments;
      this.$emit("update-module", this.addon);
    },
    order(info) {
      //create clone of related to make only 1 update to the UI, instead of 1 for each item changed
      let modules = [];

      this.addon.forEach((r) => {
        modules.push(null);
      });

      let oldOrder = info.oldOrder;

      this.addon.forEach((rel, i) => {
        //give new order to updated item
        if (rel.id == info.item.id) {
          modules[info.newOrder - 1] = info.item;
          //all items before the updated item decrease by 1
        } else if (oldOrder < info.newOrder && i + 1 <= info.newOrder) {
          modules[i - 1] = rel;
          //all items after the updated item increase by 1
        } else if (
          oldOrder > info.newOrder &&
          i + 1 >= info.newOrder &&
          i + 1 < oldOrder
        ) {
          modules[i + 1] = rel;
        } else {
          modules[i] = rel;
        }
      });

      this.addon = modules;
      this.$forceUpdate();
      this.$emit("update-module", this.addon);
    },
    previewFile(file) {
      //TO DO - add plugin
      window.open(this.$store.getters.appConfig.url + file.file, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/components/layout/addons.scss";
</style>
